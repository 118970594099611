<template>
  <div class="nav_bar">
    <div style="cursor: pointer;" :class="flagNav == 1 ? 'nav_act text_c' : ''" @click="onTap(1)">
      <img src="../assets/img/index.png" alt="" class="span1" />
      首页
    </div>
    <div style="cursor: pointer;" :class="flagNav == 2 ? 'nav_act text_c' : ''" @click="onTap(2)">
      <img src="../assets/img/rank.png" alt="" class="span1" />
      排行
    </div>
    <div style="cursor: pointer;" :class="flagNav == 3 ? 'nav_act text_c' : ''" @click="onTap(3)">
      <img src="../assets/img/tran.png" alt="" class="span1" />
      交易
    </div>
    <div
     style="cursor: pointer;"
      v-if="isLogin"
      :class="flagNav == 4 ? 'nav_act text_c' : ''"
      @click="onTap(4)"
    >
      <img src="../assets/img/mine.png" alt="" class="span1" />
      我的
    </div>
    <div style="cursor: pointer;" :class="flagNav == 5 ? 'nav_act' : ''" @click="onTap(5)">
      <img src="../assets/img/sheep.png" alt="" class="span2" />
      <img src="../assets/img/sheep1.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      flagNav: 1,
      value: true,
      activeIndex: 0,
      isLogin: false,
    };
  },
  methods: {
    

    onTap(e) {
      console.log(e,'test');
      // console.log(111,e);
      this.flagNav = e;
      if (e == 1) {
        this.$router.push({ name: "index" }, () => {});
      }
      if (e == 2) {
        this.$router.push({ name: "rank" }, () => {});
      }
      if (e == 3) {
        this.$router.push({ name: "buy" }, () => {});
      }
      if (e == 4) {
        this.$router.push({ name: "mine" }, () => {});
      }
      if (e == 5) {
        this.$router.push({ name: "sheep" }, () => {});
      }
    },
  },
  mounted() {
    var that=this
      this.$bus.$on('change',() => {
        that.flagNav=4
        console.log("11");
      });
      this.$bus.$on('changeNav',() => {
        that.flagNav=1
        console.log("11");
      });
    // console.log(this.$route.path, "lllll");
    var token = localStorage.getItem("token");
    if (token) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    if (this.$route.path == "/") {
      this.flagNav = 1;
    } else if (this.$route.path == "/rank") {
      this.flagNav = 2;
    } else if (this.$route.path == "/buy") {
      this.flagNav = 3;
    } else if (this.$route.path == "/mine") {
      this.flagNav = 4;
    } else if (this.$route.path == "/sheep") {
      this.flagNav = 5;
    }
  },
  beforeUpdate() {
    console.log(this.$route.path, "00000");
  },
};
</script>
<style lang="scss" scoped>
.nav_bar {
  width: 233px;
  height: 311px;
  background: rgba(255, 255, 255, 0.23);
  border-radius: 22px 22px 22px 22px;
  // opacity: 0.23;
  position: absolute;
  top: 110px;
  left: 230px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    height: 47px;
    font-weight: 500;
    font-size: 16px;
    color: rgba(112, 112, 112, 0.86);

    .span1 {
      margin-left: 29px;
      margin-right: 20px;
    }

    .span2 {
      margin-left: 14px;
      margin-right: 13px;
    }
  }

  .text_c {
    color: #000;
  }

  .nav_act {
    width: 233px;
    height: 47px;
    background: #f5f5f5;
    border-radius: 24px 24px 24px 24px;
  }
}
</style>