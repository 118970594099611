import Vue from 'vue'
import VueRouter from 'vue-router'
import sheep from '../views/sheep.vue'
import index from '../views/index.vue'
import mine from '../views/mine.vue'
import buy from '../views/buy.vue'
import rank from '../views/rank.vue'

Vue.use(VueRouter)
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}
const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/buy',
    name: 'buy',
    component: buy
  },
  {
    path: '/mine',
    name: 'mine',
    component: mine
  },
  {
    path: '/rank',
    name: 'rank',
    component: rank
  }, 
  {
    path: '/sheep',
    name: 'sheep',
    component: sheep
  }
 
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
