<template>
      <transition name="el-fade-in">
  <div class="q">
    <div class="main">
      <div class="q_h">礼包 <span style="cursor: pointer;" @click="close">X</span></div>
      <div class="q_m">
        <div class="q_m_nav">
          <div style="cursor: pointer;" :class="flagNav == 0 ? 'nav_act' : ''" @click="toTap(0)">
            普通礼包
          </div>
          <div
           style="cursor: pointer;"
            class="nav1"
            :class="flagNav == 1 ? 'nav_act' : ''"
            @click="toTap(1)"
          >
            充值礼包
          </div>
          <div style="cursor: pointer;" :class="flagNav == 2 ? 'nav_act' : ''" @click="toTap(2)">
            会员礼包
          </div>
        </div>
        <div class="g_m">
          <div class="gift" v-for="(i, j) in List" :key="j">
            <img src="../assets/img/gift.png" alt="" />
            <div class="gift1">
              <p class="p1">{{ i.gift_name }}</p>
              <p class="p2">{{ i.remark }}</p>
            </div>
            <div class="gift3" v-if="i.receive">已领取</div>
            <div class="gift2" style="cursor: pointer;" v-else @click="toBg">领取</div>
          </div>
        </div>
      </div>
      <div class="q_btn">
        <img src="../assets/img/z1.png" alt="" v-if="page == 1 || page == 0" />
        <img src="../assets/img/z2.png" style="cursor: pointer;" alt="" v-if="page > 1" @click="toPre" />
        <div>{{ page }} / {{ tpage }}</div>
        <img src="../assets/img/y1.png" alt="" v-if="page == tpage" />
        <img
         style="cursor: pointer;"
          src="../assets/img/y2.png"
          alt=""
          @click="toNext"
          v-if="page != tpage"
        />
        <!-- <img src="" alt="" /> -->
      </div>
      
    </div>
    <div class="notice" v-show="flagBg">
        <div class="notice1">温馨提醒</div>
        <div class="notice2">进入游戏悬浮球内领取礼包</div>
        <img src="../assets/img/gift_bg.png" alt="" srcset="" />
        <div class="notice3" style="cursor: pointer;" @click="closeBg">我知道了</div>
      </div>
  </div>
</transition>
</template>
<script>
// import { createLogger } from "vuex";
export default {
  props: ["gid"],
  data() {
    return {
      flagBg:false,
      List: "",
      tpage: 1,
      page: 1,
      flagNav: 0
    };
  },
  methods: {
    closeBg(){
      this.flagBg=false
    },
    toBg(){
      this.flagBg=true
    },
    toTap(e) {
      this.page = 1;
      this.$http
        .post("pc/Game/gift_list", {
          game_id: this.gid,
          page: this.page,
          gift_type: e,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 20000) {
            this.List = res.data.data;
            this.tpage = res.data.last_page;
            if (this.tpage == 0) {
              this.page = 0;
            }
            console.log(res.data, "ppppp1112222222222222");
          }
        });
      this.flagNav = e;
    },
    close() {
      this.$emit("closeQuan");
    },
    toPre() {
      this.page = this.page - 1;
      this.$http
        .post("pc/Game/gift_list", {
          game_id: this.gid,
          page: this.page,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 20000) {
            this.List = res.data.data;
            this.tpage = res.data.last_page;
            if (this.tpage == 0) {
              this.page = 0;
            }
            //   console.log(res.data, "ppppp");
          }
        });
    },
    receive_ticket(ticket_id) {
      this.page = 1;
      this.$http
        .post("pc/Game/receive_ticket", {
          id: ticket_id,
        })
        .then((res) => {
          //   console.log(res);
          if (res.code == 20000) {
            this.$msg.success("领取成功");
            this.$http
              .post("pc/Game/gift_list", {
                game_id: this.gid,
                page: this.page,
              })
              .then((res) => {
                console.log(res);
                if (res.code == 20000) {
                  this.List = res.data.data;
                  this.tpage = res.data.last_page;
                  if (this.tpage == 0) {
                    this.page = 0;
                  }
                  //   console.log(res.data, "ppppp");
                }
              });
          }
        });
    },
    toNext() {
      this.page = this.page + 1;
      this.$http
        .post("pc/Game/gift_list", {
          game_id: this.gid,
          page: this.page,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 20000) {
            this.List = res.data.data;
            this.tpage = res.data.last_page;
            if (this.tpage == 0) {
              this.page = 0;
            }
            //   console.log(res.data, "ppppp");
          }
        });
    },
  },
  mounted() {
    this.page = 1;
    console.log("ooooooo");
    this.$http
      .post("pc/Game/gift_list", {
        game_id: this.gid,
        page: this.page,
        gift_type: 0,
      })
      .then((res) => {
        console.log(res);
        if (res.code == 20000) {
          this.List = res.data.data;
          this.tpage = res.data.last_page;
          if (this.tpage == 0) {
            this.page = 0;
          }
          console.log(res.data, "ppppp1112222222222222");
        }
      });
  },
};
</script>
<style scoped lang="scss">
.q {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(112, 112, 112, 0.27);
  top: 0;
  left: 0;
  z-index: 99999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  .main {
    width: 796px;
    height: 893px;
    background: #ffffff;
    box-shadow: 0px 3px 22px 1px rgba(0, 0, 0, 0.16);
    border-radius: 22px 22px 22px 22px;
    padding-top: 28px;
    position: relative;
  }
  .q_h {
    text-align: center;
    position: relative;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 22px;
    color: #191918;
    span {
      position: absolute;
      font-size: 20px;
      color: RGBA(191, 191, 191, 1);
      right: 42px;
      top: 0;
    }
  }
  .q_m {
    padding: 0 33px;
    // background: pink;
    .q_m_nav {
      // background: #acc;
      display: flex;
      justify-content: center;
      margin-bottom: 38px;
      div {
        width: 98px;
        height: 25px;
        border-radius: 13px;
        background: rgba(249, 214, 121, 1);
        font-weight: 500;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        line-height: 25px;
      }
      .nav1 {
        margin-left: 20px;
        margin-right: 20px;
      }
      .nav_act {
        background: rgba(245, 179, 7, 1);
      }
    }
  }
}
.q_btn {
  //   background: #acc;
  position: absolute;
  left: 0;
  bottom: 42px;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 60px;
    height: 28px;
    font-weight: 300;
    line-height: 28px;
    font-size: 20px;
    color: #ffc844;
    text-align: center;
  }
  img {
    width: 18px;
    height: 18px;
  }
}
.g_m {
  // background: #b44;
  // width: 100%;
  .gift {
    width: 343px;
    height: 75px;
    border-radius: 10px;
    background: #fff;
    float: left;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 11px;
    margin-left: 11px;
    img {
      width: 36px;
      height: 36px;
      margin-left: 18px;
      margin-right: 21px;
    }
    .gift1 {
      .p1 {
        font-weight: 500;
        font-size: 14px;
        color: #1c1c1e;
        margin-bottom: 8px;
      }
      .p2 {
        font-weight: 300;
        font-size: 12px;
        color: #98969b;
        width: 175px;
        // background: pink;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .gift2 {
      width: 58px;
      height: 27px;
      background: #ffc844;
      border-radius: 13px 13px 13px 13px;
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
      text-align: center;
      line-height: 27px;
      position: absolute;
      right: 23px;
      top: 25px;
    }
    .gift3 {
      width: 58px;
      height: 27px;
      background: #ffc844;
      border-radius: 13px 13px 13px 13px;
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
      text-align: center;
      line-height: 27px;
      position: absolute;
      right: 23px;
      top: 25px;
      opacity: 0.59;
    }
  }
}
.notice {
  width: 440px;
  height: 370px;
  border-radius: 22px;
  box-shadow: 0px 3px 22px 1px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -220px;
  margin-top: -185px;
  z-index: 999999999;
  padding-top: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  img{
    width: 314px;
    height: 114px;
    margin-bottom: 23px;
  }
  .notice1 {
    font-weight: 500;
    font-size: 18px;
    color: #010101;
    text-align: center;
  }
  .notice2 {
    font-weight: 500;
    font-size: 16px;
    color: #707070;
    text-align: center;
    margin-top: 17px;
    margin-bottom: 17px;
  }
  .notice3 {
    width: 260px;
    height: 52px;
    line-height: 52px;
    background: #ff8737;
    border-radius: 26px 26px 26px 26px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
  }
}
</style>
