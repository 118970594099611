<template>
  <div style="height: 100vh; overflow-y: auto" @scroll="sa" ref="cmain">
    <div class="index" @click="closeA">
      <div class="input_cK" v-show="flagd" @click.stop>
        <p>搜索发现</p>
        <div class="input_c1" v-if="userS.length != 0">
          <span
            style="cursor: pointer"
            v-for="(item, i) in userS"
            :key="i"
            @click="search2(item.keyword)"
            >{{ item.keyword }}</span
          >
        </div>
        <img src="../assets/img/index/hot_s.png" alt="" />
        <div class="input_c2">
          <div v-for="(item, i) in hotGame" :key="i">
            <span></span>{{ item.game_name
            }}<img src="../assets/img/index/hs.png" alt="" srcset="" />
          </div>
        </div>
      </div>
      <div class="input_c" v-show="flagS" @click.stop>
        <p>搜索发现</p>
        <div class="input_c1" v-if="userS.length != 0">
          <span
            style="cursor: pointer"
            v-for="(item, i) in userS"
            :key="i"
            @click="search1(item.keyword)"
            >{{ item.keyword }}</span
          >
        </div>
        <img src="../assets/img/index/hot_s.png" alt="" />
        <div class="input_c2">
          <div v-for="(item, i) in hotGame" :key="i">
            <span></span>{{ item.game_name
            }}<img src="../assets/img/index/hs.png" alt="" srcset="" />
          </div>
        </div>
      </div>
      <div
        class="input_content"
        style="
          z-index: 9999999;
          height: 100%;
          overflow-y: auto;
          padding-bottom: 100px;
        "
        v-show="flagG2"
        @click.stop
      >
        <div
          style="cursor: pointer"
          class="main_case1"
          v-for="(item, i) in slist"
          :key="i"
          @click="toDetails(item.id)"
        >
          <img :src="item.image" alt="" />
          <div>
            <div class="main_c1">
              {{ item.game_name }}
            </div>

            <div class="main_c3">
              <span v-for="(item2, k) in item.game_tag" :key="k">{{
                item2
              }}</span>
            </div>
            <div class="main_cq">
              <span v-for="(a, s) in item.category_name" :key="s">{{ a }}</span>
            </div>
            <!-- <div class="main_c2" v-if="item.star_time.indexOf('今') != -1">
                {{ item.star_time }}
              </div>
              <div class="main_c2_" v-else>{{ item.star_time }}</div>
              <div class="main_c2_">{{ item.star_time }}</div> -->
          </div>
        </div>
        <div v-if="slist.length == 0" style="text-align: center">暂无内容</div>
      </div>
      <!-- <div
        class=""
        style="
          z-index: 99999;
          height: 100vh;
          background-color: #f5f5f5;
          box-shadow: 0px 3px 22px 1px #f1f1f1;
          width: 710px;
          position: absolute;
          left: 50%;
          margin-left: -380px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
        v-if="flagDetail"
        @click.stop
      >
        <div class="details_buy">
          <img
            style="cursor: pointer"
            src="../assets/img/buy/back.png"
            alt=""
            srcset=""
            class="buy_del"
            @click="toDel"
          />
          <div class="details_buy1">
            <img :src="accde.image" alt="" />
            <div>
              <p>{{ accde.game_name }}</p>
              <div><span>￥</span>50.00</div>
            </div>
            <span>信息已通过审核</span>
          </div>
          <div class="details_buy2">
            <p>游戏区服：{{ accde.server_name }}</p>
            <p>
              累计消费：{{ accde.accumulated_recharge
              }}<span>（实付金额）</span>
            </p>
            <p>创建时间：{{ accde.create_time }}天</p>
            <div style="cursor: pointer" @click="toplay">立即购买</div>
          </div>
          <div class="details_buy3">
            <p class="buy_p1">{{ accde.title }}</p>
            <div class="buy_d1">
              {{ accde.describe }}
            </div>
            <div class="buy_d2">
              <img
                :src="item"
                alt=""
                v-for="(item, i) in accde.images"
                :key="i"
              />
            </div>
            <p class="buy_p2">
              排行榜数据以发布时间为准，后续可能变动，具体以游戏内为准
            </p>
            <p class="buy_p3">发布日期：{{ accde.release_time }}</p>
          </div>
        </div>
      </div> -->
      <div
        class="input_c"
        style="
          z-index: 9999999;
          height: 100%;
          overflow-y: auto;
          padding-bottom: 100px;
        "
        v-show="flagG1"
        @click.stop
      >
        <div
          style="cursor: pointer"
          class="main_case1"
          v-for="(item, i) in slist"
          :key="i"
          @click="toDetails(item.id)"
        >
          <img :src="item.image" alt="" />
          <div>
            <div class="main_c1">
              {{ item.game_name }}
            </div>

            <div class="main_c3">
              <span v-for="(item2, k) in item.game_tag" :key="k">{{
                item2
              }}</span>
            </div>
            <div class="main_cq">
              <span v-for="(a, s) in item.category_name" :key="s">{{ a }}</span>
            </div>
            <!-- <div class="main_c2" v-if="item.star_time.indexOf('今') != -1">
                {{ item.star_time }}
              </div>
              <div class="main_c2_" v-else>{{ item.star_time }}</div>
              <div class="main_c2_">{{ item.star_time }}</div> -->
          </div>
        </div>
        <div v-if="slist.length == 0" style="text-align: center">暂无内容</div>
      </div>
      <div
        class="nav"
        :style="
          scrollTop >= 80
            ? `position: fixed;top: 0;left: 50%;margin-left: -737px;z-index:999999999999;background:#fff;width:1474px;`
            : ''
        "
      >
        <img
          src="../assets/img/index/logo.png"
          style="cursor: pointer"
          @click="toHome"
          alt=""
          srcset=""
          class="logo"
        />
        <div class="input" v-show="flagI == 1" @click.stop>
          <input
            type="text"
            placeholder="蛋仔派对"
            @focus="inputFocus"
            v-model="textS"
            @input="search"
          />
          <img
            style="cursor: pointer"
            src="../assets/img/index/search.png"
            alt=""
            srcset=""
            @click="search"
          />
        </div>
        <div class="de_nav" v-show="flagI == 0">
          <img
            style="cursor: pointer"
            src="../assets/img/index/back.png"
            alt=""
            srcset=""
            @click="onBack"
          />
          <div class="input_s" @click.stop>
            <input
              type="text"
              placeholder="蛋仔派对"
              @focus="inputFocus1"
              v-model="textS"
              @input="search_2"
            />
            <img
              style="cursor: pointer"
              src="../assets/img/index/search.png"
              alt=""
              srcset=""
              @click="search_2"
            />
          </div>
          <div
        class=""
        style="
          z-index: 99999;
          height: 100vh;
          background-color: #f5f5f5;
          box-shadow: 0px 3px 22px 1px #f1f1f1;
          width: 710px;
          position: fixed;
          left: 50%;
          top: 0;
          margin-left: -380px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
        v-if="flagDetail"
        @click.stop
      >
        <div class="details_buy">
          <img
            style="cursor: pointer"
            src="../assets/img/buy/back.png"
            alt=""
            srcset=""
            class="buy_del"
            @click="toDel"
          />
          <div class="details_buy1">
            <img :src="accde.image" alt="" />
            <div>
              <p>{{ accde.game_name }}</p>
              <div><span>￥</span>50.00</div>
            </div>
            <span>信息已通过审核</span>
          </div>
          <div class="details_buy2">
            <p>游戏区服：{{ accde.server_name }}</p>
            <p>
              累计消费：{{ accde.accumulated_recharge
              }}<span>（实付金额）</span>
            </p>
            <p>创建时间：{{ accde.create_time }}天</p>
            <div style="cursor: pointer" @click="toplay">立即购买</div>
          </div>
          <div class="details_buy3">
            <p class="buy_p1">{{ accde.title }}</p>
            <div class="buy_d1">
              {{ accde.describe }}
            </div>
            <div class="buy_d2">
              <img
                :src="item"
                alt=""
                v-for="(item, i) in accde.images"
                :key="i"
              />
            </div>
            <p class="buy_p2">
              排行榜数据以发布时间为准，后续可能变动，具体以游戏内为准
            </p>
            <p class="buy_p3">发布日期：{{ accde.release_time }}</p>
          </div>
        </div>
      </div>
        </div>
        <img
          v-if="userinfo"
          :src="userinfo.avatar_image"
          alt=""
          class="login"
          @click="toMine"
          style="border-radius: 100%; cursor: pointer"
        />
        <img
          style="cursor: pointer"
          src="../assets/img/index/login.png"
          alt=""
          class="login"
          @click="open"
          v-else
        />
      </div>
      <div
        class="main"
        v-show="flagI == 0"
        style="overflow-y: auto; padding-bottom: 100px"
      >
        <div class="banner">
          <el-carousel :interval="3000" arrow="always">
            <el-carousel-item
              v-for="(item, i) in details.cover_images"
              :key="i"
            >
              <el-image
                style="width: 100%; height: 100%"
                :src="item"
                alt=""
                srcset=""
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="d_h1">
          <img :src="details.image" alt="" />
          <div>
            <!-- <div class="d_h1_1">双生幻想</div> -->
            <div class="d_h1_1">
              {{ details.game_name }}
            </div>
            <div class="d_h1_2">{{ details.remark }}</div>
            <div class="d_h1_3">
              <span
                class="d_h1_3_1"
                v-for="(item, i) in details.game_tag"
                :key="i"
                >{{ item }}</span
              >
              <!-- <span class="d_h1_3_1">像素</span>
              <span class="d_h1_3_2">0.1折</span> -->
            </div>
          </div>
          <div class="i_share">
            <img
              style="cursor: pointer"
              v-if="flagcollect"
              src="../assets/img/sc.png"
              alt=""
              srcset=""
              @click="toCollect(details.id)"
            />
            <img
              style="cursor: pointer"
              v-else
              src="../assets/img/sc2.png"
              alt=""
              srcset=""
              @click="toCollect(details.id)"
            />
            <img
              style="cursor: pointer"
              @click="openShare(details.id)"
              src="../assets/img/fx.png"
              alt=""
              srcset=""
            />
          </div>
          <!-- <div class="btn" @click="toplay">立即开玩</div> -->
        </div>
        <div class="i_btn">
          <div
            style="cursor: pointer"
            class="i_btn1"
            @click="toplay"
            v-if="details.login_url"
          >
            云玩
          </div>
          <div
            style="cursor: pointer"
            class="i_btn2"
            v-if="details.file_size != 0"
            @click="toplay"
          >
            下载（{{ details.file_size }}）
          </div>
          <div
            class="i_btn2"
            style="cursor: pointer; background-color: #cfcfcf"
            v-else
            @click="toplay"
          >
            下载
          </div>
        </div>
        <div class="d_h2">
          <div
            style="cursor: pointer"
            class="d_h2_1"
            @click="toAcc(details.id)"
          >
            <div class="d_h2_1_1">
              <img src="../assets/img/index/span_1.png" alt="" class="i1" />
              活动与公告
            </div>
            <div class="d_h2_1_2">
              <b></b>
              <span>{{ details.article_time }}</span>
              <div>
                <img src="../assets/img/index/icon_new.png" alt="" />
                {{ details.article_name }}
              </div>
            </div>
          </div>
          <div
            class="d_h2_2 d_h2_2_"
            style="cursor: pointer"
            @click="toQuan(details.id)"
          >
            <img src="../assets/img/index/span_2.png" alt="" class="i1" />
            优惠券
          </div>
          <div
            class="d_h2_2"
            style="cursor: pointer"
            @click="toGift(details.id)"
          >
            <img src="../assets/img/index/span_3.png" alt="" class="i2" />
            礼包
          </div>
          <div
            class="d_h2_2"
            style="cursor: pointer"
            @click="toVip(details.id)"
          >
            <img src="../assets/img/index/span_4.png" alt="" class="i2" />
            VIP
          </div>
        </div>
        <div
          class="d_h3"
          style="cursor: pointer"
          @click="toServer(details.id)"
          v-if="details.server_name"
        >
          <div>{{ details.server_time }}</div>
          <span>{{ details.server_name }}</span>
          <b>></b>
        </div>
        <div
          class="d_h3"
          style="cursor: pointer; justify-content: space-between"
          @click="toServer(details.id)"
          v-else
        >
          <div>动态开服</div>
          <span style="color: gray; padding-right: 30px">看历史开服</span>
        </div>

        <div class="d_h4">
          <img src="../assets/img/index/span_g.png" alt="" />
          <div>
            {{ details.content }}
          </div>
          <div
            style="
              height: 352px;
              background-color: #fff;
              overflow: hidden;
              margin-top: 33px;
            "
          >
            <!-- <img
              :src="item"
              alt=""
              srcset=""
              v-for="(item, i) in details.screenshot_images"
              :key="i"
              style="
                width: 236px;
                height: 352px;
                flex-shrink: 0;
                margin-right: 39px;
              "
            /> -->
            <el-carousel
              :interval="2000"
              type="card"
              height="200px"
              indicator-position="none"
              style="padding-left: 43px"
              arrow="never"
            >
              <el-carousel-item
                v-for="(item, i) in details.screenshot_images"
                :key="i"
              >
                <img
                  :src="item"
                  alt=""
                  srcset=""
                  style="width: 236px; height: 352px"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="d_h4">
          <img src="../assets/img/index/span_fl.png" alt="" />
          <div v-html="details.welfare"></div>
        </div>
        <div class="d_h5">
          <img src="../assets/img/index/span_buy.png" alt="" />
          <div
            style="cursor: pointer"
            class="d_h5_1"
            v-for="(item, i) in tranList"
            :key="i"
            @click="toCDetails(item.id)"
          >
            <p>{{ item.title }}</p>
            <div class="d_h5_1_c1">
              <span>区服：{{ item.server_name }}</span>
              <span>游戏充值：{{ item.accumulated_recharge }}</span>
              <div>
                <span>￥</span>
                {{ item.sell_price }}
              </div>
            </div>
            <div class="d_h5_1_c2">
              <div v-for="(h, k) in item.images" :key="k">
                <img :src="h" alt="" style="width: 100%; height: 100%" />
              </div>
            </div>
          </div>

          <div class="span_p" style="cursor: pointer" @click="toplay">
            打开羊托手游查看更多
            <span></span>
          </div>
        </div>
      </div>
      <div class="main" v-show="flagI == 1" ref="games" @scroll="onScroll4">
        <div class="main_nav">
          <div class="img" style="cursor: pointer" @click="onChange(1)">
            <img
              src="../assets/img/index/recommed.png"
              alt=""
              srcset=""
              v-show="flagNav == 1"
            />
            <span v-show="flagNav == 2 || flagNav == 3">推荐</span>
          </div>

          <div style="cursor: pointer" class="main_nav1" @click="onChange(2)">
            <img
              src="../assets/img/index/new.png"
              alt=""
              srcset=""
              v-show="flagNav == 2"
            />

            <span style="cursor: pointer" v-show="flagNav == 1 || flagNav == 3"
              >新游</span
            >
          </div>
          <div style="cursor: pointer" @click="onChange(3)">
            <img
              src="../assets/img/index/new1.png"
              alt=""
              srcset=""
              v-show="flagNav == 3"
            />

            <span style="cursor: pointer" v-show="flagNav == 2 || flagNav == 1"
              >预约</span
            >
          </div>
        </div>
        <div
          class="main_cases"
          v-show="flagNav == 1"
          ref="main1"
          @scroll="onScroll1"
        >
          <div
            style="cursor: pointer"
            class="main_case"
            v-for="(item, i) in reList"
            :key="i"
            @click="toDetails(item.id)"
          >
            <img :src="item.cover_images" alt="" />
            <div class="main_c1">
              {{ item.game_name }}
              <span v-for="(item1, j) in item.game_tag" :key="j">{{
                item1
              }}</span>
            </div>
            <div class="main_c2">{{ item.remark }}</div>
          </div>
        </div>
        <div
          class="main_cases"
          v-show="flagNav == 2"
          ref="main2"
          @scroll="onScroll2"
        >
          <!-- <div class="main_case" @click="toDetails">
            <img src="" alt="" />
            <div class="main_c1">
              游戏名称 <span>独立游戏</span> <span>像素</span> <span>多人</span>
            </div>
            <div class="main_c2">游戏简介</div>
          </div> -->
          <div
            style="cursor: pointer"
            class="main_case1"
            v-for="(item, i) in newList"
            :key="i"
            @click="toDetails(item.id)"
          >
            <img :src="item.image" alt="" />
            <div>
              <div class="main_c1">
                {{ item.game_name }}
              </div>
              <div class="main_c3">
                <span v-for="(item2, k) in item.game_tag" :key="k">{{
                  item2
                }}</span>
              </div>
              <div class="main_c2" v-if="item.star_time.indexOf('今') != -1">
                {{ item.star_time }}
              </div>
              <div class="main_c2_" v-else>{{ item.star_time }}</div>
              <!-- <div class="main_c2_">{{ item.star_time }}</div> -->
            </div>
          </div>
          <!-- <div class="main_case1" @click="toDetails">
            <img
              src="https://oss.yangtuoyou.com/uploads/20231214/1b8a011e1f43082f2f314fe67c27b32a.png"
              alt=""
            />
            <div>
              <div class="main_c1">游戏名称</div>
              <div class="main_c3">
                <span>独立游戏</span> <span>像素</span> <span>多人</span>
              </div>
              <div class="main_c2_">2月4日上线</div>
            </div>
          </div> -->
        </div>
        <div
          class="main_cases"
          v-show="flagNav == 3"
          ref="main3"
          @scroll="onScroll3"
        >
          <div
            style="cursor: pointer"
            class="main_case1"
            v-for="(item, i) in apList"
            :key="i"
            @click="toDetails(item.id)"
          >
            <img :src="item.image" alt="" />
            <div>
              <div class="main_c1">
                {{ item.game_name }}
              </div>
              <div class="main_c3">
                <span v-for="(item2, k) in item.game_tag" :key="k">{{
                  item2
                }}</span>
              </div>
              <div class="main_c2_">{{ item.star_time }}</div>
            </div>
            <div v-if="item.reservation" class="main_c4_gray">已预约</div>
            <div
              v-else
              style="cursor: pointer"
              class="main_c4"
              @click.stop="game_reservation(item.id, item.reservation_time)"
            >
              预约
            </div>
          </div>
        </div>
      </div>
      <div
        class="hot"
        ref="hot"
        :style="scrollTop > 400 ? `top: ${ptop}px;` : ''"
      >
        <div class="hot1">
          <div class="hot1_1" style="">
            <img src="../assets/img/index/hot.png" alt="" /><span
              style="cursor: pointer"
              @click="toplay"
              >更多</span
            >
          </div>
          <div class="hot1_cases">
            <div
              style="cursor: pointer"
              class="hot1_case"
              v-for="(item, i) in hg"
              :key="i"
              @click="toDetails(item.id)"
            >
              <img :src="item.image" alt="" />
              <div>
                <p>{{ item.game_name }}</p>
                <div>
                  <span v-for="(n, m) in item.game_tag" :key="m">{{ n }}</span>
                  <!-- <span>国风</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hot2">
          <img src="../assets/img/er.png" alt="" />
          <div class="hot2_1">
            <p>
              <img
                src="../assets/img/d.png"
                alt=""
                style="width: 177px; height: 31px; margin: 0"
                srcset=""
              />
            </p>
            <div>
              <p>玩游戏不花钱 薅羊毛 游戏托</p>
              <p>游戏当托就来羊托游</p>
            </div>
          </div>
          <p @click="todownLoad" style="cursor: pointer">能赚钱的手游APP</p>
        </div>
        <div class="hot_">
          <p>
            <span
              ><a href="https://admin.yangtuoyou.com/jianhu.html"
                >家长监护工程</a
              ></span
            >
            |
            <span
              ><a href="https://admin.yangtuoyou.com/jianhu.html"
                >防沉迷系统</a
              ></span
            >
            |
            <span
              ><a href="https://admin.yangtuoyou.com/jianhu.html"
                >纠纷处理</a
              ></span
            >
            |
            <span
              ><a href="https://admin.yangtuoyou.com/yhxy.html"
                >用户协议</a
              ></span
            >
          </p>
          <p>
            安徽羔羊网络科技有限公司互联网<br />ICP备案：皖ICP备2023010426号-2
          </p>
          <p>网站备案号：皖ICP备19002784号-7</p>
          <p>皖公网安备：34010402701713号</p>
          <p>互联网ICP备案：皖ICP备19002784号-3</p>
          <p>网络文化经营许可证：皖网文（2019）3164-137</p>
          <p>
            健康游戏忠告：<br />抵制不良游戏 拒绝盗版游戏 注意自我保护
            谨防受骗上当 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活
          </p>
        </div>
      </div>
    </div>
    <AppBg v-show="flagBg" @hide1="hide1"></AppBg>
    <Popup v-show="show1" @hide2="hide2"></Popup>
    <MyVouchers v-if="flagQuan" :gid="gid" @closeQuan="closeQuan"></MyVouchers>
    <MyGift v-if="flagGift" :gid="gid" @closeQuan="closeQuan"></MyGift>
    <ServerLaunch
      v-if="flagServer"
      :gid="gid"
      :flagHistory="flagHistory"
      @closeQuan="closeQuan"
    ></ServerLaunch>
    <ShareDialog
      v-if="flagShare"
      :gid="gid"
      @closeQuan="closeQuan"
    ></ShareDialog>

    <MyVip v-if="flagVip" :gid="gid" @closeQuan="closeQuan"></MyVip>
    <MyAcc v-if="flagAcc" :gid="gid" @closeQuan="closeQuan"></MyAcc>
    <!-- <div
      style="
        height: 198px;
        background-color: white;
        font-weight: 300;
        font-size: 14px;
        color: #a1a1a1;
        text-align: center;
        padding-top: 47px;
      "
     
    >
      <p>
        健康游戏忠告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当
        适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活
      </p>
      <p style="margin: 20px 0">
        家长监护工程 | 防沉迷系统 | 纠纷处理 | 用户协议
      </p>
      <p>安徽追游网络科技有限公司互联网ICP备案：皖ICP备2023010426号-2</p>
    </div> -->
  </div>
</template>
<script>
import AppBg from "../components/AppBg.vue";
import Popup from "../components/Popup.vue";
import MyVouchers from "../components/MyVouchers.vue";
import MyGift from "../components/MyGift.vue";
import ServerLaunch from "./../components/ServerLaunch.vue";
import ShareDialog from "./../components/ShareDialog.vue";
import MyAcc from "../components/MyAcc.vue";

import MyVip from "../components/MyVip.vue";

export default {
  components: {
    AppBg,
    Popup,
    MyVouchers,
    MyGift,
    ServerLaunch,
    ShareDialog,
    MyVip,
    MyAcc,
  },
  data() {
    return {
      flagNav: 1,
      flagS: false,
      flagI: 1,
      flagd: false,
      flagBg: false,
      flagServer: false,
      flagHistory: false,
      time: 7200,
      show1: false,
      reList: [],
      details: {},
      tranList: [],
      newList: [],
      apList: [],
      hotGame: [],
      hotS: [],
      userS: [],
      hg: [],
      page: 1,
      flagG1: false,
      flagG2: false,
      textS: "",
      slist: [],
      userinfo: null,
      accde: {},
      flagDetail: false,
      flagcollect: false,
      flagQuan: false,
      gid: 0,
      flagGift: false,
      flagVip: false,
      flagAcc: false,
      flagShare: false,
      scrollTop: 0,
      scrollTop1: 0,
      ptop: 0,
      num: 0,
      hnum: 1,
    };
  },
  watch: {
    scrollTop(n, o) {
      // console.log(n,o)
      if (this.num >= 0) {
        if (n < o) {
          if (this.num < 409.2) {
            this.num = this.num + 34.1;
          }

          // console.log(this.ptop,'oooooo')
        } else {
          this.num = this.num - 34.1;
        }
      } else {
        this.num = 0;
      }
      //console.log(this.num,'oooooo')
    },
  },
  computed: {
    userInfoEmpty() {
      return this.$store.getters["userInfoIsEmpty"];
    },
  },
  methods: {
    sa(e) {
      this.scrollTop = this.$refs.cmain.scrollTop;
      this.hnum = this.$refs.games.offsetHeight;
      //  if(this.hnum-this.scrollTop>873){
      //   if(this.num==0){
      this.ptop = this.scrollTop - 400 + 86;
      //  }else{
      //   this.ptop=this.scrollTop-400+86+this.num
      //  }
      //  }else{
      //   return
      //  }
      // console.log('div滚动高度:', this.scrollTop,this.hnum);
      // console.log(this.ptop,'ppppppp')
      if(this.flagNav==1){
        const { scrollTop, clientHeight, scrollHeight } = this.$refs.main1;
      // const { scrollTop, clientHeight, scrollHeight } = this.$refs.games;
      // console.log( this.scrollTop,scrollTop, clientHeight, scrollHeight)
      if (scrollHeight-this.scrollTop <804) {
        // console.log("滚动到底部了");
        // 这里可以做加载更多数据的操作
        this.page = this.page + 1;

        this.$http
          .post("pc/index/selected", {
            page: this.page,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.reList.push(...res.data);
              // console.log(this.reList, "ioii");
            }
          });
      }
      }
      if(this.flagNav==2){
        const { scrollTop, clientHeight, scrollHeight } = this.$refs.main2;
      // const { scrollTop, clientHeight, scrollHeight } = this.$refs.games;
      // console.log( this.scrollTop,scrollTop, clientHeight, scrollHeight)
      if (scrollHeight-this.scrollTop <790) {
        // console.log("滚动到底部了");
        // 这里可以做加载更多数据的操作
        this.page = this.page + 1;

        this.$http
          .post("pc/index/news_game", {
            page: this.page,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.newList.push(...res.data);
              // console.log(this.reList, "ioii");
            }
          });
      }
      }
      if(this.flagNav==3){
        const { scrollTop, clientHeight, scrollHeight } = this.$refs.main3;
      // const { scrollTop, clientHeight, scrollHeight } = this.$refs.games;
      console.log( this.scrollTop,scrollTop, clientHeight, scrollHeight)
      if (scrollHeight-this.scrollTop <790) {
        // console.log("滚动到底部了");
        // 这里可以做加载更多数据的操作
        this.page = this.page + 1;

        this.$http
          .post("pc/index/reservation_list", {
            page: this.page,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.apList.push(...res.data);
              // console.log(this.reList, "ioii");
            }
          });
      }
      }
    },
    onScroll4(event) {
      console.log(123);
      const { scrollTop, clientHeight, scrollHeight } = this.$refs.games;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        console.log("滚动到底部了");
        // 这里可以做加载更多数据的操作
        this.page = this.page + 1;

        this.$http
          .post("pc/index/selected", {
            page: this.page,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.reList.push(...res.data);
              // console.log(this.reList, "ioii");
            }
          });
      }
    },
    onScroll1(event) {
      console.log(123);
      const { scrollTop, clientHeight, scrollHeight } = this.$refs.games;
      if (scrollTop + clientHeight >= scrollHeight - 190) {
        console.log("滚动到底部了");
        // 这里可以做加载更多数据的操作
        this.page = this.page + 1;

        this.$http
          .post("pc/index/selected", {
            page: this.page,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.reList.push(...res.data);
              // console.log(this.reList, "ioii");
            }
          });
      }
    },
    onScroll2(event) {
      const { scrollTop, clientHeight, scrollHeight } = this.$refs.main2;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        console.log("滚动到底部了");
        // 这里可以做加载更多数据的操作
        this.page = this.page + 1;
        this.$http
          .post("pc/index/news_game", {
            page: this.page,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.newList.push(...res.data);
              // console.log(this.newList, "newList ");
            }
          });
      }
    },
    onScroll3(event) {
      const { scrollTop, clientHeight, scrollHeight } = this.$refs.main3;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        console.log("滚动到底部了");
        // 这里可以做加载更多数据的操作
        this.page = this.page + 1;
        this.$http
          .post("pc/index/reservation_list", {
            page: this.page,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.apList.push(...res.data);
              // console.log(this.apList, "apList");
            }
          });
      }
    },
    scrollToTopSmooth() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    toHome() {
      this.$bus.$emit("changeNav");
      this.$router.push({ name: "index" }, () => {});
    },
    // 预约
    game_reservation(id, txt) {
      if (!this.userinfo) {
        this.open();
        return;
      }
      this.$http
        .post("pc/game/game_reservation", {
          game_id: id,
        })
        .then((res) => {
          console.log(res);
          this.$msg.success("预约成功" + (txt ? "，" + txt : ""));
          this.page = 1;
          this.$http
            .post("pc/index/reservation_list", {
              page: this.page,
            })
            .then((res) => {
              if (res.code == 20000) {
                this.apList = res.data;
              }
            });
        });
    },
    openShare(e) {
      if (!this.userinfo) {
        this.open();
        return;
      }
      this.gid = e;
      this.flagShare = true;
    },
    toServer(e) {
      this.gid = e;
      if (!this.details.server_name) {
        this.flagHistory = true;
      }
      this.flagServer = true;
    },
    toAcc(e) {
      this.gid = e;
      this.flagAcc = true;
    },
    toVip(e) {
      this.gid = e;
      this.flagVip = true;
    },
    closeQuan() {
      this.flagQuan = false;
      this.flagGift = false;
      this.flagServer = false;
      this.flagShare = false;
      this.flagVip = false;
      this.flagAcc = false;
      this.flagHistory = false;
    },
    toGift(e) {
      this.gid = e;
      this.flagGift = true;
    },
    toQuan(e) {
      this.gid = e;
      this.$http
        .post("pc/Game/cp_ticket_list", {
          game_id: this.gid,
          page: 1,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 20000) {
            if (res.data.data.length > 0) {
              this.flagQuan = true;
            } else {
              this.$msg.error("该游戏暂无优惠券");
            }
          }
        });
    },
    toCollect(e) {
      if (localStorage.getItem("token")) {
        this.$http
          .post("pc/game/game_collect", {
            game_id: e,
          })
          .then((res) => {
            console.log(res);
            if (this.flagcollect) {
              this.$msg.success("取消收藏成功");
            } else {
              this.$msg.success("收藏成功");
            }
            this.flagcollect = !this.flagcollect;
          });
      } else {
        this.open();
        return;
      }
    },
    toDel() {
      this.flagDetail = false;
    },
    toCDetails(e) {
      // this.tranList = [];
      this.$refs.cmain.scrollTop=0
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.$http
        .post("pc/transaction/transaction_detail", {
          id: e,
        })
        .then((res) => {
          if (res.code == 20000) {
            // this.reList = res.data;
            console.log(res, "de....");
            this.accde = res.data;
          }
        });
      this.flagDetail = true;
      // this.flagBuy = true;
    },
    // 用户信息
    getUserInfo() {
      var token = localStorage.getItem("token");
      console.log("token", token);
      console.log("test  aaaaaaaa", localStorage.getItem("userinfo"), token);
      if (token) {
        var userInfo = localStorage.getItem("userinfo");
        if (userInfo) {
          this.userinfo = JSON.parse(userInfo);
        } else {
          this.$http.post("pc/user/userinfo").then((res) => {
            if (res.code == 20000) {
              this.userinfo = res.data;
              console.log(res, "用户信息");
            }
          });
        }
      }
    },
    closeA() {
      this.flagG1 = false;
      this.flagG2 = false;
      this.flagS = false;
      this.flagd = false;
    },
    search1(e) {
      this.textS = e;
      this.flagS = false;
      this.flagd = false;
      this.$http
        .post("pc/index/search", {
          keyword: this.textS,
        })
        .then((res) => {
          console.log(res, "ooppp");
          if (res.code == 20000) {
            // this.apList.push(...res.data);
            // console.log(this.apList, "apList");
            this.slist = res.data;
          }
        });
      this.flagG1 = true;
    },
    search2(e) {
      this.textS = e;
      this.flagS = false;
      this.flagd = false;
      this.$http
        .post("pc/index/search", {
          keyword: this.textS,
        })
        .then((res) => {
          console.log(res, "ooppp");
          if (res.code == 20000) {
            // this.apList.push(...res.data);
            // console.log(this.apList, "apList");
            this.slist = res.data;
          }
        });
      this.flagG2 = true;
    },
    search() {
      this.flagS = false;
      this.flagd = false;
      if (this.textS == "") {
        return;
      }
      this.$http
        .post("pc/index/search", {
          keyword: this.textS,
        })
        .then((res) => {
          console.log(res, "ooppp");
          if (res.code == 20000) {
            // this.apList.push(...res.data);
            // console.log(this.apList, "apList");
            this.slist = res.data;
          }
        });
      this.flagG1 = true;
    },
    search_2() {
      this.flagS = false;
      this.flagd = false;
      console.log(2222222, this.textS);
      if (this.textS == "") {
        this.flagG2 = false;
        return;
      }
      this.$http
        .post("pc/index/search", {
          keyword: this.textS,
        })
        .then((res) => {
          console.log(res, "ooppp");
          if (res.code == 20000) {
            // this.apList.push(...res.data);
            // console.log(this.apList, "apList");
            this.slist = res.data;
          }
        });
      this.flagG2 = true;
    },
    checkScroll() {
      const scrollY = window.scrollY || window.pageYOffset;
      const clientHeight = window.innerHeight;
      const scrollHeight = document.body.scrollHeight;

      // 判断是否接近底部，这里使用了 100 作为阈值，你可以根据需要调整
      if (scrollY + clientHeight >= scrollHeight - 1) {
        console.log("llll");

        // this.isBottom = true;
        this.page = this.page + 1;
        if (this.flagNav == 1) {
          this.$http
            .post("pc/index/selected", {
              page: this.page,
            })
            .then((res) => {
              if (res.code == 20000) {
                this.reList.push(...res.data);
                // console.log(this.reList, "ioii");
              }
            });
        }
        if (this.flagNav == 2) {
          this.$http
            .post("pc/index/news_game", {
              page: this.page,
            })
            .then((res) => {
              if (res.code == 20000) {
                this.newList.push(...res.data);
                // console.log(this.newList, "newList ");
              }
            });
        }
        if (this.flagNav == 3) {
          this.$http
            .post("pc/index/reservation_list", {
              page: this.page,
            })
            .then((res) => {
              if (res.code == 20000) {
                this.apList.push(...res.data);
                // console.log(this.apList, "apList");
              }
            });
        }
      } else {
        console.log("8888");

        // this.isBottom = false;
      }
    },
    open() {
      this.show1 = true;
    },
    toMine() {
      this.$bus.$emit("change");
      // console.log("909");
      window.location.href = "/#/mine";
    },
    toplay() {
      this.flagBg = true;
    },
    hide1() {
      this.flagBg = false;
    },
    hide2() {
      this.show1 = false;
    },
    onChange(e) {
      this.page = 1;
      if (e == 1) {
        this.$http
          .post("pc/index/selected", {
            page: 1,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.reList = res.data;
              console.log(res, "ioii");
            }
          });
      }
      if (e == 2) {
        this.$http
          .post("pc/index/news_game", {
            page: 1,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.newList = res.data;
              console.log(res, "newList ");
            }
          });
      }
      if (e == 3) {
        this.$http
          .post("pc/index/reservation_list", {
            page: 1,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.apList = res.data;
              console.log(res, "apList");
            }
          });
      }
      this.flagNav = e;
    },
    toDetails(e) {
      this.scrollTop1=this.$refs.cmain.scrollTop
      this.$refs.cmain.scrollTop=0
      this.$http
        .post("pc/game/game_detail", {
          game_id: e,
        })
        .then((res) => {
          if (res.code == 20000) {
            // this.reList = res.data;
            // console.log(res, "de....");
            this.details = res.data;
            this.flagcollect = res.data.collect;
          }
        });
      this.$http
        .post("pc/transaction/game_transaction", {
          game_id: e,
        })
        .then((res) => {
          if (res.code == 20000) {
            // this.reList = res.data;
            console.log(res, "jy....");
            this.tranList = res.data;
          }
        });
      this.flagI = 0;
      this.flagG1 = false;
      this.flagG2 = false;
    },
    inputFocus() {
      this.$http.post("pc/index/search_record").then((res) => {
        if (res.code == 20000) {
          // this.reList = res.data;
          console.log(res, "ss....");
          this.hotGame = res.data.game;
          this.hotS = res.data.hot_search;
          this.userS = res.data.user_search;
          // this.tranList = res.data;
        }
      });
      this.flagS = true;
    },
    inputBlur() {
      this.flagS = false;
    },
    inputFocus1() {
      this.$http.post("pc/index/search_record").then((res) => {
        if (res.code == 20000) {
          // this.reList = res.data;
          console.log(res, "ss....");
          this.hotGame = res.data.game;
          this.hotS = res.data.hot_search;
          this.userS = res.data.user_search;
          // this.tranList = res.data;
        }
      });
      this.flagd = true;
    },
    inputBlur1() {
      this.flagd = false;
    },
    onBack() {
      console.log(this.scrollTop1,'lllllllllllllll')
      this.$refs.cmain.scrollTop=this.scrollTop1
      this.flagI = 1;

    },
    todownLoad() {
      this.$http.post("pc/index/download_app", {}).then((res) => {
        if (res.code == 20000) {
          // this.reList = res.data;
          // console.log(res.data,'//////////////')
          window.location.href = res.data;
          this.$msg.success("下载成功");
        }
      });
    },
  },
  mounted() {
    this.getUserInfo();
    console.log(this.userinfo, "this.userinfo.................");
    setInterval(() => {
      this.time--;
    }, 1000);
    this.page = 1;
    window.addEventListener("scroll", this.checkScroll);
    this.$http
      .post("pc/index/selected", {
        page: 1,
      })
      .then((res) => {
        if (res.code == 20000) {
          this.reList = res.data;
          console.log(res, "ioii");
        }
      });
    this.$http.post("pc/index/hot_list").then((res) => {
      if (res.code == 20000) {
        this.hg = res.data;
        console.log(res, "hg...............");
      }
    });
    if (!this.details.server_name) {
      this.flagHistory = true;
    }
  },
  beforeDestroy() {
    // 组件销毁前移除监听器，避免内存泄漏
    window.removeEventListener("scroll", this.checkScroll);
  },
};
</script>
<style lang="scss" scoped>
.index {
  width: 1474px;
  // background: pink;
  margin: 0 auto;
  position: relative;
  .nav {
    height: 80px;
    // background-color: rgb(115, 230, 195);
    display: flex;
    position: relative;
    .logo {
      width: 142px;
      height: 44px;
      margin-top: 20px;
      margin-right: 215px;
    }
    .de_nav {
      width: 1047px;
      height: 48px;
      margin-top: 20px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > img {
        width: 30px;
        height: 30px;
      }
      .input_s {
        width: 277px;
        height: 48px;
        background: #f5f5f5;
        border-radius: 24px 24px 24px 24px;
        position: relative;
        input {
          width: 277px;
          height: 48px;
          background: #f5f5f5;
          border-radius: 24px 24px 24px 24px;
          box-sizing: border-box;
          padding-left: 27px;
        }
        img {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 14px;
          right: 23px;
        }
      }
    }
    .input {
      width: 690px;
      height: 48px;
      background: #f5f5f5;
      // background: #b94848;
      position: relative;
      border-radius: 24px 24px 24px 24px;
      margin-top: 20px;
      position: relative;
      margin-right: 366px;
      input {
        width: 690px;
        height: 48px;
        border-radius: 24px 24px 24px 24px;
        padding-left: 25px;
        background: #f5f5f5;
      }
      img {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 14px;
        right: 23px;
      }
    }
    .login {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 24px;
      right: 19px;
    }
  }
  .main {
    width: 690px;
    // height: 100%;
    // background: rgb(177, 235, 144);
    margin-left: 359px;
    padding-top: 1px;
    .main_nav {
      display: flex;
      height: 20px;
      margin-top: 14px;
      align-content: center;
      > div {
        width: 43px;
        height: 20px;
        font-weight: 500;
        line-height: 20px;

        text-align: center;
        font-size: 16px;
        color: rgba(112, 112, 112, 0.86);
      }
      .img {
        margin-left: 22px;
        margin-right: 53px;
      }
      img {
        width: 43px;
        height: 20px;
      }
      .main_nav1 {
        margin-right: 49px;
      }
    }
    .main_cases {
      width: 690px;
      // height: 100vh;
      margin-top: 32px;
      // overflow: auto;
      // padding-bottom: 100px;
      .main_case {
        cursor: pointer;
        width: 690px;
        height: 434px;
        background: #f5f5f5;
        // box-shadow: 0px 6px 22px 1px rgba(0, 0, 0, 0.16);
        border-radius: 28px 28px 28px 28px;
        overflow: hidden;
        margin-bottom: 43px;
        img {
          width: 690px;
          height: 343px;
          background-color: rgb(50, 85, 85);
          display: block;
        }
        .main_c1 {
          font-weight: 700;
          font-size: 18px;
          color: #191918;
          display: flex;
          align-items: center;
          margin-left: 32px;
          margin-top: 13px;
          span {
            height: 19px;
            font-weight: 300;
            font-size: 12px;
            color: #ffffff;
            line-height: 19px;
            padding: 0 6px;
            background: #f8810a;
            border-radius: 4px 4px 4px 4px;
            margin-right: 6px;
          }
          span:nth-child(1) {
            margin-left: 16px;
          }
        }

        .main_c2 {
          font-weight: 300;
          font-size: 14px;
          color: rgba(81, 81, 81, 0.86);
          margin-left: 32px;
          margin-top: 10px;
        }
      }
      .main_case1 {
        display: flex;
        height: 95px;
        display: flex;
        align-items: center;
        margin-bottom: 57px;
        position: relative;
        img {
          width: 95px;
          height: 95px;
          margin-left: 15px;
          margin-right: 24px;
        }
        .main_c4 {
          width: 94px;
          height: 36px;
          border-radius: 22px;
          background: linear-gradient(
              to right,
              rgba(250, 138, 10, 1) 0%,
              rgba(245, 72, 7, 1)
            )
            100%;
          box-shadow: 0px 2px 10px 1px rgba(255, 83, 0, 0.55);
          font-weight: 400;
          text-align: center;
          line-height: 36px;
          font-size: 14px;
          color: #ffffff;
          position: absolute;
          top: 30px;
          right: 35px;
        }
        .main_c1 {
          font-weight: 700;
          font-size: 16px;
          color: #191918;
        }
        .main_c3 {
          font-weight: 300;
          font-size: 12px;
          color: rgba(170, 170, 170, 0.86);
          margin-top: 8px;
          margin-bottom: 8px;
          span {
            margin-right: 8px;
          }
        }
        .main_c2 {
          width: 60px;
          height: 17px;
          background: #f8bf0a;
          border-radius: 4px 4px 4px 4px;
          text-align: center;
          line-height: 17px;
          font-weight: 300;
          font-size: 10px;
          color: #ffffff;
        }
        .main_c2_ {
          font-weight: 300;
          font-size: 12px;
          color: rgba(170, 170, 170, 0.86);
        }
      }
    }
  }
  .hot {
    position: absolute;
    top: 86px;
    right: 0;
    width: 351px;
    height: 100vh;
    // overflow: auto;
    // background: #acc;
    // padding-bottom: 100px;
    .hot1 {
      width: 351px;
      height: 764px;
      background: #f5f5f5;
      border-radius: 28px 28px 28px 28px;
      margin-bottom: 39px;
      padding-top: 19px;
      .hot1_1 {
        height: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        img {
          width: 65px;
          height: 15px;
          margin-left: 27px;
          margin-right: 203px;
        }
        span {
          font-weight: 300;
          font-size: 12px;
          color: rgba(170, 170, 170, 0.86);
        }
      }
      .hot1_cases {
        height: 668px;
        width: 351px;
        box-sizing: border-box;
        overflow-y: auto;
        // padding-left: 27px;
        .hot1_case {
          box-sizing: border-box;
          padding-left: 27px;
          height: 64px;
          width: 351px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          img {
            height: 64px;
            width: 64px;
            margin-right: 12px;
          }
          div {
            font-weight: 700;
            font-size: 16px;
            color: #191918;
            margin-top: 5px;
            span {
              font-weight: 500;
              font-size: 12px;
              color: rgba(170, 170, 170, 0.86);
              margin-right: 8px;
            }
          }
        }
      }
    }
    .hot_ {
      width: 351px;
      height: 238px;
      background: #f5f5f5;
      border-radius: 28px 28px 28px 28px;
      // display: flex;
      // align-items: center;
      padding: 20px 24px;
      margin-top: 20px;
      p {
        font-weight: 700;
        font-size: 12px;
        color: rgba(110, 110, 110, 0.86);
        margin-bottom: 3px;
        text-align: center;
        line-height: 18px;
      }
      span {
        cursor: pointer;
        a {
          color: rgba(110, 110, 110, 0.86);
        }
      }
    }
    .hot2 {
      width: 351px;
      height: 191px;
      background: #f5f5f5;
      border-radius: 28px 28px 28px 28px;
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 48px;
      > p {
        position: absolute;
        bottom: 30px;
        left: 67px;
        width: 217px;
        height: 38px;
        background: #f57a00;
        border-radius: 13px 13px 13px 13px;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 38px;
      }
      img {
        width: 78px;
        height: 78px;
        border-radius: 0px 0px 0px 0px;
        margin-left: 27px;
        margin-right: 22px;
      }
      .hot2_1 {
        p {
          font-weight: 700;
          font-size: 20px;
          color: rgba(25, 25, 24, 1);
          margin-bottom: 4px;
          span {
            color: #f8ba10;
            margin-left: 4px;
          }
        }
        div {
          // width: 180px;
          p {
            font-weight: 700;
            font-size: 12px;
            color: rgba(110, 110, 110, 0.86);
            margin-bottom: 3px;
            text-align: center;
          }
        }
      }
    }
  }
}
.input_c {
  width: 690px;
  // height: 557px;
  background: #ffffff;
  box-shadow: 0px 3px 22px 1px #f1f1f1;
  border-radius: 25px 25px 25px 25px;
  position: absolute;
  left: 359px;
  box-sizing: border-box;
  top: 88px;
  padding: 24px 49px 60px;
  z-index: 10;
  p {
    font-weight: 300;
    font-size: 14px;
    color: #909094;
    margin-bottom: 14px;
  }
  .input_c1 {
    display: flex;
    flex-wrap: wrap;
    span {
      // width: 114px;
      flex-shrink: 0;

      height: 37px;
      background: #f5f5f5;
      border-radius: 19px 19px 19px 19px;
      font-size: 12px;
      line-height: 37px;
      color: rgba(145, 145, 145, 1);
      padding: 0 20px;
      margin-right: 17px;
      margin-bottom: 17px;
    }
  }
  > img {
    width: 44px;
    height: 18px;
  }
  .input_c2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > div {
      font-weight: 300;
      font-size: 16px;
      height: 44px;
      color: #919191;
      width: 296px;
      // background: #caa;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      // flex-wrap: wrap !important;

      span {
        width: 4px;
        height: 4px;
        background: #f65d08;
        border-radius: 100%;
        margin-right: 12px;
      }
      img {
        width: 22px;
        height: 22px;
        margin-left: 20px;
      }
    }
  }
}
.input_content {
  width: 351px;
  // height: 557px;
  background: #ffffff;
  box-shadow: 0px 3px 22px 1px #f1f1f1;
  border-radius: 25px;
  position: absolute;
  right: 0;
  box-sizing: border-box;
  top: 88px;
  padding: 24px 24px 20px 10px;
  z-index: 10;
  .main_case1 {
    box-sizing: border-box;
    height: 64px;
    width: 351px;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    > img {
      height: 64px;
      width: 64px;
      margin-right: 12px;
    }
  }
  p {
    font-weight: 300;
    font-size: 14px;
    color: #909094;
    margin-bottom: 14px;
  }
}
.main {
  padding-bottom: 40px;
  .banner {
    width: 690px;
    height: 390px;
    // background: #acc;
    border-radius: 25px 25px 25px 25px;
    .el-carousel {
      height: 100%;
      border-radius: 25px 25px 25px 25px;
    }
    .el-carousel__item h3 {
      color: #000;
      font-size: 18px;
      opacity: 0.75;
      line-height: 300px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #000;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #000;
    }
  }
  .d_h5 {
    margin-left: 29px;

    > img {
      width: 145px;
      height: 27px;
      margin-bottom: 43px;
    }
    .span_p {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 625px;
      text-align: left;
      font-weight: 300;
      font-size: 16px;
      // line-height: 28px;
      // text-decoration: underline;
      color: rgba(170, 170, 170, 0.86);
      span {
        height: 1px;
        width: 159px;
        background: rgba(170, 170, 170, 0.86);
      }
    }
    .d_h5_1 {
      width: 625px;
      // height: 252px;
      background: #ffffff;
      box-shadow: 0px 6px 22px 1px rgba(0, 0, 0, 0.09);
      border-radius: 19px 19px 19px 19px;
      box-sizing: border-box;
      padding-top: 23px;
      padding-left: 30px;
      margin-bottom: 41px;
      padding-bottom: 40px;

      p {
        font-weight: 700;
        font-size: 16px;
        color: #191918;
      }
      .d_h5_1_c1 {
        display: flex;
        margin-top: 11px;
        margin-bottom: 16px;
        position: relative;
        > span {
          height: 19px;
          font-weight: 300;
          font-size: 12px;
          background: rgba(248, 191, 10, 1);
          color: #ffffff;
          line-height: 19px;
          padding: 0 9px;
          border-radius: 4px;
          margin-right: 9px;
        }
        div {
          height: 19px;
          position: absolute;
          top: 0;
          right: 36px;
          font-weight: 700;
          font-size: 16px;
          color: #f6696c;
          span {
            font-size: 12px;
          }
        }
      }
      .d_h5_1_c2 {
        padding-right: 30px;
        // height: 123px;
        display: flex;
        // justify-content: space-between;
        div {
          width: 123px;
          height: 123px;
          background: #f5f5f5;
          border-radius: 14px 14px 14px 14px;
          margin-right: 8px;
        }
      }
    }
  }
  .d_h4 {
    margin-bottom: 40px;
    margin-left: 29px;
    .el-carousel {
      height: 100%;
      border-radius: 25px 25px 25px 25px;
    }
    .el-carousel__item {
      width: 236px;
    }

    // .el-carousel__item:nth-child(2n) {
    //   // background-color: #000;
    // }

    // .el-carousel__item:nth-child(2n + 1) {
    //   // background-color: #000;
    // }
    img {
      width: 145px;
      height: 27px;
      margin-bottom: 33px;
    }
    div {
      font-weight: 300;
      font-size: 16px;
      color: rgba(59, 58, 58, 0.83);
      padding-left: 3px;
      padding-right: 13px;
      line-height: 24px;
    }
  }
  .d_h3 {
    width: 636px;
    height: 58px;
    border-radius: 29px;
    box-shadow: 0px 3px 22px 1px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 60px;
    margin-left: 29px;

    div {
      margin-left: 46px;
      margin-right: 34px;
      font-weight: 700;
      font-size: 16px;
      color: #ffc844;
    }
    span {
      font-weight: 300;
      font-size: 16px;
      color: #909094;
    }
    b {
      font-weight: 700;
      font-size: 29px;
      color: #ffc844;
      position: absolute;
      top: 7px;
      right: 45px;
    }
  }
  .d_h2 {
    width: 636px;
    height: 111px;
    border-radius: 20px;
    box-shadow: 0px 3px 22px 1px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    margin-bottom: 44px;
    margin-left: 29px;
    .d_h2_1 {
      width: 318px;
      padding-top: 14px;
      box-sizing: border-box;
      height: 111px;
      .d_h2_1_1 {
        font-weight: 700;
        font-size: 16px;
        color: #2c2c2c;
        display: flex;
        align-items: center;
        margin-left: 42px;
        img {
          margin-right: 7px;
          width: 29px;
          height: 29px;
        }
      }
      .d_h2_1_2 {
        height: 35px;
        border-left: 1px dashed rgba(255, 200, 68, 1);
        padding-left: 14px;
        margin-left: 56px;
        margin-top: 10px;
        position: relative;
        padding-top: 1px;
        b {
          display: block;
          width: 6px;
          height: 6px;
          background: rgba(255, 200, 68, 1);
          border-radius: 100%;
          position: absolute;
          top: -3px;
          left: -4px;
        }
        span {
          font-weight: 300;
          font-size: 12px;
          display: block;
          color: #909094;
          margin-top: -5px;
          margin-bottom: 10px;
        }
        div {
          display: flex;
          align-items: center;
          font-weight: 300;
          font-size: 14px;
          color: #2c2c2c;
          img {
            width: 37px;
            height: 10px;
            margin-right: 14px;
          }
        }
      }
    }
    // .d_h2_2:nth-child(1){
    //   margin-left: 300px;
    // }
    .d_h2_2_ {
      margin-left: 35px;
    }
    .d_h2_2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 300;
      font-size: 14px;
      color: #909094;
      margin-right: 55px;
    }
  }
  .d_h1 {
    padding-left: 29px;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    position: relative;
    .btn {
      width: 116px;
      height: 44px;
      border-radius: 22px;
      background: linear-gradient(
        to right,
        rgba(250, 138, 10, 1) 0%,
        rgba(245, 72, 7, 1) 100%
      );
      box-shadow: 0px 2px 10px 1px rgba(255, 83, 0, 0.55);
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      line-height: 44px;
      position: absolute;
      bottom: 0;
      right: 42px;
    }
    > img {
      width: 106px;
      height: 106px;
      border-radius: 0px 0px 0px 0px;
      margin-right: 17px;
    }
    .d_h1_1 {
      font-weight: 700;
      font-size: 16px;
      color: #191918;
    }
    .d_h1_2 {
      font-weight: 300;
      font-size: 12px;
      color: rgba(170, 170, 170, 0.86);
      margin-top: 6px;
      margin-bottom: 10px;
    }
    .d_h1_3 {
      display: flex;
      span {
        // width: 69px;
        height: 21px;
        font-size: 12px;
        line-height: 21px;
        font-weight: 300;
        // background: #F8810A;
        border-radius: 4px 4px 4px 4px;
        padding: 0 10px;
        margin-right: 9px;
      }
      .d_h1_3_1 {
        background: rgba(248, 129, 10, 1);
        color: #ffffff;
      }
      .d_h1_3_2 {
        border: 1px solid #f8810a;
        color: #f8810a;
      }
    }
  }
}
.input_cK {
  width: 351px;
  // height: 557px;
  background: #ffffff;
  box-shadow: 0px 3px 22px 1px #f1f1f1;
  border-radius: 25px 25px 25px 25px;
  position: absolute;
  right: 14px;
  box-sizing: border-box;
  top: 88px;
  padding: 16px 27px 37px;
  z-index: 10;
  p {
    font-weight: 300;
    font-size: 14px;
    color: #909094;
    margin-bottom: 14px;
  }
  .input_c1 {
    display: flex;
    flex-wrap: wrap;
    span {
      // width: 114px;
      flex-shrink: 0;

      height: 37px;
      background: #f5f5f5;
      border-radius: 19px 19px 19px 19px;
      font-size: 12px;
      line-height: 37px;
      color: rgba(145, 145, 145, 1);
      padding: 0 20px;
      margin-right: 17px;
      margin-bottom: 17px;
    }
  }
  > img {
    width: 44px;
    height: 18px;
  }
  .input_c2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > div {
      font-weight: 300;
      font-size: 16px;
      height: 44px;
      color: #919191;
      width: 296px;
      // background: #caa;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      // flex-wrap: wrap !important;

      span {
        width: 4px;
        height: 4px;
        background: #f65d08;
        border-radius: 100%;
        margin-right: 12px;
      }
      img {
        width: 22px;
        height: 22px;
        margin-left: 20px;
      }
    }
  }
}
.main_case1 {
  display: flex;
  height: 95px;
  display: flex;
  align-items: center;
  margin-bottom: 57px;
  position: relative;
  img {
    width: 95px;
    height: 95px;
    margin-left: 15px;
    margin-right: 24px;
  }
  .main_c4 {
    width: 94px;
    height: 36px;
    border-radius: 22px;
    background: linear-gradient(
        to right,
        rgba(250, 138, 10, 1) 0%,
        rgba(245, 72, 7, 1)
      )
      100%;
    box-shadow: 0px 2px 10px 1px rgba(255, 83, 0, 0.55);
    font-weight: 400;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    top: 30px;
    right: 35px;
  }
  .main_c4_gray {
    width: 94px;
    height: 36px;
    border-radius: 22px;
    background: rgb(207, 207, 207);
    font-weight: 400;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    top: 30px;
    right: 35px;
  }
  .main_c1 {
    font-weight: 700;
    font-size: 16px;
    color: #191918;
  }
  .main_c3 {
    font-weight: 300;
    font-size: 12px;
    color: rgba(170, 170, 170, 0.86);
    margin-top: 8px;
    margin-bottom: 8px;
    span {
      margin-right: 8px;
    }
  }
  .main_c2 {
    width: 60px;
    height: 17px;
    background: #f8bf0a;
    border-radius: 4px 4px 4px 4px;
    text-align: center;
    line-height: 17px;
    font-weight: 300;
    font-size: 10px;
    color: #ffffff;
  }
  .main_c2_ {
    font-weight: 300;
    font-size: 12px;
    color: rgba(170, 170, 170, 0.86);
  }
  .main_cq {
    // width: 41px;
    display: flex;
    span {
      height: 17px;
      background: #f8bf0a;
      border-radius: 4px 4px 4px 4px;
      padding: 0 10px;
      font-weight: 300;
      font-size: 10px;
      line-height: 17px;
      color: #ffffff;
      margin-right: 5px;
    }
  }
}
.details_buy {
  width: 625px;
  height: 819px;
  background: #ffffff;
  box-shadow: 0px 6px 22px 1px rgba(0, 0, 0, 0.09);
  border-radius: 19px 19px 19px 19px;
  position: absolute;
  top: 53px;
  left: 43px;
  z-index: 100;
  padding-top: 26px;
  .buy_del {
    margin-left: 27px;
    width: 30px;
    height: 30px;
    margin-bottom: 32px;
  }
  .details_buy3 {
    margin-top: 34px;
    padding-left: 42px;
    .buy_p1 {
      font-weight: 700;
      font-size: 19px;
      color: #1c1c1e;
      margin-bottom: 16px;
    }
    .buy_d1 {
      width: 300px;
      font-weight: 300;
      font-size: 13px;
      color: #8a8a8a;
      margin-bottom: 18px;
    }
    .buy_d2 {
      display: flex;
      flex-wrap: wrap;
      img {
        width: 119px;
        height: 102px;
        border-radius: 12px;
        background-color: rgba(245, 245, 245, 1);
        margin-right: 21px;
        margin-bottom: 20px;
      }
    }
    .buy_p2 {
      font-weight: 300;
      font-size: 13px;
      color: #8a8a8a;
      margin-top: 22px;
      margin-bottom: 18px;
    }
    .buy_p3 {
      font-weight: 700;
      font-size: 12px;
      color: #1c1c1e;
    }
  }
  .details_buy2 {
    font-weight: 300;
    font-size: 16px;
    color: #1c1c1e;
    position: relative;
    padding-left: 42px;
    p {
      margin-bottom: 16px;
      span {
        color: rgba(246, 105, 108, 1);
      }
    }
    div {
      width: 116px;
      height: 44px;
      border-radius: 22px;
      background: linear-gradient(
        to right,
        rgba(250, 138, 10, 1) 0%,
        rgba(245, 72, 7, 1) 100%
      );
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      line-height: 44px;
      box-shadow: 0px 2px 10px 1px rgba(255, 83, 0, 0.55);
      position: absolute;
      top: 54px;
      right: 36px;
    }
  }
  .details_buy1 {
    width: 625px;
    height: 119px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    > span {
      color: rgba(250, 130, 10, 1);
      font-size: 17px;
      position: absolute;
      top: 0;
      right: 36px;
    }
    img {
      width: 119px;
      height: 119px;
      margin-left: 42px;
      margin-right: 20px;
    }
    > div {
      p {
        font-weight: 700;
        font-size: 22px;
        color: #1c1c1e;
        margin-bottom: 20px;
      }
      div {
        font-weight: 500;
        font-size: 20px;
        color: #f6696c;
        span {
          font-size: 16px;
        }
      }
    }
  }
}
.i_share {
  position: absolute;
  top: 0;
  right: 23px;
  img {
    width: 42px;
    height: 42px;
    margin-right: 18px;
  }
}
.i_btn {
  display: flex;
  justify-content: center;
  margin-bottom: 41px;
  .i_btn1 {
    width: 293px;
    height: 44px;
    border-radius: 22px;
    border: 1px solid rgba(249, 125, 9, 1);
    font-weight: 500;
    font-size: 16px;
    color: #f97d09;
    text-align: center;
    line-height: 44px;
    margin-right: 30px;
  }
  .i_btn2 {
    width: 293px;
    height: 44px;
    border-radius: 22px;
    background: rgba(249, 125, 9, 1);
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 44px;
    // margin-left: 30px;
  }
}
</style>