<template>
    <transition name="el-fade-in">
  <div class="bg1" v-show="flagL" @click.stop>
    <div class="bm" @click.stop>
      <span style="cursor: pointer;" @click="tohide" class="bm_span">X</span>
      <div class="bm_nav">
        <div style="cursor: pointer;" @click="toTap(1)" :class="flagTap == 1 ? 'flod' : ''">
          短信登录
          <span v-show="flagTap == 1"></span>
        </div>
        <div style="cursor: pointer;" @click="toTap(2)" :class="flagTap == 2 ? 'flod' : ''">
          密码登录
          <span v-show="flagTap == 2"></span>
        </div>
      </div>
      <div class="phone" v-show="flagTap == 1">
        <input type="text" placeholder="请输入手机号" v-model="phone1" />
        <span class="ph1">+86</span>
      </div>
      <div class="phone" v-show="flagTap == 1">
        <input
          type="text"
          placeholder="请输入验证码"
          class="phone1"
          v-model="code"
        />
        <span style="cursor: pointer;" class="ph2" @click="SendSms" v-if="showCode">获取验证码</span>
        <span class="ph2" v-else> {{ sec }}秒后重试 </span>
      </div>
      <div class="phone" v-show="flagTap == 2">
        <input
          type="text"
          placeholder="请输入手机号或账号"
          v-model="phone"
          class="phone1"
        />
      </div>
      <div class="phone" v-show="flagTap == 2">
        <input
          type="text"
          placeholder="请输入密码"
          v-model="pass"
          class="phone1"
        />
      </div>
      <div style="cursor: pointer;" class="bm_btn" @click="login">登录</div>
      <div class="bm_f">
        <p>未登录过羊托游的账号，我们将帮你完成注册</p>
        <p>
          登录即代表同意<span style="cursor: pointer;" @click="toYh">用户协议</span>和<span style="cursor: pointer;" @click="toYs"
            >隐私政策</span
          >
        </p>
      </div>
    </div>
  </div>
</transition>
</template>
  <script>
export default {
  data() {
    return {
      flagV: false,
      flagTap: 1,
      phone: "",
      pass: "",
      phone1: "",
      code: "",
      showCode: true, //是否显示倒计时
      sec: 60, //倒计时,
      flagL: true,
    };
  },
  methods: {
    //发送验证码
    SendSms() {
      if (this.phone1 == "") {
        this.$msg.error("请输入手机号");
        return;
      }
      setTimeout(() => {
        this.$http
          .post("pc/AliSendSms/SendSms", {
            phone: this.phone1,
          })
          .then((res) => {
            console.log(res, "opooo");
            if (res.code == 20000) {
              this.$msg.success(res.msg);
              // 开启倒计时
              let timer = setInterval(() => {
                this.showCode = false;
                this.sec--;
                if (this.sec == 0) {
                  clearInterval(timer);
                  this.sec = 60;
                  this.showCode = true;
                }
              }, 1000);
            }

            // 处理成功响应
          });
      }, 500);
    },
    login() {
      if (this.flagTap == 1) {
        if (this.phone1 == "") {
          this.$msg.error("请输入手机号");
          return;
        }
        if (this.code == "") {
          this.$msg.error("请输入验证码");
          return;
        }
        this.$http
          .post("pc/Login/phone_login", {
            phone: this.phone1,
            code: this.code,
          })
          .then((res) => {
            console.log(res, "code");
            if (res.code == 20000) {
              this.$msg.success("登录成功");
              localStorage.setItem("token", res.token);
              this.flagL = false;
              this.$http.post("app/User/userinfo").then((res) => {
                console.log(res, "userinfo");
                if (res.code == 20000) {
                  this.$store.dispatch("getInfo", res.data);
                  localStorage.setItem("userinfo", JSON.stringify(res.data));
                  location.reload();
                }

                // 处理成功响应
              });

              // localStorage.setItem("userinfo",JSON.stringify(res.data))
            }
            // 处理成功响应
          });
      }
      if (this.flagTap == 2) {
        if (this.phone == "") {
          this.$msg.error("请输入手机号");
          return;
        }
        if (this.pass == "") {
          this.$msg.error("请输入密码");
          return;
        }
        this.$http
          .post("app/Login/login", {
            phone: this.phone,
            password: this.pass,
          })
          .then((res) => {
            console.log(res, "opooo");
            if (res.code == 20000) {
              this.$msg.success("登录成功");
              localStorage.setItem("token", res.token);
              this.flagL = false;
              this.$http.post("app/User/userinfo").then((res) => {
                console.log(res, "userinfo");
                if (res.code == 20000) {
                  this.$store.dispatch("getInfo", res.data);
                  localStorage.setItem("userinfo", JSON.stringify(res.data));
                  location.reload();
                }

                // 处理成功响应
              });
            }
            // 处理成功响应
          });
      }
    },
    toYh() {
      window.open("https://admin.yangtuoyou.com/yhxy.html");
    },
    toYs() {
      window.open("https://admin.yangtuoyou.com/yszc.html");
    },
    showV() {
      this.flagV = true;
    },
    hideV() {
      this.flagV = false;
    },
    tohide() {
      this.$emit("hide2");
    },
    toTap(e) {
      this.flagTap = e;
    },
  },
};
</script>
  <style lang="scss" scoped>
.bg1 {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(112, 112, 112, 0.27);
  z-index: 999999999;
  .bm {
    width: 515px;
    height: 463px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -231px;
    margin-left: -257px;
    background: url("../assets/img/buy/loginBg.png") no-repeat top left;
    background-size: cover;
    padding-top: 81px;
    .bm_span {
      font-size: 16rpx;
      color: RGBA(191, 191, 191, 1);
      position: absolute;
      top: 28px;
      right: 28px;
    }
    .bm_nav {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 25px;
      > div {
        font-weight: 500;
        font-size: 20px;
        color: #191918;
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          width: 46px;
          height: 4px;
          background: #f9c728;
          border-radius: 6px 6px 6px 6px;
          margin-top: 4px;
        }
      }
      .flod {
        font-weight: 700;
      }
    }
  }
  .phone {
    width: 311px;
    height: 50px;
    background: #f9f9f9;
    border-radius: 27px 27px 27px 27px;
    margin-left: 97px;
    margin-bottom: 16px;
    position: relative;
    input {
      width: 311px;
      height: 50px;
      background: #f9f9f9;
      border-radius: 27px 27px 27px 27px;
      padding-left: 60px;
    }
    .ph1 {
      position: absolute;
      font-weight: 500;
      font-size: 16px;
      color: #191918;
      top: 14px;
      left: 20px;
    }
    .ph2 {
      position: absolute;
      top: 14px;
      right: 21px;
      font-weight: 500;
      font-size: 16px;
      color: #f9c728;
    }
  }
  .phone1 {
    padding-left: 20px !important;
  }
  .bm_btn {
    width: 311px;
    height: 50px;
    background: #f9c728;
    border-radius: 27px 27px 27px 27px;
    margin-left: 97px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    margin-bottom: 24px;
  }
  .bm_f {
    font-weight: 300;
    font-size: 12px;
    color: rgba(25, 25, 24, 1);
    text-align: center;
    span {
      color: #f9c728;
    }
  }
}
</style>