<template>
    <transition name="el-fade-in">
  <div class="bg1" style="z-index: 9999999999">
    <div>
      <span style="cursor: pointer;" @click="tohide">X</span>
      <p class="p1">请打开<span>羊托手游客户端</span>体验</p>
      <img src="../assets/img/er.png" alt="" />
      <p class="p2">扫码打开<span>羊托手游APP</span>畅玩</p>
      <!-- <p class="p3">未安装客户端？<span @click="copyLink">立即下载</span></p> -->
      <div class="share_link">
        下载地址：{{ share_url }} <span class="copy_link" style="cursor: pointer;" @click="copyLink">复制</span>
      </div>
      <!-- <div class="bg_div" v-show="flagV">
        <div>
          <img src="../assets/img/buy/back.png" alt="" @click="hideV" />
          <span @click="tohide">X</span>
        </div>
        <p>扫码下载<span>羊托手游客户端</span></p>
        <img src="../assets/img/er.png" alt="" />
      </div> -->
    </div>
  </div>
 </transition>
</template>
<script>
export default {
  data() {
    return {
      flagV: false,
      share_url:'',
      userinfo:null
    };
  },
  mounted() {  
    this.getUserInfo();
    console.log("用户信息",this.userinfo);
    this.share_url = "https://h5.yangtuoyou.com/#/pages/download/download" + (this.userinfo ? "?parent_id=" + this.userinfo.id : "");
  },
  methods: {
    copyLink(){
      const input = document.createElement('input');
      input.value = this.share_url; // 设置要复制的文本
      document.body.appendChild(input);
      input.select(); // 选中文本
      document.execCommand('copy'); // 执行复制操作
      document.body.removeChild(input); // 移除临时的输入框
      this.$msg.success('下载网址已复制到剪贴板');
    },
    // 用户信息
    getUserInfo() {
      var token = localStorage.getItem("token");
      console.log('token',token);
      if (token) {
        var userInfo = localStorage.getItem("userinfo");
        if(userInfo){
          this.userinfo = JSON.parse(userInfo);
        }else{
          this.$http.post("pc/user/userinfo").then((res) => {
            if (res.code == 20000) {
              this.userinfo = res.data;
              console.log(res, "用户信息");
            }
          });
        }
      }
    },
    showV() {
      this.flagV = true;
    },
    hideV() {
      this.flagV = false;
    },
    tohide() {
      this.$emit("hide1");
    },
  },
};
</script>
<style lang="scss" scoped>
.bg1 {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(112, 112, 112, 0.27);
  z-index: 99999999999;
  > div {
    width: 682px;
    height: 420px;
    background: #ffffff;
    box-shadow: 0px 3px 22px 1px rgba(0, 0, 0, 0.16);
    border-radius: 22px 22px 22px 22px;
    box-sizing: border-box;
    padding-top: 42px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -203px;
    // margin: 1.5rem;
    margin-left: -341px;
    .bg_div {
      width: 682px;
      height: 407px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1000;
      background: #ffffff;
      box-shadow: 0px 3px 22px 1px rgba(0, 0, 0, 0.16);
      border-radius: 22px 22px 22px 22px;
      box-sizing: border-box;
      padding-top: 23px;
      div {
        display: flex;
        padding-left: 28px;
        padding-right: 33px;
        align-items: center;
        justify-content: space-between;
        img {
          width: 30px;
          height: 30px;
        }
        span {
          font-size: 24px;
          color: RGBA(191, 191, 191, 1);
        }
      }
      p {
        font-weight: 700;
        font-size: 20px;
        color: #191918;
        text-align: center;
        margin-bottom: 54px;
        span {
          color: rgba(248, 191, 10, 1);
        }
      }
      > img {
        width: 163px;
        height: 163px;
        background: #f5f5f5;
        border-radius: 0px 0px 0px 0px;
        display: block;
        margin: 0 auto;
      }
    }
    > span {
      font-size: 24px;
      font-weight: 500;
      color: RGBA(191, 191, 191, 1);
      position: absolute;
      top: 24px;
      right: 33px;
    }
    .p1 {
      font-weight: 700;
      font-size: 20px;
      color: #191918;
      text-align: center;
      span {
        color: rgba(248, 191, 10, 1);
      }
    }
    > img {
      width: 163px;
      height: 163px;
      background: #f5f5f5;
      margin: 28px auto 30px;
      display: block;
    }
    .p2 {
      font-weight: 500;
      font-size: 16px;
      color: #191918;
      text-align: center;
      margin-bottom: 20px;
      span {
        color: rgba(248, 191, 10, 1);
      }
    }
    .p3 {
      font-weight: 500;
      font-size: 16px;
      color: #191918;
      text-align: center;

      span {
        color: rgba(248, 191, 10, 1);
      }
    }
  }
}
.share_link {
  width: 388px;
  height: 44px;
  line-height: 44px;
  background: #efefef;
  border-radius: 10px;
  text-align: center;
  margin: 0 auto;
  margin-top: 15px;
  position: relative;
  color: #9A9A9A;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
}
.copy_link {
  width: 56px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #4188ff;
  border-radius: 5px 5px 5px 5px;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1000;
  color: white;
  
}
</style>