<template>
    <transition name="el-fade-in">
  <div class="q">
    <div class="main">
      <div class="main_list" v-if="showList">
        <div class="q_h">公告 <span style="cursor: pointer;" @click="close">X</span></div>
        <div class="q_m">
          <div
           style="cursor: pointer;"
            class="acc"
            v-for="(i, j) in List"
            :key="j"
            @click="toDetail(i.id)"
          >
            <div class="acc1">
              <div style="cursor: pointer;" v-if="i.article_type == 1">活动</div>
              <div style="cursor: pointer;" v-if="i.article_type == 2">公告</div>
              <div style="cursor: pointer;" v-if="i.article_type == 3">攻略</div>
              <div style="cursor: pointer;" v-if="i.article_type == 0">转游</div>
              {{ i.title }}
            </div>
            <div class="acc2">{{ i.createtime }}</div>
          </div>
        </div>
        <div class="q_btn">
          <img
            src="../assets/img/z1.png"
            alt=""
            v-if="page == 1 || page == 0"
          />
          <img
            src="../assets/img/z2.png"
            alt=""
            v-if="page > 1"
            @click="toPre"
          />
          <div>{{ page }} / {{ tpage }}</div>
          <img src="../assets/img/y1.png" alt="" v-if="page == tpage" />
          <img
            src="../assets/img/y2.png"
            alt=""
            @click="toNext"
            v-if="page != tpage"
          />
          <!-- <img src="" alt="" /> -->
        </div>
      </div>
      <div class="main_content" v-else>
        <div class="q_h">公告详情 <span style="cursor: pointer;" @click="closeDetail">X</span></div>
        <div class="content">
          <div class="title">{{ Detail.title }}</div>
          <div class="time">{{ Detail.createtime }}</div>
          <div class="con" v-html="Detail.content"></div>
        </div>
      </div>
    </div>
  </div>
</transition>
</template>
<script>
// import { createLogger } from "vuex";
export default {
  props: ["gid"],
  data() {
    return {
      List: "",
      tpage: 1,
      page: 1,
      showList: true,
      Detail: [],
    };
  },
  methods: {
    toDetail(id) {
      this.Detail = [];
      this.showList = false;
      this.$http
        .post("pc/game/notice_detail", {
          id: id,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 20000) {
            this.Detail = res.data;
          }
        });
    },
    close() {
      this.$emit("closeQuan");
    },
    closeDetail() {
      this.showList = true;
    },
    toPre() {
      this.page = this.page - 1;
      this.$http
        .post("pc/Game/notice_list", {
          game_id: this.gid,
          page: this.page,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 20000) {
            this.List = res.data.data;
            this.tpage = res.data.last_page;
            if (this.tpage == 0) {
              this.page = 0;
            }
            //   console.log(res.data, "ppppp");
          }
        });
    },
    receive_ticket(ticket_id) {
      this.page = 1;
      this.$http
        .post("pc/Game/receive_ticket", {
          id: ticket_id,
        })
        .then((res) => {
          //   console.log(res);
          if (res.code == 20000) {
            this.$msg.success("领取成功");
            this.$http
              .post("pc/Game/notice_list", {
                game_id: this.gid,
                page: this.page,
              })
              .then((res) => {
                console.log(res);
                if (res.code == 20000) {
                  this.List = res.data.data;
                  this.tpage = res.data.last_page;
                  if (this.tpage == 0) {
                    this.page = 0;
                  }
                  //   console.log(res.data, "ppppp");
                }
              });
          }
        });
    },
    toNext() {
      this.page = this.page + 1;
      this.$http
        .post("pc/Game/notice_list", {
          game_id: this.gid,
          page: this.page,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 20000) {
            this.List = res.data.data;
            this.tpage = res.data.last_page;
            if (this.tpage == 0) {
              this.page = 0;
            }
            //   console.log(res.data, "ppppp");
          }
        });
    },
  },
  mounted() {
    this.page = 1;
    console.log("ooooooo");
    this.$http
      .post("pc/Game/notice_list", {
        game_id: this.gid,
        page: this.page,
      })
      .then((res) => {
        console.log(res);
        if (res.code == 20000) {
          this.List = res.data.data;
          this.tpage = res.data.last_page;
          if (this.tpage == 0) {
            this.page = 0;
          }
          //   console.log(res.data, "ppppp");
        }
      });
  },
};
</script>
<style scoped lang="scss">
.q {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(112, 112, 112, 0.27);
  top: 0;
  left: 0;
  z-index: 99999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .main {
    width: 796px;
    height: 893px;
    background: #ffffff;
    box-shadow: 0px 3px 22px 1px rgba(0, 0, 0, 0.16);
    border-radius: 22px 22px 22px 22px;
    padding-top: 28px;
    overflow: hidden;
    position: relative;
  }
  .q_h {
    text-align: center;
    position: relative;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 50px;
    color: #191918;
    span {
      position: absolute;
      font-size: 20px;
      color: RGBA(191, 191, 191, 1);
      right: 42px;
      top: 0;
    }
  }
  .q_m {
    padding: 0 33px;
  }
  .claimrecord_m {
    width: 343px;
    height: 88px;
    float: left;
    background: #fff8e9;
    border-radius: 7px;
    margin-right: 11px;
    margin-left: 11px;
    display: flex;
    margin-bottom: 19px;
    align-items: center;
    .claimrecord_l {
      width: 100px;
      height: 88px;
      border-right: 1px dashed #ff8431;
      position: relative;
      // padding-top: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .claimrecord_l1 {
        font-weight: 700;
        font-size: 27px;
        color: #ff1d1d;
        text-align: center;

        span {
          font-weight: 700;
          font-size: 17px;
          color: #ff1d1d;
        }
      }

      .claimrecord_l2 {
        font-weight: 500;
        font-size: 12px;
        color: #010302;
        text-align: center;
      }
    }

    .claimrecord_l::before {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      position: absolute;
      background-color: #fff;
      top: -8px;
      right: -8px;
      z-index: 99;
    }

    .claimrecord_l::after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background-color: #fff;
      position: absolute;
      bottom: -8px;
      right: -8px;
      z-index: 99;
    }

    .claimrecord_r {
      // height: 176px;
      flex: 1;
      // padding-top: 32px;
      padding-left: 13px;
      position: relative;

      .claimrecord_r1 {
        font-weight: 700;
        font-size: 15px;
        color: #010302;
      }

      .claimrecord_r2 {
        font-weight: 500;
        font-size: 13px;
        color: #010302;
        margin-top: 4px;
        margin-bottom: 5px;
      }

      .claimrecord_r3 {
        font-weight: 500;
        font-size: 13px;
        color: #707070;
      }
    }
  }
}
.claimrecord_case_text3 {
  margin-left: 8px;
  position: absolute;
  right: 12px;
  top: 22px;
  font-size: 12px;
  margin-right: 12x;
  flex-shrink: 0;
  background-color: rgba(253, 171, 64, 1);
  width: 60px;
  height: 25px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  // background: #FFFFFF;
  line-height: 25px;
  text-align: center;
  border-radius: 14px;
  opacity: 1;
  // position: relative;

  view {
    position: absolute;
    width: 60px;
    height: 25px;
    top: 0;
    left: 0;
  }
}
.claimrecord_case_text3_act {
  font-weight: 500;
  background-color: transparent;

  font-size: 14px;
  color: #fdab40;
}
.q_btn {
  //   background: #acc;
  position: absolute;
  left: 0;
  bottom: 42px;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 60px;
    height: 28px;
    font-weight: 300;
    line-height: 28px;
    font-size: 20px;
    color: #ffc844;
    text-align: center;
  }
  img {
    width: 18px;
    height: 18px;
  }
}
.acc {
  cursor: pointer;
  width: 575px;
  height: 97px;
  border-radius: 13px;
  box-shadow: 0px 1px 12px 1px rgba(174, 173, 173, 0.19);
  display: flex;
  flex-direction: column;
  justify-content: center;
  // background: pink;
  padding-left: 40px;
  position: relative;
  margin: 0 auto 31px;
  .acc1 {
    display: flex;
    align-content: center;
    font-weight: 500;
    font-size: 14px;
    color: #1c1c1e;
    margin-bottom: 6px;
    div {
      width: 30px;
      height: 18px;
      background: #ffc844;
      border-radius: 9px 9px 9px 9px;
      font-weight: 300;
      font-size: 10px;
      color: #ffffff;
      text-align: center;
      line-height: 18px;
      margin-right: 11px;
    }
  }
  .acc2 {
    font-weight: 300;
    font-size: 12px;
    color: #98969b;
    padding-left: 41px;
  }
}

.content {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  padding: 0 30px;
  overflow-y: auto;
  padding-bottom: 250px;
  .title {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  .time {
    text-align: center;
    color: gray;
    margin-bottom: 30px;
  }
}

</style>
<style>
.content span{
  text-wrap:wrap!important;
}
.content img{
  width: 100%;
  max-width: 100% !important;
}
</style>