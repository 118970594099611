<template>
  <transition name="el-fade-in">
  <div class="q">
    <div class="main">
      <div class="q_h">VIP价格表<span style="cursor: pointer;" @click="close">X</span></div>
      <div class="q_m">
        <div class="gamevip_table">
			<div class="gamevip_table_h">
				<div class="">
					等级
				</div>
				<div class="">
					价格（元）
				</div>
			</div>
			<div class="gamevip_table_cases">
				<div class="gamevip_table_h1" v-for="(item,index) in List" :key="index">
					<div class="">
						{{item.grade}}
					</div>
					<div class="">
						{{item.condition}}
					</div>
				</div>
				
			</div>
		</div>
      </div>
      
      
    </div>
    
  </div>
</transition>
</template>
<script>
// import { createLogger } from "vuex";
export default {
  props: ["gid"],
  data() {
    return {
      flagBg:false,
      List: "",
      tpage: 1,
      page: 1,
      flagNav: 0
    };
  },
  methods: {
    closeBg(){
      this.flagBg=false
    },
    toBg(){
      this.flagBg=true
    },
    toTap(e) {
      this.page = 1;
      this.$http
        .post("pc/game/game_vip", {
          game_id: this.gid,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 20000) {
            this.List = res.data
          }
        });
      this.flagNav = e;
    },
    close() {
      this.$emit("closeQuan");
    },
  },
  mounted() {
    this.page = 1;
    this.$http
      .post("pc/game/game_vip", {
        game_id: this.gid,
      })
      .then((res) => {
        console.log(res);
        if (res.code == 20000) {
          this.List = res.data
        }
      });
  },
};
</script>
<style scoped lang="scss">
.q {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(112, 112, 112, 0.27);
  top: 0;
  left: 0;
  z-index: 99999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  .main {
    width: 796px;
    height: 750px;
    background: #ffffff;
    box-shadow: 0px 3px 22px 1px rgba(0, 0, 0, 0.16);
    border-radius: 22px 22px 22px 22px;
    padding-top: 28px;
    position: relative;
    overflow: hidden;
  }
  .q_h {
    text-align: center;
    position: relative;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 22px;
    color: #191918;
    span {
      position: absolute;
      font-size: 20px;
      color: RGBA(191, 191, 191, 1);
      right: 42px;
      top: 0;
    }
  }
  .q_m {
    padding: 0 33px;
    // background: pink;
    height: 600px;
    overflow: hidden;

    
  }
}
.gamevip_table{
			width: 591px;
			// height: 620px;
			// margin-left: 20px;
			margin: 30px auto;
      height: 600px;
      overflow-y: auto;
      padding-bottom: 30px;
			.gamevip_table_h{
				height: 50px;
				display: flex;
				background: rgba(255, 179, 0, 0.67);
				div{
					flex: 1;
					font-size: 16px;
					height: 50px;
					font-weight: 500;
					color: #FFFFFF;
					line-height: 50px;
					text-align: center;
				}
			}
			
		}
		.gamevip_table_cases{
			.gamevip_table_h1{
				height: 50px;
				display: flex;
				// background: rgba(255, 179, 0, 0.67);
				div{
					flex: 1;
					font-size: 16px;
					height: 50px;
					font-weight: 500;
					color: #707070;
					line-height: 50px;
					text-align: center;
				}
			}
			.gamevip_table_h1:nth-child(2n){
				background: RGBA(245, 245, 245, 1);
			}
		}
</style>
