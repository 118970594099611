<template>
      <transition name="el-fade-in">
  <div class="q">
    <div class="main">
      <div class="q_h"><span style="cursor: pointer;" @click="close">X</span></div>
      <div class="title">
        微信扫一扫，点击右上角 <img src="../assets/img/dot.png" />分享
      </div>
      <div class="qrcode_box">
        <img :src="qrcode_url" class="qrcode" alt="" />
      </div>
      <div class="share_link">
        {{ share_url }} <span class="copy_link" style="cursor: pointer;" @click="copyLink">复制</span>
      </div>
    </div>
  </div>
</transition>
</template>
<script>
export default {
  props: ["gid"],
  data() {
    return {
      List: "",
      qrcode_url: "",
      share_url: "",
      userinfo: null,
    };
  },
  methods: {
    close() {
      this.$emit("closeQuan");
    },
    copyLink(){
      const input = document.createElement('input');
      input.value = this.share_url; // 设置要复制的文本
      document.body.appendChild(input);
      input.select(); // 选中文本
      document.execCommand('copy'); // 执行复制操作
      document.body.removeChild(input); // 移除临时的输入框
      this.$msg.success('内容已复制到剪贴板');
    },
    // 用户信息
    getUserInfo() {
      var token = localStorage.getItem("token");
      console.log('token',token);
      if (token) {
        var userInfo = localStorage.getItem("userinfo");
        if(userInfo){
          this.userinfo = JSON.parse(userInfo);
        }else{
          this.$http.post("pc/user/userinfo").then((res) => {
            if (res.code == 20000) {
              this.userinfo = res.data;
              console.log(res, "用户信息");
            }
          });
        }
      }
    },
  },
  mounted() {  
    // this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.getUserInfo();
    console.log("用户信息",this.userinfo);
    this.share_url =
      "https://h5.yangtuoyou.com/#/pages/index/gameDetails?id=" +
      this.gid +
      (this.userinfo ? "&invite_id=" + this.userinfo.id : "");
    this.qrcode_url =
      "https://admin.yangtuoyou.com/qrcode/build?text=" +
      encodeURIComponent(this.share_url) +
      "&label=&logo=1&labelalignment=center&foreground=%23000000&background=%23ffffff&size=200&padding=10&logosize=40&labelfontsize=14&errorcorrection=medium";
  },
};
</script>
<style scoped lang="scss">
.q {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(112, 112, 112, 0.27);
  top: 0;
  left: 0;
  z-index: 9999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  .main {
    width: 568px;
    height: 455px;
    background: url("../assets/img/share_bg.png");
    box-shadow: 0px 3px 22px 1px rgba(0, 0, 0, 0.16);
    border-radius: 22px 22px 22px 22px;
    padding-top: 28px;
    position: relative;
    .q_h {
      text-align: center;
      position: relative;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 30px;
      color: #191918;
      span {
        position: absolute;
        font-size: 20px;
        color: RGBA(191, 191, 191, 1);
        right: 42px;
        top: 0;
      }
    }
    .title {
      display: flex;
      justify-content: center;
      text-align: center;
      line-height: 28px;
      img {
        padding: 0 10px;
      }
    }
    .qrcode_box {
      width: 196px;
      height: 196px;
      background: #ffffff;
      box-shadow: 0px 0px 22px 1px rgba(0, 0, 0, 0.06);
      border-radius: 20px;

      margin: 0 auto;
      margin-top: 30px;
    }
    .qrcode {
      width: 182px;
      height: 182px;
      text-align: center;
      margin: 0 auto;
      display: block;
      padding-top: 12px;
    }
    .share_link {
      width: 388px;
      height: 44px;
      line-height: 44px;
      background: #efefef;
      border-radius: 10px;
      text-align: center;
      margin: 0 auto;
      margin-top: 46px;
      position: relative;
      color: #9A9A9A;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 10px;
    }
    .copy_link {
      width: 56px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: #4188ff;
      border-radius: 5px 5px 5px 5px;
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 1000;
      color: white;
      
    }
  }
}
</style>
