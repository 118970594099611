import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: '羊托手游戏 玩游戏不花钱，薅羊毛游戏托，游戏当托就来羊托游，能赚钱的手游APP',
    flagNav:{
      flag:true
    },
    appid: 'alpaca_app',
		version: '1.0.0',
		key: 'cVFYmzSHYThTYzgASMBVPoZbniNquIQn',
		baseURL: 'https://admin.yangtuoyou.com',
    userinfo:localStorage.getItem("userinfo")?JSON.parse(localStorage.getItem("userinfo")):''
  },
  getters: {
    userInfoIsEmpty(state) {
      return state.userinfo === '';
    }
  },
  mutations: {
  },
  actions: {
    changeNav1(){
      this.state.flagNav.flag=false
    },
    changeNav2(){
      this.state.flagNav.flag=true
    },
    getInfo(e){
      console.log(e,'ooppp1')
      this.userinfo=e
    }
  },
  modules: {
  }
})
