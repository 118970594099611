// src/utils/http.js

import axios from 'axios';
import store from '@/store/index.js'
import md5 from 'js-md5'   //引入md5
import { Message } from 'element-ui';
const HTTPSTATUS_1 = [20000, 30000, 40000, 40001, 40002]; 
const HTTPSTATUS_2 = [30001, 40003, 40004, 40005, 40006, 40007, 40009]; 
const HTTPSTATUS_3 = [40008]; 
let time =Math.floor(new Date().getTime() / 1000);   //获取当前时间
let str="app_id="+store.state.appid+"&timestamp="+time+'&version='+store.state.version+'&key='+store.state.key;   //拼接字符串

// 创建 axios 实例
const service = axios.create({
  baseURL: store.state.baseURL, // 应用的基础 API 地址
  timeout: 5000, // 请求超时时间
  headers: {
    'content-type': 'application/json;charset=UTF-8',
    'token': localStorage.getItem('token'),
    'appid':store.state.appid,
    'sign':md5(str),
    'timestamp':time,
    'version':store.state.version
  }
});

// 请求拦截器
service.interceptors.request.use(config => {
  // 在发送请求之前做些什么，例如添加 Token
  // if (store.getters.token) {
  //   config.headers['Authorization'] = `Bearer ${store.getters.token}`;
  // }
  // console.log(config,'config...............')
  config.headers['Authorization']= localStorage.getItem('token')
  config.headers['token']= localStorage.getItem('token')
  return config;
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 对响应数据做点什么，例如错误码处理
    const res = response.data;
    // console.log(response,'响应拦截.....')
    if (res.code !== 200&&res.code !== 20000) {
      // 根据错误码进行错误处理，比如弹窗提示
       Message.error(res.msg || 'Error');
      return res;
    }
    
    return res;
  },
  error => {
    // 对响应错误做点什么
    console.error('err' + error); // for debug
    // ElMessage.error(error.message);
    return Promise.reject(error);
  }
);

export default service;