<template>
  <div style="height: 100vh; overflow: auto" @scroll="sa"  ref="cmain">
    <div class="index" @click="closeA">
      <div
        class=""
        style="
          z-index: 99999;
          height: 100%;
          background-color: #f5f5f5;
          box-shadow: 0px 3px 22px 1px #f1f1f1;
          width: 710px;
          position: fixed;
          left: 50%;
          margin-left: -380px;
          padding: 30px 0;
        "
        v-if="flagDetail"
        @click.stop
      >
        <div class="details_buy">
          <img
            style="cursor: pointer"
            src="../assets/img/buy/back.png"
            alt=""
            srcset=""
            class="buy_del"
            @click="toDel"
          />
          <div class="details_buy1">
            <img :src="accde.image" alt="" />
            <div>
              <p>{{ accde.game_name }}</p>
              <div><span>￥</span>50.00</div>
            </div>
            <span>信息已通过审核</span>
          </div>
          <div class="details_buy2">
            <p>游戏区服：{{ accde.server_name }}</p>
            <p>
              累计消费：{{ accde.accumulated_recharge
              }}<span>（实付金额）</span>
            </p>
            <p>创建时间：{{ accde.create_time }}天</p>
            <div style="cursor: pointer" @click="toplay">立即购买</div>
          </div>
          <div class="details_buy3">
            <p class="buy_p1">{{ accde.title }}</p>
            <div class="buy_d1">
              {{ accde.describe }}
            </div>
            <div class="buy_d2">
              <img
                :src="item"
                alt=""
                v-for="(item, i) in accde.images"
                :key="i"
              />
            </div>
            <p class="buy_p2">
              排行榜数据以发布时间为准，后续可能变动，具体以游戏内为准
            </p>
            <p class="buy_p3">发布日期：{{ accde.release_time }}</p>
          </div>
        </div>
      </div>
      <div class="input_cK" v-show="flagd" @click.stop>
        <p>搜索发现</p>
        <div class="input_c1" v-if="userS.length != 0">
          <span
            style="cursor: pointer"
            v-for="(item, i) in userS"
            :key="i"
            @click="search2(item.keyword)"
            >{{ item.keyword }}</span
          >
        </div>
        <img src="../assets/img/index/hot_s.png" alt="" />
        <div class="input_c2">
          <div
            style="cursor: pointer"
            v-for="(item, i) in hotGame"
            :key="i"
            @click="toDetails(item.id)"
          >
            <span></span>{{ item.game_name
            }}<img src="../assets/img/index/hs.png" alt="" srcset="" />
          </div>
        </div>
      </div>
      <div
        class="input_c"
        style="
          z-index: 9999999;
          background-color: #fff;
          box-shadow: 0px 3px 22px 1px #f1f1f1;
          width: 710px;
          position: absolute;
          left: 50%;
          margin-left: -380px;
          padding: 30px 0;
          height: 100%;overflow-y: auto;padding-bottom: 100px;
        "
        v-show="flagG1"
        @click.stop
      >
        <div
          style="cursor: pointer"
          class="main_case1"
          v-for="(item, i) in slist"
          :key="i"
          @click="toDetails(item.id)"
        >
          <img :src="item.image" alt="" />
          <div>
            <div class="main_c1">
              {{ item.game_name }}
            </div>

            <div class="main_c3">
              <span v-for="(item2, k) in item.game_tag" :key="k">{{
                item2
              }}</span>
            </div>
            <div class="main_cq">
              <span v-for="(a, s) in item.category_name" :key="s">{{ a }}</span>
            </div>
          </div>
        </div>
        <div v-if="slist.length == 0" style="text-align: center">暂无内容</div>
      </div>
      <div
        class="input_content"
        style="z-index: 9999999;height: 100%;overflow-y: auto;padding-bottom: 100px;"
        v-show="flagG2"
        @click.stop
      >
        <div
          style="cursor: pointer"
          class="main_case1"
          v-for="(item, i) in slist"
          :key="i"
          @click="toDetails(item.id)"
        >
          <img :src="item.image" alt="" />
          <div>
            <div class="main_c1">
              {{ item.game_name }}
            </div>

            <div class="main_c3">
              <span v-for="(item2, k) in item.game_tag" :key="k">{{
                item2
              }}</span>
            </div>
            <div class="main_cq">
              <span v-for="(a, s) in item.category_name" :key="s">{{ a }}</span>
            </div>
            <!-- <div class="main_c2" v-if="item.star_time.indexOf('今') != -1">
                {{ item.star_time }}
              </div>
              <div class="main_c2_" v-else>{{ item.star_time }}</div>
              <div class="main_c2_">{{ item.star_time }}</div> -->
          </div>
        </div>
        <div v-if="slist.length == 0" style="text-align: center">暂无内容</div>
      </div>
  <transition name="el-fade-in">
      <div class="input_cK1" v-if="isShow" @click.stop>
        <div class="p_h">
          <img
            v-if="userinfo"
            :src="userinfo.avatar_image"
            alt=""
            class="h_pic"
            style="border-radius: 100%"
          />
          <img
            v-else
            src="../assets/img/index/login.png"
            alt=""
            class="h_pic"
            style="border-radius: 100%"
          />

          <div>
            <p class="p1">{{ userinfo.nickname }}</p>
            <p class="p2">账号：{{ userinfo.id }}</p>
          </div>
          <img
            style="cursor: pointer"
            src="../assets/img/mine/sign.png"
            alt=""
            srcset=""
            class="sign"
            @click="toSign"
          />
        </div>
        <div
          class="bg_m"
          v-if="(userinfo && userinfo.member_type == 0) || !userinfo"
        >
          <img src="../assets/img/mine/bg1.png" alt="" srcset="" class="b1" />
          <img
            style="cursor: pointer"
            src="../assets/img/mine/btn1.png"
            alt=""
            @click="toplay"
            srcset=""
            class="b2"
          />
        </div>
        <div class="mine_bg1" v-else>
          <div class="mine_bg1_tap">
            <img src="../assets/img/mine/tap_y1.png" mode="" />
            <div class="mine_bg1_tap_1">
              <div class="" v-if="userinfo && userinfo.member_type == 1">
                月卡会员
              </div>
              <div class="" v-if="userinfo && userinfo.member_type == 2">
                季卡会员
              </div>
              <div class="" v-if="userinfo && userinfo.member_type == 3">
                年卡会员
              </div>
              <div class="mine_bg1_tap1">
                剩余{{ userinfo ? userinfo.end_time : "" }}天
              </div>
            </div>
          </div>
          <div class="mine_bg1_h">
            <span @click="toplay" style="cursor: pointer">立即续费</span>
            <img src="../assets/img/mine/r1.png" mode="" />
            <div
              style="cursor: pointer"
              class="mine_bg1_h_1"
              @click="toplay"
              v-if="!userinfo.user_ticket_today"
            >
              领膨胀券
              <div class="">NEW</div>
            </div>
            <div class="mine_bg1_h_1" v-if="userinfo.user_ticket_today">
              领膨胀券
              <div class="">NEW</div>
            </div>
          </div>
          <div class="mine_bg1_main">
            <div class="">
              <div class="">
                <img src="../assets/img/mine/vip_case1.png" mode="" />
              </div>
              送膨胀券
            </div>
            <div class="">
              <div class="">
                <img src="../assets/img/mine/vip_case2.png" mode="" />
              </div>
              专属身份
            </div>
            <div class="">
              <div class="">
                <img src="../assets/img/mine/vip_case3.png" mode="" />
              </div>
              专属礼包
            </div>
            <div
              class="mine_bg1_main_case"
              :class="
                userinfo &&
                (userinfo.member_type == 2 || userinfo.member_type == 3)
                  ? 'lact1'
                  : ''
              "
            >
              <div
                :class="
                  userinfo &&
                  (userinfo.member_type == 2 || userinfo.member_type == 3)
                    ? 'lact'
                    : ''
                "
              >
                <span v-if="userinfo && userinfo.member_type == 1">季卡</span>
                <img
                  v-if="
                    userinfo &&
                    (userinfo.member_type == 2 || userinfo.member_type == 3)
                  "
                  src="../assets/img/mine/vip_case4.png"
                />
                <img v-else src="../assets/img/mine/vip_case41.png" mode="" />
              </div>
              优先服务
            </div>
            <div
              class="mine_bg1_main_case"
              :class="userinfo && userinfo.member_type == 3 ? 'lact1' : ''"
            >
              <div :class="userinfo && userinfo.member_type == 3 ? 'lact' : ''">
                <span
                  v-if="
                    userinfo &&
                    (userinfo.member_type == 1 || userinfo.member_type == 2)
                  "
                  >年卡</span
                >
                <img
                  v-if="userinfo && userinfo.member_type == 3"
                  src="../assets/img/mine/vip_case5.png"
                  mode=""
                />
                <img v-else src="../assets/img/mine/vip_case51.png" mode="" />
              </div>
              额外返利
            </div>
          </div>
        </div>
        <div class="setting">
          <div @click="toName" style="cursor: pointer">
            <img src="../assets/img/mine/name.png" alt="" />
            实名认证
          </div>
          <div @click="toPhone" style="cursor: pointer">
            <img src="../assets/img/mine/phone.png" alt="" />
            修改手机
          </div>
          <div @click="toPass" style="cursor: pointer">
            <img src="../assets/img/mine/set.png" alt="" />
            设置密码
          </div>
        </div>
        <div class="back_btn" @click="logout" style="cursor: pointer">
          退出登录
        </div>
      </div>
      </transition>
      <!-- .................. -->
      <div class="nav"
      :style="
          scrollTop >= 80
            ? `position: fixed;top: 0;left: 50%;margin-left: -737px;z-index:999;background:#fff;width:1474px;`
            : ''
        "
      >
        <img
          src="../assets/img/index/logo.png"
          style="cursor: pointer"
          @click="toHome"
          alt=""
          srcset=""
          class="logo"
        />
        <div class="de_nav">
          <img
            style="cursor: pointer"
            v-show="flagI == 0"
            src="../assets/img/index/back.png"
            alt=""
            srcset=""
            @click="onBack"
          />
          <div class="input_s" @click.stop>
            <input
              type="text"
              placeholder="蛋仔派对"
              @focus="inputFocus1"
              v-model="textS"
              @input="search_2"
            />
            <img
              style="cursor: pointer"
              src="../assets/img/index/search.png"
              alt=""
              srcset=""
              @click="search_2"
            />
          </div>
          <div class="notice" @click="toplay" style="cursor: pointer">
            <img src="../assets/img/buy/notice.png" alt="" srcset="" />
            <div>{{ userinfo ? userinfo.notice_num : "" }}</div>
          </div>
          <div class="de_sign" v-show="flagsign">
            <img
              src="../assets/img/buy/back.png"
              alt=""
              style="cursor: pointer; position: absolute"
              class="de_back"
              @click="closeSign"
            />
            <div class="welfare_h">
              <div class="welfare_h1">
                <div class="welfare_h1_1">
                  已连续签到<span>{{ sign_data.continuous_day }}天</span>
                </div>
                <!-- <div class="welfare_h1_2 welfare_h1_21" v-if="is_sign">
					已签到
				</div> -->
                <div
                  class="welfare_h1_2"
                  @click="toplay"
                  style="cursor: pointer"
                >
                  立即签到
                </div>
              </div>
              <div class="welfare_h2">
                <div
                  class="welfare_h2_case welfare_h2_case1"
                  v-for="(item, index) in sign_list"
                  :key="index"
                >
                  <img
                    v-if="item.check == true"
                    src="../assets/img/mine/w_span1.png"
                    mode=""
                  />
                  <img
                    v-if="
                      sign_list.length - 1 > index &&
                      item.check == false &&
                      item.today == true &&
                      item.id != 3
                    "
                    src="../assets/img/mine/w_span2.png"
                    mode=""
                  />
                  <img
                    v-if="
                      sign_list.length - 1 > index &&
                      item.check == false &&
                      item.today != true &&
                      item.id != 3
                    "
                    src="../assets/img/mine/w_span3.png"
                    mode=""
                  />
                  <div class="w_img" v-if="item.id == 3 && item.check == false">
                    <img src="../assets/img/mine/w_span5.png" mode="" />
                  </div>
                  <div class="w_img" v-if="item.id == 7 && item.check == false">
                    <img src="../assets/img/mine/w_span5.png" mode="" />
                  </div>
                  <div class="w_span" v-if="item.today == true">今天</div>
                  <div
                    class="w_span"
                    v-if="item.check == true && item.today != true"
                  >
                    已签
                  </div>
                  <div
                    class="w_span"
                    v-if="item.check == false && item.today != true"
                  >
                    {{ item.day }}
                  </div>
                </div>
              </div>
            </div>
            <div class="welfare_main">
              <div class="welfare_main_h">本周活跃</div>
              <div class="welfare_main_process">
                <div class="progress_bar">
                  <div class="progress_bar1">
                    <div
                      class="progress_bar_c"
                      :style="{ width: (activation / 50) * 100 + '%' }"
                    ></div>
                  </div>
                  <div class="progress_bar2">
                    <div
                      class="progress_bar_c"
                      :style="{ width: ((activation - 50) / 50) * 100 + '%' }"
                    ></div>
                  </div>
                  <div class="progress_bar3">
                    <div
                      class="progress_bar_c"
                      :style="{ width: ((activation - 100) / 50) * 100 + '%' }"
                    ></div>
                  </div>
                  <div class="progress_bar4">
                    <div
                      class="progress_bar_c"
                      :style="{ width: ((activation - 150) / 50) * 100 + '%' }"
                    ></div>
                  </div>
                  <div class="w_span w_span1">
                    <img
                      v-if="activation_list[0].status == 2"
                      src="../assets/img/mine/w_span1.png"
                      mode=""
                    />
                    <img
                      v-if="activation_list[0].status == 1"
                      src="../assets/img/mine/w_span6.png"
                      mode=""
                    />
                    <img
                      v-if="activation_list[0].status == 0"
                      src="../assets/img/mine/w_span3.png"
                      mode=""
                    />
                  </div>
                  <div class="w_span w_span2">
                    <img
                      v-if="activation_list[1].status == 2"
                      src="../assets/img/mine/w_span1.png"
                      mode=""
                    />
                    <img
                      v-if="activation_list[1].status == 1"
                      src="../assets/img/mine/w_span6.png"
                      mode=""
                    />
                    <img
                      v-if="activation_list[1].status == 0"
                      src="../assets/img/mine/w_span3.png"
                      mode=""
                    />
                  </div>
                  <div class="w_span w_span3">
                    <img
                      v-if="activation_list[2].status == 2"
                      src="../assets/img/mine/w_span1.png"
                      mode=""
                    />
                    <img
                      v-if="activation_list[2].status == 1"
                      src="../assets/img/mine/w_span6.png"
                      mode=""
                    />
                    <img
                      v-if="activation_list[2].status == 0"
                      src="../assets/img/mine/w_span3.png"
                      mode=""
                    />
                  </div>
                  <div class="w_span w_span4">
                    <img
                      v-if="activation_list[3].status == 2"
                      src="../assets/img/mine/w_span1.png"
                      mode=""
                    />
                    <img
                      v-if="activation_list[3].status == 1"
                      src="../assets/img/mine/w_span6.png"
                      mode=""
                    />
                    <img
                      v-if="activation_list[3].status == 0"
                      src="../assets/img/mine/w_span3.png"
                      mode=""
                    />
                  </div>
                </div>
                <div class="progress_bar_span">
                  <div class="w_bar1">
                    <!-- <div class="w_bar_1">已领</div>
                    <div class="w_bar_2">领取</div> -->
                    <div class="w_bar_3">50活跃值</div>
                  </div>
                  <div class="w_bar2">
                    <!-- <div class="w_bar_1">已领</div>
                    <div class="w_bar_2">领取</div> -->
                    <div class="w_bar_3">100活跃值</div>
                  </div>
                  <div class="w_bar3">
                    <!-- <div class="w_bar_1">已领</div>
                    <div class="w_bar_2">领取</div> -->
                    <div class="w_bar_3">150活跃值</div>
                  </div>
                  <div class="w_bar4">
                    <!-- <div class="w_bar_1">已领</div>
                    <div class="w_bar_2">领取</div> -->
                    <div class="w_bar_3">200活跃值</div>
                  </div>
                </div>
              </div>
              <div class="process_tap">
                <div class="process_tap_main">
                  <div
                    class="process_tap1"
                    style="cursor: pointer"
                    @click="toTap1(1)"
                  >
                    日常任务
                    <div :class="flagTap1 == 1 ? 'process_tap_act' : ''"></div>
                  </div>
                  <div
                    class="process_tap1"
                    style="cursor: pointer"
                    @click="toTap1(2)"
                  >
                    成就任务
                    <div :class="flagTap1 == 2 ? 'process_tap_act' : ''"></div>
                  </div>
                </div>
                <!-- <div  scroll-y="true" class="appoint"> -->
                <div class="process_tap_cases" v-show="flagTap1 == 1">
                  <div
                    class="process_tap_case"
                    v-for="(item, index) in daily_list"
                    :key="index"
                  >
                    <div class="process_tap_case1">
                      <div class="process_tap_case1_1">
                        {{ item.task_name
                        }}<span v-if="item.id == 10">（{{ item.num }}/10）</span
                        ><span v-if="item.id == 11"
                          >（{{ item.login_duration }}/60）</span
                        ><span v-if="item.id == 12"
                          >（{{ item.price }}/100）</span
                        >
                      </div>
                      <div class="process_tap_case1_2">
                        <span class="t_sp">活跃+{{ item.activation }}</span>
                        <div class="process_k">
                          <div class="process_k1">奖励：</div>
                          <div class="" v-if="item.type == 2">
                            <img
                              src="../assets/img/mine/grass.png"
                              mode=""
                              class="h1"
                            />
                            x{{ item.grass_num }}
                          </div>
                          <div class="" v-if="item.type == 0">
                            <img
                              src="../assets/img/mine/g2.png"
                              mode=""
                              class="h2"
                            />x1
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="process_tap_case3" v-if="item.status == 2">
                      <span style="cursor: pointer">已完成</span>
                    </div>
                    <div
                      class="process_tap_case3 process_tap_case3_loging"
                      v-if="item.status == 0"
                    >
                      <span @click="toplay" style="cursor: pointer"
                        >去完成</span
                      >
                    </div>
                    <div
                      class="process_tap_case3 process_tap_case3_act"
                      v-if="item.status == 1"
                    >
                      <span style="cursor: pointer">可领取</span>
                    </div>
                  </div>
                  <div class="box"></div>
                </div>
                <!-- </div> -->
                <!-- <div  scroll-y="true" class="appoint"> -->
                <div class="process_tap_cases" v-show="flagTap1 == 2">
                  <div
                    class="process_tap_case"
                    v-for="(item, index) in achieve_list"
                    :key="index"
                  >
                    <div class="process_tap_case1">
                      <div class="process_tap_case1_1">
                        {{ item.task_name
                        }}<span class="span"
                          >{{ item.task_num }}/{{ item.total_num }}</span
                        >
                      </div>
                      <div class="process_tap_case1_2">
                        <div class="process_k">
                          <div class="process_k1">奖励：</div>
                          <div class="" v-if="item.grass_num">
                            <img
                              src="../assets/img/mine/grass.png"
                              mode=""
                              class="h1"
                            />
                            x{{ item.grass_num }}
                          </div>
                          <div
                            class=""
                            v-if="item.reward.indexOf('膨胀券') != -1"
                          >
                            <img
                              src="../assets/img/mine/g2.png"
                              mode=""
                              class="h2"
                            />x{{ item.ticket_num }}
                          </div>
                          <div v-if="item.item">
                            <div
                              class=""
                              v-for="(item1, i) in item.item"
                              :key="i"
                            >
                              <div class="" v-if="item1.item_name == '勋章'">
                                <img :src="item1.image" mode="" class="h3" />x1
                              </div>
                              <div class="" v-if="item1.item_name == '背景'">
                                <img :src="item1.image" mode="" class="h3" />x1
                              </div>
                              <div class="" v-if="item1.item_name == '头像圈'">
                                <img :src="item1.image" mode="" class="h3" />x1
                              </div>
                              <div class="" v-if="item1.item_name == '时装'">
                                <img :src="item1.image" mode="" class="h3" />x1
                              </div>
                              <div class="" v-if="item1.item_name == '道具卡'">
                                <img :src="item1.image" mode="" class="h3" />x1
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="process_tap_case3" v-if="item.status == 2">
                      <span>已完成</span>
                    </div>
                    <div
                      class="process_tap_case3 process_tap_case3_loging"
                      v-if="item.status == 0"
                    >
                      <span>去完成</span>
                    </div>
                    <div
                      class="process_tap_case3 process_tap_case3_act"
                      v-if="item.status == 1"
                    >
                      <span>可领取</span>
                    </div>
                  </div>
                  <div class="box"></div>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
        <img
          v-if="userinfo"
          :src="userinfo.avatar_image"
          @click.stop="showUserInfo"
          alt=""
          class="login"
          style="border-radius: 100%; cursor: pointer"
        />
        <img
          v-else
          src="../assets/img/index/login.png"
          alt=""
          class="login"
          @click="open"
        />
      </div>
      <div class="main" v-show="flagI == 0" style="">
        <div class="banner">
          <el-carousel :interval="3000" arrow="always">
            <el-carousel-item
              v-for="(item, i) in details.cover_images"
              :key="i"
            >
              <el-image
                style="width: 100%; height: 100%"
                :src="item"
                alt=""
                srcset=""
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="d_h1">
          <img :src="details.image" alt="" />
          <div>
            <!-- <div class="d_h1_1">双生幻想</div> -->
            <div class="d_h1_1">{{ details.game_name }}</div>
            <div class="d_h1_2">{{ details.remark }}</div>
            <div class="d_h1_3">
              <span
                class="d_h1_3_1"
                v-for="(item, i) in details.game_tag"
                :key="i"
                >{{ item }}</span
              >
              <!-- <span class="d_h1_3_1">像素</span>
              <span class="d_h1_3_2">0.1折</span> -->
            </div>
          </div>
          <div class="i_share">
            <img
              style="cursor: pointer"
              src="../assets/img/sc.png"
              alt=""
              srcset=""
              v-if="flagcollect"
              @click="toCollect(details.id)"
            />
            <img
              style="cursor: pointer"
              src="../assets/img/sc2.png"
              alt=""
              srcset=""
              v-else
              @click="toCollect(details.id)"
            />
            <img
              style="cursor: pointer"
              @click="openShare(details.id)"
              src="../assets/img/fx.png"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="i_btn">
          <div
            class="i_btn1"
            @click="toplay"
            v-if="details.login_url"
            style="cursor: pointer"
          >
            云玩
          </div>
          <div
            class="i_btn2"
            v-if="details.file_size != 0"
            @click="toplay"
            style="cursor: pointer"
          >
            下载（{{ details.file_size }}）
          </div>
          <div
            class="i_btn2"
            style="cursor: pointer; background-color: #cfcfcf"
            v-else
            @click="toplay"
          >
            下载
          </div>
        </div>
        <div class="d_h2">
          <div
            class="d_h2_1"
            style="cursor: pointer"
            @click="toAcc(details.id)"
          >
            <div class="d_h2_1_1">
              <img src="../assets/img/index/span_1.png" alt="" class="i1" />
              活动与公告
            </div>
            <div class="d_h2_1_2">
              <b></b>
              <span>{{ details.article_time }}</span>
              <div>
                <img src="../assets/img/index/icon_new.png" alt="" />
                {{ details.article_name }}
              </div>
            </div>
          </div>
          <div
            class="d_h2_2 d_h2_2_"
            style="cursor: pointer"
            @click="toQuan(details.id)"
          >
            <img src="../assets/img/index/span_2.png" alt="" class="i1" />
            优惠券
          </div>
          <div
            class="d_h2_2"
            style="cursor: pointer"
            @click="toGift(details.id)"
          >
            <img src="../assets/img/index/span_3.png" alt="" class="i2" />
            礼包
          </div>
          <div
            class="d_h2_2"
            style="cursor: pointer"
            @click="toVip(details.id)"
          >
            <img src="../assets/img/index/span_4.png" alt="" class="i2" />
            VIP
          </div>
        </div>
        <div
          class="d_h3"
          style="cursor: pointer"
          @click="toServer(details.id)"
          v-if="details.server_name"
        >
          <div>{{ details.server_time }}</div>
          <span>{{ details.server_name }}</span>
          <b>></b>
        </div>
        <div
          class="d_h3"
          style="cursor: pointer; justify-content: space-between"
          @click="toServer(details.id)"
          v-else
        >
          <div>动态开服</div>
          <span style="color: gray; padding-right: 30px">看历史开服</span>
        </div>
        <div class="d_h4">
          <img src="../assets/img/index/span_g.png" alt="" />
          <div>
            {{ details.content }}
          </div>
          <div
            style="
              height: 352px;
              background-color: transparent;
              overflow: hidden;
              margin-top: 33px;
            "
          >
            <!-- <img
              :src="item"
              alt=""
              srcset=""
              v-for="(item, i) in details.screenshot_images"
              :key="i"
              style="
                width: 236px;
                height: 352px;
                flex-shrink: 0;
                margin-right: 39px;
              "
            /> -->
            <el-carousel
              :interval="2000"
              type="card"
              height="200px"
              arrow="never"
              indicator-position="none"
              style="padding-left: 43px"
            >
              <el-carousel-item
                v-for="(item, i) in details.screenshot_images"
                :key="i"
              >
                <img
                  :src="item"
                  alt=""
                  srcset=""
                  style="width: 236px; height: 352px"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="d_h4">
          <img src="../assets/img/index/span_fl.png" alt="" />
          <div v-html="details.welfare"></div>
        </div>
        <div class="d_h5">
          <img src="../assets/img/index/span_buy.png" alt="" />
          <div
            style="cursor: pointer"
            class="d_h5_1"
            v-for="(item, i) in tranList"
            :key="i"
            @click="toCDetails(item.id)"
          >
            <p>{{ item.title }}</p>
            <div class="d_h5_1_c1">
              <span>区服：{{ item.server_name }}</span>
              <span>游戏充值：{{ item.accumulated_recharge }}</span>
              <div>
                <span>￥</span>
                {{ item.sell_price }}
              </div>
            </div>
            <div class="d_h5_1_c2">
              <div v-for="(h, k) in item.images" :key="k">
                <img :src="h" alt="" style="width: 100%; height: 100%" />
              </div>
            </div>
          </div>

          <div class="span_p" style="cursor: pointer" @click="toplay">
            打开羊托手游查看更多
            <span></span>
          </div>
        </div>
      </div>
      <div class="main" v-show="flagI == 1" ref="games">
        <div class="pic">
          <div class="pic1">
            <img
              v-if="userinfo"
              :src="userinfo.avatar_image"
              alt=""
              srcset=""
              class="img1"
              style="border-radius: 100%"
            />
            <img
              v-else
              src="../assets/img/index/login.png"
              alt=""
              class="login"
              @click="open"
            />
            <div>
              <p class="p1">{{ userinfo ? userinfo.nickname : "" }}</p>
              <p class="p2">账号：{{ userinfo ? userinfo.id : "" }}</p>
            </div>
            <img
              style="cursor: pointer"
              v-if="userinfo && userinfo.member_type == 3"
              src="../assets/img/buy/tap1.png"
              alt=""
              class="tap1"
              @mouseenter="m1(1)"
              @mouseleave="m2(1)"
            />
            <img
              style="cursor: pointer"
              v-if="game_rebate.length > 0 && userinfo"
              src="../assets/img/buy/tap2.png"
              alt=""
              class="tap2"
              @mouseenter="m1(2)"
              @mouseleave="m2(2)"
            />
            <img
              style="cursor: pointer"
              v-if="reservation_rebate.length > 0 && userinfo"
              src="../assets/img/buy/tap3.png"
              alt=""
              class="tap3"
              @mouseenter="m1(3)"
              @mouseleave="m2(3)"
            />
            <div class="mine_pic1_tap11" v-show="flagv1">
              <div class="mine_pic1_tap11_1">年卡会员特权：</div>
              <div class="mine_pic1_tap11_2">每天首次消费返利 +3%</div>
            </div>
            <div class="mine_pic1_tap12" v-show="flagv2">
              <div class="" v-for="(item, index) in game_rebate" :key="index">
                <div class="mine_pic1_tap11_1">
                  {{ item.game_name }} 额外返利：
                </div>
                <div class="mine_pic1_tap11_2">
                  首充返利 +{{ item.proportion }}%
                </div>
              </div>
            </div>
            <div class="mine_pic1_tap13" v-show="flagv3">
              <div
                class=""
                v-for="(item, index) in reservation_rebate"
                :key="index"
              >
                <div class="mine_pic1_tap11_1">
                  {{ item.game_name }} 预约返利：
                </div>
                <div class="mine_pic1_tap11_2">
                  额外返利 +{{ item.reservation_proportion }}%
                </div>
                <div class="mine_pic1_tap11_2">
                  剩余时间{{ item.reservation_end_time }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="userinfo && userinfo.alpaca_sex == 1"
            style="cursor: pointer"
            @click="toplay"
          >
            <img
              src="../assets/img/sheep/m1.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 1"
            />
            <img
              src="../assets/img/sheep/m2.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 2"
            />
            <img
              src="../assets/img/sheep/m3.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 3"
            />
            <img
              src="../assets/img/sheep/m4.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 4"
            />
            <img
              src="../assets/img/sheep/m5.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 5"
            />
            <img
              src="../assets/img/sheep/m6.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 6"
            />
            <img
              src="../assets/img/sheep/m7.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 7"
            />
          </div>
          <div v-else style="cursor: pointer" @click="toplay">
            <img
              src="../assets/img/sheep/w1.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 1"
            />
            <img
              src="../assets/img/sheep/w2.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 2"
            />
            <img
              src="../assets/img/sheep/w3.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 3"
            />
            <img
              src="../assets/img/sheep/w4.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 4"
            />
            <img
              src="../assets/img/sheep/w5.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 5"
            />
            <img
              src="../assets/img/sheep/w6.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 6"
            />
            <img
              src="../assets/img/sheep/w7.png"
              alt=""
              srcset=""
              class="sheep"
              v-if="userinfo && userinfo.user_grade_id == 7"
            />
          </div>
        </div>
        <div class="gcase">
          <img
            style="cursor: pointer"
            src="../assets/img/buy/left_1.png"
            alt=""
            srcset=""
            @click="toA1"
            v-if="userinfo && userinfo_.user_grade_id != userinfo.user_grade_id"
          />
          <img
            src="../assets/img/buy/left.png"
            alt=""
            srcset=""
            v-if="userinfo && userinfo_.user_grade_id == userinfo.user_grade_id"
          />
          <div class="gcase1">
            <div
              class="gcase_1"
              v-if="
                userinfo && userinfo_.user_grade_id == userinfo.user_grade_id
              "
            >
              <div class="gcase_11">
                V{{ userinfo ? userinfo_.user_grade_id : "" }}
              </div>
              <div class="gcase_12">
                返{{ proportion[userinfo && userinfo_.user_grade_id - 1] }}%
              </div>
            </div>
            <div
              class="gcase_1"
              v-if="
                userinfo && userinfo_.user_grade_id != userinfo.user_grade_id
              "
            >
              <div class="gcase_11" style="color: #e2e2e2">
                V{{ userinfo ? userinfo_.user_grade_id : "" }}
              </div>
              <div class="gcase_12" style="background: #e2e2e2">
                返{{ proportion[userinfo && userinfo_.user_grade_id - 1] }}%
              </div>
            </div>
            <div class="gcase_2">
              <div class="gcase_21">
                <div
                  :style="{
                    width:
                      (userinfo &&
                        userinfo.experience / userinfo &&
                        userinfo.last_grade.experience) *
                        100 +
                      '%',
                  }"
                  v-if="
                    userinfo &&
                    userinfo_.user_grade_id == userinfo.user_grade_id
                  "
                ></div>
              </div>
              <div
                class="gcase_22"
                v-if="
                  userinfo && userinfo_.user_grade_id == userinfo.user_grade_id
                "
              >
                {{ userinfo && userinfo.experience }} /
                {{ userinfo && userinfo.last_grade.experience }}
              </div>
              <div
                class="gcase_22"
                v-if="
                  userinfo && userinfo_.user_grade_id != userinfo.user_grade_id
                "
              >
                未知
              </div>
            </div>
            <div
              class="gcase_1"
              v-if="
                userinfo && userinfo_.user_grade_id == userinfo.user_grade_id
              "
            >
              <div class="gcase_11 vip_tap vip_tap1">
                V{{ userinfo_ && userinfo_.user_grade_id + 1 }}
                <div
                  class="vip_tap vip_tap1 vip_tap_1"
                  :style="{
                    width:
                      (userinfo.experience / userinfo.last_grade.experience) *
                        100 +
                      '%',
                  }"
                >
                  V{{ userinfo_ && userinfo_.user_grade_id + 1 }}
                </div>
                <!-- <div></div>
                <div>V{{ userinfo_.user_grade_id + 1 }}</div> -->
              </div>
              <div
                class="gcase_12"
                style="overflow: hidden; background: #e2e2e2"
              >
                <div
                  style="
                    height: 30px;
                    background: #fc8702;
                    position: absolute;
                    top: 0;
                    left: 0;
                  "
                  :style="{
                    width:
                      (userinfo.experience / userinfo.last_grade.experience) *
                        100 +
                      '%',
                  }"
                ></div>
                <div
                  style="
                    width: 87px;
                    height: 30px;
                    background: transparent;
                    position: absolute;
                    top: 0;
                    left: 0;
                  "
                >
                  返{{ proportion[userinfo_.user_grade_id] }}%
                </div>
              </div>
            </div>
            <div
              class="gcase_1"
              v-if="userinfo_.user_grade_id != userinfo.user_grade_id"
            >
              <div class="gcase_11" style="color: #e2e2e2">
                V{{ userinfo_.user_grade_id + 1 }}
              </div>
              <div class="gcase_12" style="background: #e2e2e2">
                返{{ proportion[userinfo_.user_grade_id] }}%
              </div>
            </div>
          </div>
          <img
            style="cursor: pointer"
            src="../assets/img/buy/right.png"
            alt=""
            srcset=""
            @click="toA2"
            v-if="userinfo_.user_grade_id != 6"
          />
          <img
            src="../assets/img/buy/right_1.png"
            alt=""
            srcset=""
            v-if="userinfo_.user_grade_id == 6"
          />
        </div>
        <div class="mine_pic3">
          <div class="mine_pic3_1" style="cursor: pointer" @click="toplay">
            <img
              src="../assets/img/mine/span_money1.png"
              mode=""
              class="mine_pic3_img"
            />
            <div class="mine_pic3_text">
              <div class="mine_pic3_text1">羊托币</div>
              <div class="mine_pic3_text2">{{ userinfo.balance }}</div>
            </div>
          </div>
          <div
            class="mine_pic3_1 m_k"
            style="cursor: pointer"
            @click="showTicket"
          >
            <img
              src="../assets/img/mine/span_tirck.png"
              mode=""
              class="mine_pic3_img"
            />
            <div class="mine_pic3_text">
              <div class="mine_pic3_text1">优惠券</div>
              <div class="mine_pic3_text2">{{ userinfo.ticket_num }}</div>
            </div>
          </div>
        </div>
        <div class="myGames_tap">
          <div
            class="tap_case"
            @click="changeTap(1)"
            :class="flagTap == 1 ? 'tap_case1' : ''"
            style="cursor: pointer"
          >
            正在玩
            <div class="" :class="flagTap == 1 ? 'tap_case2' : ''"></div>
          </div>
          <div
            class="tap_case"
            @click="changeTap(2)"
            :class="flagTap == 2 ? 'tap_case1' : ''"
            style="cursor: pointer"
          >
            已预约
            <div class="" :class="flagTap == 2 ? 'tap_case2' : ''"></div>
          </div>
          <div
            class="tap_case"
            @click="changeTap(3)"
            :class="flagTap == 3 ? 'tap_case1' : ''"
            style="cursor: pointer"
          >
            已收藏
            <div class="" :class="flagTap == 3 ? 'tap_case2' : ''"></div>
          </div>
        </div>
        <div style="">
          <div
            style="cursor: pointer"
            class="new_recommended1"
            v-for="item in playList"
            :key="item.id"
            @click="toDetails(item.game_id)"
          >
            <div class="title">
              <img :src="item.image" alt="" srcset="" class="img" />
            </div>
            <div class="texts">
              <div class="text1">
                {{ item.name }}
              </div>
              <div class="text2">
                <div
                  class="span"
                  v-for="(val, index) in item.category_name"
                  :key="index"
                >
                  {{ val }}
                </div>
              </div>
              <div class="text3">
                <div
                  class="text3_span"
                  v-for="(val, index) in item.game_tag"
                  :key="index"
                >
                  <span>{{ val }}</span>
                </div>
              </div>
            </div>
            <div
              class="btn"
              @click.stop="toplay"
              v-if="item.login_url"
              style="cursor: pointer"
            >
              云玩
            </div>
            <div
              class="btn"
              @click.stop="toDetails(item.game_id)"
              v-else
              style="cursor: pointer"
            >
              查看
            </div>
          </div>
        </div>
      </div>

      <div class="hot"
       ref="hot"
        :style="scrollTop > 400 ? `top: ${ptop}px;` : ''"
      >
        <div class="hot1">
          <div class="hot1_1" style="cursor: pointer" @click="toplay">
            <img src="../assets/img/index/hot.png" alt="" /><span
              style="cursor: pointer"
              >更多</span
            >
          </div>
          <div class="hot1_cases">
            <div
              style="cursor: pointer"
              class="hot1_case"
              v-for="(item, i) in hg"
              :key="i"
              @click="toDetails(item.id)"
            >
              <img :src="item.image" alt="" />
              <div>
                <p>{{ item.game_name }}</p>
                <div>
                  <span v-for="(n, m) in item.game_tag" :key="m">{{ n }}</span>
                  <!-- <span>国风</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hot2">
          <img src="../assets/img/er.png" alt="" />
          <div class="hot2_1">
            <p>
              <img
                src="../assets/img/d.png"
                alt=""
                style="width: 177px; height: 31px; margin: 0"
                srcset=""
              />
            </p>
            <div>
              <p>玩游戏不花钱 薅羊毛 游戏托</p>
              <p>游戏当托就来羊托游</p>
            </div>
          </div>
          <p @click="todownLoad" style="cursor: pointer">能赚钱的手游APP</p>
        </div>
        <div class="hot_">
         
          <p>
            <span
              ><a href="https://admin.yangtuoyou.com/jianhu.html"
                >家长监护工程</a
              ></span
            >
            |
            <span
              ><a href="https://admin.yangtuoyou.com/jianhu.html"
                >防沉迷系统</a
              ></span
            >
            |
            <span
              ><a href="https://admin.yangtuoyou.com/jianhu.html"
                >纠纷处理</a
              ></span
            >
            |
            <span
              ><a href="https://admin.yangtuoyou.com/yhxy.html"
                >用户协议</a
              ></span
            >
          </p>
          <p>
            安徽羔羊网络科技有限公司互联网<br />ICP备案：皖ICP备2023010426号-2
          </p>
          <p>网站备案号：皖ICP备19002784号-7</p>
          <p>皖公网安备：34010402701713号</p>
          <p>互联网ICP备案：皖ICP备19002784号-3</p>
          <p>网络文化经营许可证：皖网文（2019）3164-137</p>
          <p>
            健康游戏忠告：<br />抵制不良游戏 拒绝盗版游戏 注意自我保护
            谨防受骗上当 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活
          </p>
        </div>
      </div>
    </div>
    <AppBg v-show="flagBg" @hide1="hide1"></AppBg>
    <!-- <transition name="el-fade-in"> -->
    <Popup v-show="show1" @hide2="hide2"></Popup>
  <!-- </transition> -->
  <transition name="el-fade-in">
    <div class="bg1" v-show="flagname">
      <div class="bm bm1">
        <span style="cursor: pointer" @click="closeAll" class="bm_span">X</span>
        <div class="bm_nav">
          <div class="flod">
            实名认证
            <span></span>
          </div>
        </div>
        <div class="phone phone_">
          <input
            type="text"
            v-model="username"
            placeholder="请输入真实姓名"
            class="phone1"
          />
        </div>
        <div class="phone phone_">
          <input
            type="text"
            v-model="idcard"
            placeholder="请输入身份证"
            class="phone1"
          />
        </div>
        <div class="bm_btn bm_btn1" @click="commitAuth" style="cursor: pointer">
          提交
        </div>
        <!-- <div class="bm_f">
          <p>未登录过羊托游的账号，我们将帮你完成注册</p>
          <p>登录即代表同意<span>用户协议</span>和<span>隐私政策</span></p>
        </div> -->
      </div>
    </div>
  </transition>
  <transition name="el-fade-in">
    <div class="bg1" v-show="flagphone1">
      <div class="bm bm1">
        <span @click="closeAll" style="cursor: pointer" class="bm_span">X</span>
        <div class="bm_nav">
          <div class="flod">
            手机换绑
            <span></span>
          </div>
        </div>
        <div class="phone phone_">
          <input
            type="text"
            placeholder="请输入新手机号"
            v-model="phone1"
            class="phone1"
          />
        </div>
        <div class="phone phone_">
          <input type="text" placeholder="请输入验证码" class="phone1" />
          <span
            class="ph2"
            style="cursor: pointer"
            @click="SendSms2"
            v-if="showCode2"
            >获取验证码</span
          >
          <span class="ph2" v-else> {{ sec2 }}秒后重试 </span>
        </div>
        <div class="bm_btn bm_btn1" @click="toPhone2" style="cursor: pointer">
          确定
        </div>
      </div>
    </div>
  </transition>
  <transition name="el-fade-in">
    <div class="bg1" v-show="flagphone">
      <div class="bm bm1">
        <span @click="closeAll" class="bm_span">X</span>
        <div class="bm_nav">
          <div class="flod">
            手机换绑
            <span></span>
          </div>
        </div>
        <div
          class="phone phone_"
          style="
            text-align: center;
            font-weight: 500;
            font-size: 19px;
            color: #191918;
            line-height: 49px;
            background-color: transparent;
          "
        >
          当前绑定手机号：{{ userinfo.user_phone }}
        </div>
        <div class="phone phone_">
          <input
            type="text"
            placeholder="请输入验证码"
            v-model="code"
            class="phone1"
          />
          <span
            class="ph2"
            @click="SendSms"
            v-if="showCode"
            style="cursor: pointer"
            >获取验证码</span
          >
          <span class="ph2" v-else> {{ sec }}秒后重试 </span>
        </div>
        <div class="bm_btn bm_btn1" @click="toPhone1" style="cursor: pointer">
          下一步
        </div>
      </div>
    </div>
  </transition>
  <transition name="el-fade-in">
    <div class="bg1" v-show="flagphone2">
      <div class="bm bm1">
        <!-- <span @click="closeAll" class="bm_span">X</span> -->
        <img src="../assets/img/mine/success.png" alt="" class="s" />
        <div class="s1">换绑成功</div>
        <div class="s2">当前绑定手机号：{{ userinfo.user_phone }}</div>
        <div class="bm_btn bm_btn1" @click="closeAll" style="cursor: pointer">
          确定
        </div>
      </div>
    </div>
  </transition>
    <!-- ......................... -->
  <transition name="el-fade-in">
    <div class="bg1" v-show="flagpass1">
      <div class="bm bm1">
        <span @click="closeAll" class="bm_span" style="cursor: pointer">X</span>
        <div class="bm_nav">
          <div class="flod">
            设置密码
            <span></span>
          </div>
        </div>
        <div class="phone phone_">
          <input
            type="text"
            v-model="pass"
            placeholder="请输入密码"
            class="phone1"
          />
        </div>
        <div class="phone phone_">
          <input
            type="text"
            v-model="repass"
            placeholder="请再次输入密码"
            class="phone1"
          />
        </div>
        <div class="bm_btn bm_btn1" @click="toPass2" style="cursor: pointer">
          确定
        </div>
      </div>
    </div>
  </transition>
  <transition name="el-fade-in">
    <div class="bg1" v-show="flagpass">
      <div class="bm bm1">
        <span @click="closeAll" class="bm_span" style="cursor: pointer">X</span>
        <div class="bm_nav">
          <div class="flod">
            设置密码
            <span></span>
          </div>
        </div>
        <div
          class="phone phone_"
          style="
            text-align: center;
            font-weight: 500;
            font-size: 19px;
            color: #191918;
            line-height: 49px;
            background-color: transparent;
          "
        >
          当前绑定手机号：{{ userinfo.user_phone }}
        </div>
        <div class="phone phone_">
          <input
            type="text"
            v-model="code3"
            placeholder="请输入验证码"
            class="phone1"
          />
          <span class="ph2" @click="SendSms3" v-if="showCode3">获取验证码</span>
          <span class="ph2" v-else> {{ sec3 }}秒后重试 </span>
        </div>
        <div class="bm_btn bm_btn1" @click="toPass1" style="cursor: pointer">
          下一步
        </div>
      </div>
    </div>
  </transition>
  <transition name="el-fade-in">
    <div class="bg1" v-show="flagpass2">
      <div class="bm bm1">
        <!-- <span @click="closeAll" class="bm_span">X</span> -->
        <img src="../assets/img/mine/success.png" alt="" class="s" />
        <div class="s1" style="margin-top: 38px; margin-bottom: 44px">
          设置密码成功
        </div>
        <div class="bm_btn bm_btn1" @click="closeAll" style="cursor: pointer">
          确定
        </div>
      </div>
    </div>
  </transition>
    <!-- ............................. -->
    <MyVouchers v-if="flagQuan" :gid="gid" @closeQuan="closeQuan"></MyVouchers>
    <ServerLaunch
      v-if="flagServer"
      :gid="gid"
      :flagHistory="flagHistory"
      @closeQuan="closeQuan"
    ></ServerLaunch>
    <ShareDialog
      v-if="flagShare"
      :gid="gid"
      @closeQuan="closeQuan"
    ></ShareDialog>
    <MyVouchers v-if="flagQuan" :gid="gid" @closeQuan="closeQuan"></MyVouchers>
    <MyGift v-if="flagGift" :gid="gid" @closeQuan="closeQuan"></MyGift>
    <MyVip v-if="flagVip" :gid="gid" @closeQuan="closeQuan"></MyVip>
    <MyAcc v-if="flagAcc" :gid="gid" @closeQuan="closeQuan"></MyAcc>
    <MyTicket v-if="flagTicket" :gid="gid" @closeQuan="closeQuan"></MyTicket>
    <!-- <div
      style="
        height: 198px;
        background-color: white;
        font-weight: 300;
        font-size: 14px;
        color: #a1a1a1;
        text-align: center;
        padding-top: 47px;
      "
  
    >
      <p>
        健康游戏忠告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当
        适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活
      </p>
      <p style="margin: 20px 0">
        家长监护工程 | 防沉迷系统 | 纠纷处理 | 用户协议
      </p>
      <p>安徽追游网络科技有限公司互联网ICP备案：皖ICP备2023010426号-2</p>
    </div> -->
  </div>
</template>
<script>
import AppBg from "../components/AppBg.vue";
import Popup from "../components/Popup.vue";
import MyVouchers from "../components/MyVouchers.vue";
import ServerLaunch from "./../components/ServerLaunch.vue";
import ShareDialog from "./../components/ShareDialog.vue";
import MyGift from "../components/MyGift.vue";
import MyVip from "../components/MyVip.vue";
import MyAcc from "../components/MyAcc.vue";
import MyTicket from "../components/MyTicket.vue";

export default {
  components: {
    AppBg,
    Popup,
    MyVouchers,
    ServerLaunch,
    ShareDialog,
    MyGift,
    MyVip,
    MyAcc,
    MyTicket,
  },
  data() {
    return {
      hotGame: [],
      hotS: [],
      userS: [],
      flagNav: 1,
      flagS: false,
      flagI: 1,
      flagd: false,
      flagBg: false,
      time: 7200,
      show1: false,
      flagG1: false,
      flagG2: false,
      flagTap: 1,
      flagTap1: 1,
      flagname: false,
      flagphone: false,
      flagphone1: false,
      flagphone2: false,
      flagpass: false,
      flagpass1: false,
      flagpass2: false,
      flagsign: false,
      flagServer: false,
      username: "",
      idcard: "",
      activation: "",
      userinfo: null,
      userinfo_: null,
      playList: [],
      sign_data: [],
      sign_list: [],
      phone1: "",
      isShow: false,
      showCode: true, //是否显示倒计时
      showCode2: true,
      showCode3: true,
      sec: 60, //倒计时,
      sec2: 60,
      sec3: 60,
      code: "",
      code2: "",
      code3: "",
      pass: "",
      repass: "",
      slist: [],
      details: {},
      activation_list: [
        { status: 0 },
        { status: 0 },
        { status: 0 },
        { status: 0 },
      ],
      daily_list: [],
      achieve_list: [],
      hg: [],
      tranList: [],
      textS: "",
      game_rebate: [],
      reservation_rebate: [],
      accde: {},
      flagDetail: false,
      flagcollect: false,
      flagQuan: false,
      flagShare: false,
      gid: 0,
      flagGift: false,
      flagVip: false,
      flagAcc: false,
      proportion: [20, 30, 40, 50, 55, 60, 66],
      flagTicket: false,
      flagv1: false,
      flagv2: false,
      flagv3: false,
      flagHistory: false,
      scrollTop: 0,
      ptop: 0,
      num: 0,
      hnum: 1,
    };
  },
  watch: {
    scrollTop(n, o) {
      // console.log(n,o)
      if (this.num >= 0) {
        if (n < o) {
          if (this.num < 409.2) {
            this.num = this.num + 34.1;
          }

          // console.log(this.ptop,'oooooo')
        } else {
          this.num = this.num - 34.1;
        }
      } else {
        this.num = 0;
      }
      //console.log(this.num,'oooooo')
    },
  },
  mounted() {
    this.getUserInfo();
    this.playGame();
    this.signList();
    this.taskActivation();
    this.weekActivation();
    this.dailyList();
    this.achieveList();
    this.gameRebate();
    this.$http.post("pc/index/hot_list").then((res) => {
      if (res.code == 20000) {
        this.hg = res.data;
        console.log(res, "hg...............");
      }
    });
    if (!this.details.server_name) {
      this.flagHistory = true;
    }
  },
  methods: {
    sa(e) {
      this.scrollTop = this.$refs.cmain.scrollTop;
      this.hnum = this.$refs.games.offsetHeight;
      this.ptop = this.scrollTop - 400 + 86;
      // console.log(this.scrollTop)
    },
    onScroll1(event) {
      const { scrollTop, clientHeight, scrollHeight } = this.$refs.main1;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        console.log("滚动到底部了");
        // 这里可以做加载更多数据的操作
        this.page = this.page + 1;

        this.$http
          .post("pc/index/selected", {
            page: this.page,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.reList.push(...res.data);
              // console.log(this.reList, "ioii");
            }
          });
      }
    },
    todownLoad() {
      this.$http.post("pc/index/download_app", {}).then((res) => {
        if (res.code == 20000) {
          // this.reList = res.data;
          // console.log(res.data,'//////////////')
          window.location.href = res.data;
          this.$msg.success("下载成功");
        }
      });
    },
    toHome() {
      this.$bus.$emit("changeNav");
      this.$router.push({ name: "index" }, () => {});
    },
    m1(e) {
      if (e == 1) {
        this.flagv1 = true;
        this.flagv2 = false;
        this.flagv3 = false;
      }
      if (e == 2) {
        this.flagv1 = false;
        this.flagv2 = true;
        this.flagv3 = false;
      }
      if (e == 3) {
        this.flagv1 = false;
        this.flagv2 = false;
        this.flagv3 = true;
      }
    },
    m2(e) {
      this.flagv1 = false;
      this.flagv2 = false;
      this.flagv3 = false;
    },
    toA1() {
      --this.userinfo_.user_grade_id;
    },
    toA2() {
      ++this.userinfo_.user_grade_id;
    },
    showTicket() {
      this.flagTicket = true;
    },
    openShare(e) {
      if (!this.userinfo) {
        this.open();
        return;
      }
      this.gid = e;
      this.flagShare = true;
    },
    toServer(e) {
      this.gid = e;
      if (!this.details.server_name) {
        this.flagHistory = true;
      }
      this.flagServer = true;
    },
    closeQuan() {
      this.flagQuan = false;
      this.flagServer = false;
      this.flagShare = false;
      this.flagGift = false;
      this.flagVip = false;
      this.flagAcc = false;
      this.flagTicket = false;
      this.flagHistory = false;
    },
    toAcc(e) {
      this.gid = e;
      this.flagAcc = true;
    },
    toVip(e) {
      this.gid = e;
      this.flagVip = true;
    },
    toQuan(e) {
      this.gid = e;
      this.$http
        .post("pc/Game/cp_ticket_list", {
          game_id: this.gid,
          page: 1,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 20000) {
            if (res.data.data.length > 0) {
              this.flagQuan = true;
            } else {
              this.$msg.error("该游戏暂无优惠券");
            }
          }
        });
    },
    toGift(e) {
      this.gid = e;
      this.flagGift = true;
    },
    toCollect(e) {
      if (localStorage.getItem("token")) {
        this.$http
          .post("pc/game/game_collect", {
            game_id: e,
          })
          .then((res) => {
            console.log(res);
            if (this.flagcollect) {
              this.$msg.success("取消收藏成功");
            } else {
              this.$msg.success("收藏成功");
            }
            this.flagcollect = !this.flagcollect;
          });
      } else {
        this.open();
        return;
      }
    },
    toDel() {
      this.flagDetail = false;
    },
    toCDetails(e) {
      // this.tranList = [];
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.$refs.cmain.scrollTop=0
      this.$http
        .post("pc/transaction/transaction_detail", {
          id: e,
        })
        .then((res) => {
          if (res.code == 20000) {
            // this.reList = res.data;
            console.log(res, "de....");
            this.accde = res.data;
          }
        });
      this.flagDetail = true;
      // this.flagBuy = true;
    },
    search1(e) {
      this.textS = e;
      this.flagS = false;
      this.flagd = false;
      this.$http
        .post("pc/index/search", {
          keyword: this.textS,
        })
        .then((res) => {
          console.log(res, "ooppp");
          if (res.code == 20000) {
            // this.apList.push(...res.data);
            // console.log(this.apList, "apList");
            this.slist = res.data;
          }
        });
      this.flagG1 = true;
    },
    search2(e) {
      this.textS = e;
      this.flagS = false;
      this.flagd = false;
      this.$http
        .post("pc/index/search", {
          keyword: this.textS,
        })
        .then((res) => {
          console.log(res, "ooppp");
          if (res.code == 20000) {
            // this.apList.push(...res.data);
            // console.log(this.apList, "apList");
            this.slist = res.data;
          }
        });
      this.flagG2 = true;
    },
    search() {
      this.flagS = false;
      this.flagd = false;
      if (this.textS == "") {
        return;
      }
      this.$http
        .post("pc/index/search", {
          keyword: this.textS,
        })
        .then((res) => {
          console.log(res, "ooppp");
          if (res.code == 20000) {
            // this.apList.push(...res.data);
            // console.log(this.apList, "apList");
            this.slist = res.data;
          }
        });
      this.flagG1 = true;
    },
    search_2() {
      this.flagS = false;
      this.flagd = false;
      console.log(2222222, this.textS);
      if (this.textS == "") {
        this.flagG2 = false;
        return;
      }
      this.$http
        .post("pc/index/search", {
          keyword: this.textS,
        })
        .then((res) => {
          console.log(res, "ooppp");
          if (res.code == 20000) {
            // this.apList.push(...res.data);
            // console.log(this.apList, "apList");
            this.slist = res.data;
          }
        });
      this.flagG2 = true;
    },
    closeA() {
      this.isShow = false;
      this.flagG1 = false;
      this.flagG2 = false;
      this.flagd = false;
    },
    // 退出登录
    logout() {
      this.$msg.success("退出成功");
      this.$store.commit("clear");
      localStorage.removeItem("token");
      localStorage.removeItem("userinfo");
      // this.$zml.getUserinfo();
      window.location.href = "/#/";
    },
    showUserInfo() {
      this.isShow = true;
    },
    //发送验证码
    SendSms() {
      setTimeout(() => {
        this.$http
          .post("pc/AliSendSms/SendSms", {
            phone: this.userinfo.phone,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.$msg.success(res.msg);
              // 开启倒计时
              let timer = setInterval(() => {
                this.showCode = false;
                this.sec--;
                if (this.sec == 0) {
                  clearInterval(timer);
                  this.sec = 60;
                  this.showCode = true;
                }
              }, 1000);
            }

            // 处理成功响应
          });
      }, 500);
    },
    gameRebate() {
      this.$http.post("pc/user/game_rebate").then((res) => {
        if (res.code == 20000) {
          this.game_rebate = res.game_rebate;
          this.reservation_rebate = res.reservation_rebate;
          console.log("游戏返利", this.game_rebate, this.reservation_rebate);
        }
      });
    },
    //发送验证码
    SendSms2() {
      setTimeout(() => {
        this.$http
          .post("pc/AliSendSms/SendSms", {
            phone: this.phone1,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.$msg.success(res.msg);
              // 开启倒计时
              let timer = setInterval(() => {
                this.showCode2 = false;
                this.sec2--;
                if (this.sec2 == 0) {
                  clearInterval(timer);
                  this.sec2 = 60;
                  this.showCode2 = true;
                }
              }, 1000);
            }
          });
      }, 500);
    },
    //发送验证码
    SendSms3() {
      setTimeout(() => {
        this.$http
          .post("pc/AliSendSms/SendSms", {
            phone: this.userinfo.phone,
          })
          .then((res) => {
            if (res.code == 20000) {
              this.$msg.success(res.msg);
              // 开启倒计时
              let timer = setInterval(() => {
                this.showCode3 = false;
                this.sec3--;
                if (this.sec3 == 0) {
                  clearInterval(timer);
                  this.sec3 = 60;
                  this.showCode3 = true;
                }
              }, 1000);
            }
          });
      }, 500);
    },
    // 实名认证
    commitAuth() {
      if (this.username == "") {
        this.$msg.error("请输入真实姓名");
        return;
      }
      if (this.idcard == "") {
        this.$msg.error("请输入身份证号");
        return;
      }
      this.$http
        .post("pc/User/user_auth", {
          name: this.username,
          card_no: this.idcard,
        })
        .then((res) => {
          if (res.code == 20000) {
            this.$msg.success(res.msg);
            // this.$zml.getUserinfo();
            this.closeAll();
          }
        });
    },
    closeSign() {
      this.flagsign = false;
    },
    toSign() {
      this.flagsign = true;
      this.flagDetail = false;

    },
    toName() {
      this.flagname = true;
    },
    toPhone() {
      this.flagphone = true;
    },
    toPhone1() {
      if (this.code == "") {
        this.$msg.error("请输入验证码");
        return;
      }
      this.$http
        .post("pc/login/check_code", {
          phone: this.userinfo.phone,
          code: this.code,
        })
        .then((res) => {
          if (res.code == 20000) {
            this.flagphone = false;
            this.flagphone1 = true;
          } else {
            this.$msg.error(res.msg);
          }
        });
    },
    toPhone2() {
      if (this.code == "") {
        this.$msg.error("请输入验证码");
        return;
      }
      this.$http
        .post("pc/login/check_code", {
          phone: this.phone1,
          code: this.code2,
        })
        .then((res) => {
          if (res.code == 20000) {
            this.$http
              .post("pc/User/edit_userinfo", {
                phone: this.phone1,
              })
              .then((res) => {
                if (res.code == 20000) {
                  this.$msg.success("换绑成功");
                  this.flagphone1 = false;
                  this.flagphone2 = true;
                } else {
                  this.$msg.error(res.msg);
                }
              });
          } else {
            this.$msg.error(res.msg);
          }
        });
    },
    toPass() {
      this.flagpass = true;
    },
    toPass1() {
      if (this.code3 == "") {
        this.$msg.error("请输入验证码");
        return;
      }
      this.$http
        .post("pc/login/check_code", {
          phone: this.phone1,
          code: this.code2,
        })
        .then((res) => {
          if (res.code == 20000) {
            this.flagpass = false;
            this.flagpass1 = true;
          } else {
            this.$msg.error(res.msg);
          }
        });
    },
    toPass2() {
      this.flagpass1 = false;
      this.flagpass2 = true;
    },
    closeAll() {
      this.flagname = false;
      this.flagphone = false;
      this.flagphone2 = false;
      this.flagphone1 = false;
      this.flagpass = false;
      this.flagpass1 = false;
      this.flagpass2 = false;
    },
    toTap1(e) {
      this.flagTap1 = e;
    },
    changeTap(e) {
      if (this.flagTap == e) {
        return;
      }
      this.flagTap = e;
      if (this.flagTap == 1) {
        this.playGame();
      } else if (this.flagTap == 2) {
        this.reservation();
      } else if (this.flagTap == 3) {
        this.collectList();
      }
    },
    open() {
      this.show1 = true;
    },
    toMine() {
      this.$bus.$emit("change");

      window.location.href = "/#/mine";
    },
    toplay() {
      this.flagBg = true;
    },
    hide1() {
      this.flagBg = false;
    },
    hide2() {
      this.show1 = false;
    },
    onChange(e) {
      this.flagNav = e;
    },
    toDetails() {
      this.flagI = 0;
    },
    inputFocus() {
      this.$http.post("pc/index/search_record").then((res) => {
        if (res.code == 20000) {
          // this.reList = res.data;
          console.log(res, "ss....");
          this.hotGame = res.data.game;
          this.hotS = res.data.hot_search;
          this.userS = res.data.user_search;
          // this.tranList = res.data;
        }
      });
      this.flagS = true;
    },
    inputBlur() {
      this.flagS = false;
    },
    inputFocus1() {
      this.$http.post("pc/index/search_record").then((res) => {
        if (res.code == 20000) {
          // this.reList = res.data;
          console.log(res, "ss....");
          this.hotGame = res.data.game;
          this.hotS = res.data.hot_search;
          this.userS = res.data.user_search;
          // this.tranList = res.data;
        }
      });
      this.flagd = true;
    },
    inputBlur1() {
      this.flagd = false;
    },
    onBack() {
      this.flagI = 1;
    },
    // 用户信息
    getUserInfo() {
      var token = localStorage.getItem("token");
      console.log("token", token);
      if (token) {
        var userInfo = localStorage.getItem("userinfo");
        if (userInfo) {
          this.userinfo = JSON.parse(userInfo);
          this.userinfo_ = JSON.parse(userInfo);
        } else {
          this.$http.post("pc/user/userinfo").then((res) => {
            if (res.code == 20000) {
              this.userinfo = res.data;
              this.userinfo_ = res.data;
              console.log(res, "用户信息");
            }
          });
        }
      }
    },
    // 正在玩
    playGame() {
      this.$http.post("pc/user/play_list").then((res) => {
        if (res.code == 20000) {
          this.playList = res.data;
          console.log(res, "正在玩");
        }
      });
    },
    // 已预约
    reservation() {
      this.$http.post("pc/user/reservation_list").then((res) => {
        if (res.code == 20000) {
          this.playList = res.data;
          console.log(res, "已预约");
        }
      });
    },
    // 已收藏
    collectList() {
      this.$http.post("pc/user/collect_list").then((res) => {
        if (res.code == 20000) {
          this.playList = res.data;
          console.log(res, "已收藏");
        }
      });
    },
    // 签到列表
    signList() {
      this.$http.post("pc/user/sign_list").then((res) => {
        if (res.code == 20000) {
          this.sign_data = res.data;
          this.sign_list = res.data.list;
          console.log(res, "签到列表");
        }
      });
    },
    // 任务活跃度
    taskActivation() {
      this.$http.post("pc/User/task_activation").then((res) => {
        if (res.code == 20000) {
          this.activation_list = res.data;
          console.log(this.activation_list, "任务活跃度");
        }
      });
    },
    // 本周活跃度
    weekActivation() {
      this.$http.post("pc/Task/week_activation").then((res) => {
        if (res.code == 20000) {
          this.activation = res.data.activation;
          this.total_activation = res.data.total_activation;
          console.log(res, "本周活跃度");
        }
      });
    },
    // 日常任务
    dailyList() {
      this.$http.post("pc/User/task_daily").then((res) => {
        if (res.code == 20000) {
          this.daily_list = res.data;
          console.log(res, "日常任务");
        }
      });
    },
    // 成就任务
    achieveList() {
      this.$http.post("pc/User/achieve_list").then((res) => {
        if (res.code == 20000) {
          this.achieve_list = res.data;
          console.log(res, "成就任务");
        }
      });
    },
    toDetails(e) {
      this.$refs.cmain.scrollTop=0
      this.flagsign = false;
      this.flagDetail = false;

      this.$http
        .post("pc/game/game_detail", {
          game_id: e,
        })
        .then((res) => {
          if (res.code == 20000) {
            // this.reList = res.data;
            // console.log(res, "de....");
            this.details = res.data;
            this.flagcollect = res.data.collect;
          }
        });
      this.$http
        .post("pc/transaction/game_transaction", {
          game_id: e,
        })
        .then((res) => {
          if (res.code == 20000) {
            // this.reList = res.data;
            console.log(res, "jy....");
            this.tranList = res.data;
          }
        });
      this.flagI = 0;
      this.isShow = false;
      this.flagG1 = false;
      this.flagd = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  width: 1474px;
  // background: pink;
  margin: 0 auto;
  height: 100%;
  position: relative;
  .nav {
    height: 80px;
    // background-color: rgb(115, 230, 195);
    display: flex;
    position: relative;
    .logo {
      width: 142px;
      height: 44px;
      margin-top: 20px;
      margin-right: 215px;
    }
    .de_nav {
      width: 1047px;
      height: 48px;
      margin-top: 20px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      position: relative;
      // justify-content: space-between;
      > img {
        width: 30px;
        height: 30px;
        position: absolute;
      }
      .input_s {
        width: 226px;
        height: 48px;
        background: #f5f5f5;
        border-radius: 24px 24px 24px 24px;
        position: relative;
        display: flex;
        margin-left: 770px;
        input {
          width: 277px;
          height: 48px;
          background: #f5f5f5;
          border-radius: 24px 24px 24px 24px;
          box-sizing: border-box;
          padding-left: 27px;
        }
        img {
          width: 20px;
          height: 20px;
          position: absolute;
          top: 14px;
          right: 23px;
        }
      }
      .notice {
        width: 51px * 0.69;
        height: 59px * 0.69;
        margin-left: 13px;
        position: relative;
        img {
          width: 51px * 0.69;
          height: 59px * 0.69;
        }
        div {
          position: absolute;
          width: 22px;
          height: 15px;
          background: #f74c31;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #ffffff;
          font-weight: 500;
          font-size: 10px;
          color: #ffffff;
          text-align: center;
          line-height: 15px;
          top: 0;
          right: -6px;
        }
      }
      .de_sign {
        position: absolute;
        top: -20px;
        left: 0;
        width: 692px;
        height: 100vh;
        background: rgba(245, 245, 245, 1);
        z-index: 999;
        overflow-y: auto;
        padding-top: 29px;
        .de_back {
          width: 30px;
          height: 30px;
          margin-left: 11px;
          margin-bottom: 40px;
        }
      }
    }
    .input {
      width: 690px;
      height: 48px;
      background: #f5f5f5;
      // background: #b94848;
      position: relative;
      border-radius: 24px 24px 24px 24px;
      margin-top: 20px;
      position: relative;
      margin-right: 366px;
      input {
        width: 690px;
        height: 48px;
        border-radius: 24px 24px 24px 24px;
        padding-left: 25px;
        background: #f5f5f5;
      }
      img {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 14px;
        right: 23px;
      }
    }
    .login {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 24px;
      right: 19px;
    }
  }
  .input_content {
    width: 351px;
    // height: 557px;
    background: #ffffff;
    box-shadow: 0px 3px 22px 1px #f1f1f1;
    border-radius: 25px;
    position: absolute;
    right: 0;
    box-sizing: border-box;
    top: 88px;
    padding: 24px 24px 20px 10px;
    z-index: 10;
    .main_case1 {
      box-sizing: border-box;
      height: 64px;
      width: 351px;
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      > img {
        height: 64px;
        width: 64px;
        margin-right: 12px;
      }
    }
    p {
      font-weight: 300;
      font-size: 14px;
      color: #909094;
      margin-bottom: 14px;
    }
  }
  .main {
    width: 690px;
    height: 100%;
    // background: rgb(177, 235, 144);
    margin-left: 359px;
    padding-top: 1px;
  }
  .hot {
    position: absolute;
    top: 86px;
    right: 0;
    width: 351px;
    // height: 100vh;
    // overflow: auto;
    // padding-bottom: 100px;
    .hot1 {
      width: 351px;
      height: 764px;
      background: #f5f5f5;
      border-radius: 28px 28px 28px 28px;
      margin-bottom: 39px;
      padding-top: 19px;
      .hot1_1 {
        height: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        img {
          width: 65px;
          height: 15px;
          margin-left: 27px;
          margin-right: 203px;
        }
        span {
          font-weight: 300;
          font-size: 12px;
          color: rgba(170, 170, 170, 0.86);
        }
      }
      .hot1_cases {
        height: 668px;
        width: 351px;
        box-sizing: border-box;
        overflow-y: auto;
        // padding-left: 27px;
        .hot1_case {
          box-sizing: border-box;
          padding-left: 27px;
          height: 64px;
          width: 351px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          img {
            height: 64px;
            width: 64px;
            margin-right: 12px;
          }
          div {
            font-weight: 700;
            font-size: 16px;
            color: #191918;
            margin-top: 5px;
            span {
              font-weight: 500;
              font-size: 12px;
              color: rgba(170, 170, 170, 0.86);
              margin-right: 8px;
            }
          }
        }
      }
    }
    .hot2 {
      width: 351px;
      height: 191px;
      background: #f5f5f5;
      border-radius: 28px 28px 28px 28px;
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 48px;
      > p {
        position: absolute;
        bottom: 30px;
        left: 67px;
        width: 217px;
        height: 38px;
        background: #f57a00;
        border-radius: 13px 13px 13px 13px;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        text-align: center;
        line-height: 38px;
      }
      img {
        width: 78px;
        height: 78px;
        border-radius: 0px 0px 0px 0px;
        margin-left: 27px;
        margin-right: 22px;
      }
      .hot2_1 {
        p {
          font-weight: 700;
          font-size: 20px;
          color: rgba(25, 25, 24, 1);
          margin-bottom: 4px;
          span {
            color: #f8ba10;
            margin-left: 4px;
          }
        }
        div {
          // width: 180px;
          p {
            font-weight: 700;
            font-size: 12px;
            color: rgba(110, 110, 110, 0.86);
            margin-bottom: 3px;
            text-align: center;
          }
        }
      }
    }
  }
}

.main {
  // background-color: #acc;
  padding-bottom: 40px;
  .pic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pic1 {
      display: flex;
      align-items: center;
      position: relative;
      .img1 {
        width: 113px;
        height: 113px;
      }
      > div {
        margin-left: 25px;
      }
      .p1 {
        font-weight: 500;
        font-size: 28px;
        color: #191918;
        margin-bottom: 4px;
      }
      .p2 {
        font-weight: 500;
        font-size: 19px;
        color: #000000;
      }
      .tap1 {
        width: 41px;
        height: 41px;
        position: absolute;
        bottom: -55px;
        left: 0;
      }
      .tap2 {
        width: 41px;
        height: 41px;
        position: absolute;
        bottom: -55px;
        left: 48px;
      }
      .tap3 {
        width: 33px * 0.9;
        height: 27px * 0.9;
        position: absolute;
        bottom: -48px;
        left: 104px;
      }
    }
    .sheep {
      width: 130px;
      height: 148px;
    }
  }
  .gcase {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 60px;
    // background: pink;
    padding: 0 34px;
    img {
      width: 11px;
      height: 23px;
    }
    .gcase1 {
      display: flex;

      .gcase_1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        .gcase_11 {
          font-size: 42px;
          font-weight: 700;
          color: RGBA(252, 135, 2, 1);
        }
        .gcase_12 {
          width: 87px;
          height: 30px;
          background: #fc8702;
          border-radius: 11px 11px 11px 11px;
          border: 1px solid #ffffff;
          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
          text-align: center;
          line-height: 29px;
          position: relative;
        }
        .vip_tap {
          // font-family: "GH";
          // width: 66px;
          // height: 36px;
          // line-height: 40px;
          // font-size: 60px;
          text-align: center;
          // font-weight: 400;
          color: #fc8702;
        }

        .vip_tap1 {
          color: #e2e2e2;
          position: relative;

          .vip_tap_1 {
            position: absolute;
            top: 0;
            left: 0;
            // height: 90px;
            width: 0%;
            background: #fc8702;
            color: transparent;
            background-clip: text;
          }
        }
      }
      .gcase_2 {
        padding-top: 10px;
        .gcase_21 {
          width: 327px;
          height: 35px;
          background: #e2e2e2;
          border-radius: 11px 11px 11px 11px;
          margin-bottom: 16px;
          position: relative;
          overflow: hidden;
          > div {
            background: linear-gradient(90deg, #ffcc68 0%, #fc8600 100%);
            width: 0%;
            height: 35px;

            position: absolute;
            top: 0;
            left: 0;
            z-index: 99;
          }
        }
        .gcase_22 {
          font-weight: 500;
          font-size: 17px;
          color: #fc8b07;
          text-align: center;
        }
      }
    }
  }
  .mine_pic3 {
    height: 137px;
    width: 100%;
    // background-color: lightgoldenrodyellow;
    margin-top: 52px;
    display: flex;
    padding-left: 58px;
    padding-top: 39px;
    padding-bottom: 37px;
    // padding-right: 58px;
    // justify-content: space-between;
    background: rgba(255, 255, 255, 1);
    border-radius: 24px;
    box-shadow: 0 3px 22px 1px rgba(252, 189, 94, 0.2);
    .mine_pic3_1 {
      display: flex;
      align-items: center;
      flex: 1;
      .mine_pic3_img {
        width: 60px;
        height: 60px;
        margin-right: 11px;
      }

      .mine_pic3_text {
        display: flex;

        .mine_pic3_text1 {
          // width: 84px;
          height: 40px;
          font-size: 25px;
          font-weight: 500;
          color: #1c1c1e;
          line-height: 40px;
        }

        .mine_pic3_text2 {
          // width: 34px;
          margin-left: 12px;
          height: 40px;
          font-size: 25px;
          font-weight: 500;
          color: #fb9102;
          line-height: 40px;
        }
      }
    }
    .m_k {
      padding-left: 28px;
    }
  }
  .myGames_tap {
    width: 750px;
    display: flex;
    padding-top: 40px;
    padding-left: 66px;
    margin-bottom: 48px;
    position: relative;
    z-index: 100;

    .tap_case {
      font-size: 20px;
      font-weight: 500;
      color: #1c1c1e;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 62px;

      div {
        width: 46px;
        height: 4px;
        // background: #FEBD0D;
        border-radius: 6px;
        margin-top: 6px;
        opacity: 1;
      }

      .tap_case2 {
        background: rgba(252, 135, 2, 1);
      }
    }

    .tap_case1 {
      font-size: 20px;
      font-weight: 700;
      color: #1c1c1e;
    }
  }
  .new_recommended1 {
    // position: relative;
    display: flex;
    margin-left: 40px;
    position: relative;
    align-items: center;
    background-color: #fff;
    // height: 200px;
    // width: 670px;
    // box-shadow: 0px 2px 12px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 42px;
    border-radius: 14px;
    .btn {
      width: 94px;
      height: 44px;
      border-radius: 22px;
      background: rgba(255, 128, 47, 1);
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      line-height: 44px;
      position: absolute;
      top: 21px;
      right: 51px;
    }
    .title {
      width: 86px;
      height: 86px;
      border-radius: 14px;
      overflow: hidden;

      // margin-top: 72px;
      // margin-left: 24px;
      .img {
        background-color: #c0c0c0;
        width: 86px;
        height: 86px;
      }
    }

    .texts {
      margin-left: 38px;

      .text1 {
        width: 450px;
        // height: 44px;
        font-size: 19px;
        font-weight: 700;
        color: rgba(67, 62, 62, 1);
        // line-height: 44px;
        overflow: hidden;
        overflow-x: auto;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        // justify-content: space-between;
        align-items: center;

        .gggg {
          margin-left: 20px;
          height: 38px;
          background: #ebebeb;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 18px;
          border-radius: 20px;
        }

        span {
          // width: 168px;
          // height: 40px;
          line-height: 24px;
          // vertical-align: middle;
          // padding-left: 18px;
          // padding-right: 18px;
          background: #ebebeb;
          border-radius: 20px 20px 20px 20px;
          font-weight: 500;
          font-size: 24px;
          color: #808080;
        }
      }

      .text2 {
        // height: 34px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(144, 144, 148, 1);
        // line-height: 34px;
        display: flex;
        margin-top: 5px;
        margin-bottom: 11px;
        display: flex;
        position: relative;

        img {
          width: 125px;
          height: 42px;
          position: absolute;
          top: -8px;
          right: 0;
        }

        .span {
          margin-right: 8px;
        }
      }

      .text3 {
        display: flex;
        // width: 440px;
        // overflow-x: auto;
        // overflow-y: hidden;
        height: 26px;

        .text3_span {
          flex-shrink: 0;
          font-size: 14px;
          // padding-left: 8px;
          // padding-right: 8px;
          height: 26px;
          // line-height: 38px;
          padding: 0px 8px;
          // padding-top: 1px;
          box-sizing: border-box;
          border-radius: 8px 8px 8px 8px;
          opacity: 1;
          border: 1px solid #ff7534;
          margin-right: 14px;
          color: #ff7534;
          display: flex;
          align-items: center;
          font-weight: 500;
          justify-content: center;

          > span {
            line-height: 22px;
            // border: 2px solid #FF7534;
          }
        }

        .text3_span1 {
          width: 204px;
          height: 36px;
          margin-right: 14px;
          position: relative;

          img {
            width: 204px;
            height: 36px;
          }

          div {
            height: 36px;
            // line-height: 36px;
            position: absolute;
            top: 0;
            left: 64px;
            font-weight: 500;
            font-size: 22px;
            color: #ff7534;
            display: flex;
            align-items: center;

            // background-color: #808080;
            span {
              line-height: 22px;

              // background-color: #808080;
            }
          }
        }
      }
    }
  }
  .banner {
    width: 690px;
    height: 390px;
    background: #acc;
    border-radius: 25px 25px 25px 25px;
    .el-carousel {
      height: 100%;
      border-radius: 25px 25px 25px 25px;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 300px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
  }
  .d_h5 {
    margin-left: 29px;
    > img {
      width: 145px;
      height: 27px;
      margin-bottom: 43px;
    }
    .span_p {
      display: flex;
      flex-direction: column;
      width: 625px;
      text-align: left;
      align-items: center;

      font-weight: 300;
      font-size: 16px;
      // line-height: 28px;
      // text-decoration: underline;
      color: rgba(170, 170, 170, 0.86);
      span {
        height: 1px;
        width: 159px;
        background: rgba(170, 170, 170, 0.86);
      }
    }
    .d_h5_1 {
      width: 625px;
      // height: 252px;
      background: #ffffff;
      box-shadow: 0px 6px 22px 1px rgba(0, 0, 0, 0.09);
      border-radius: 19px 19px 19px 19px;
      box-sizing: border-box;
      padding-top: 23px;
      padding-left: 30px;
      margin-bottom: 41px;
      padding-bottom: 40px;

      p {
        font-weight: 700;
        font-size: 16px;
        color: #191918;
      }
      .d_h5_1_c1 {
        display: flex;
        margin-top: 11px;
        margin-bottom: 16px;
        position: relative;
        > span {
          height: 19px;
          font-weight: 300;
          font-size: 12px;
          background: rgba(248, 191, 10, 1);
          color: #ffffff;
          line-height: 19px;
          padding: 0 9px;
          border-radius: 4px;
          margin-right: 9px;
        }
        div {
          height: 19px;
          position: absolute;
          top: 0;
          right: 36px;
          font-weight: 700;
          font-size: 16px;
          color: #f6696c;
          span {
            font-size: 12px;
          }
        }
      }
      .d_h5_1_c2 {
        padding-right: 30px;
        // height: 123px;
        display: flex;
        justify-content: space-between;
        div {
          width: 123px;
          height: 123px;
          background: #f5f5f5;
          border-radius: 14px 14px 14px 14px;
        }
      }
    }
  }
  .d_h4 {
    margin-bottom: 40px;
    margin-left: 29px;
    .el-carousel {
      height: 100%;
      // width: 625;
      border-radius: 25px 25px 25px 25px;
    }
    .el-carousel__item {
      width: 236px;
    }

    img {
      width: 145px;
      height: 27px;
      margin-bottom: 33px;
    }
    div {
      font-weight: 300;
      font-size: 16px;
      color: rgba(59, 58, 58, 0.83);
      padding-left: 3px;
      padding-right: 13px;
      line-height: 24px;
    }
  }
  .d_h3 {
    width: 636px;
    height: 58px;
    border-radius: 29px;
    box-shadow: 0px 3px 22px 1px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 60px;
    margin-left: 29px;
    div {
      margin-left: 46px;
      margin-right: 34px;
      font-weight: 700;
      font-size: 16px;
      color: #ffc844;
    }
    span {
      font-weight: 300;
      font-size: 16px;
      color: #909094;
    }
    b {
      font-weight: 700;
      font-size: 29px;
      color: #ffc844;
      position: absolute;
      top: 7px;
      right: 45px;
    }
  }
  .d_h2 {
    width: 636px;
    height: 111px;
    border-radius: 20px;
    box-shadow: 0px 3px 22px 1px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    margin-bottom: 44px;
    margin-left: 29px;
    .d_h2_1 {
      width: 318px;
      padding-top: 14px;
      box-sizing: border-box;
      height: 111px;
      .d_h2_1_1 {
        font-weight: 700;
        font-size: 16px;
        color: #2c2c2c;
        display: flex;
        align-items: center;
        margin-left: 42px;
        img {
          margin-right: 7px;
          width: 29px;
          height: 29px;
        }
      }
      .d_h2_1_2 {
        height: 35px;
        border-left: 1px dashed rgba(255, 200, 68, 1);
        padding-left: 14px;
        margin-left: 56px;
        margin-top: 10px;
        position: relative;
        padding-top: 1px;
        b {
          display: block;
          width: 6px;
          height: 6px;
          background: rgba(255, 200, 68, 1);
          border-radius: 100%;
          position: absolute;
          top: -3px;
          left: -4px;
        }
        span {
          font-weight: 300;
          font-size: 12px;
          display: block;
          color: #909094;
          margin-top: -5px;
          margin-bottom: 10px;
        }
        div {
          display: flex;
          align-items: center;
          font-weight: 300;
          font-size: 14px;
          color: #2c2c2c;
          img {
            width: 37px;
            height: 10px;
            margin-right: 14px;
          }
        }
      }
    }
    // .d_h2_2:nth-child(1){
    //   margin-left: 300px;
    // }
    .d_h2_2_ {
      margin-left: 35px;
    }
    .d_h2_2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-weight: 300;
      font-size: 14px;
      color: #909094;
      margin-right: 55px;
    }
  }
  .d_h1 {
    padding-left: 29px;
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    position: relative;
    .btn {
      width: 116px;
      height: 44px;
      border-radius: 22px;
      background: linear-gradient(
        to right,
        rgba(250, 138, 10, 1) 0%,
        rgba(245, 72, 7, 1) 100%
      );
      box-shadow: 0px 2px 10px 1px rgba(255, 83, 0, 0.55);
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      line-height: 44px;
      position: absolute;
      bottom: 0;
      right: 42px;
    }
    > img {
      width: 106px;
      height: 106px;
      border-radius: 0px 0px 0px 0px;
      margin-right: 17px;
    }
    .d_h1_1 {
      font-weight: 700;
      font-size: 16px;
      color: #191918;
    }
    .d_h1_2 {
      font-weight: 300;
      font-size: 12px;
      color: rgba(170, 170, 170, 0.86);
      margin-top: 6px;
      margin-bottom: 10px;
    }
    .d_h1_3 {
      display: flex;
      span {
        // width: 69px;
        height: 21px;
        font-size: 12px;
        line-height: 21px;
        font-weight: 300;
        // background: #F8810A;
        border-radius: 4px 4px 4px 4px;
        padding: 0 10px;
        margin-right: 9px;
      }
      .d_h1_3_1 {
        background: rgba(248, 129, 10, 1);
        color: #ffffff;
      }
      .d_h1_3_2 {
        border: 1px solid #f8810a;
        color: #f8810a;
      }
    }
  }
}
.input_cK {
  width: 351px;
  // height: 557px;
  background: #ffffff;
  box-shadow: 0px 3px 22px 1px #f1f1f1;
  border-radius: 25px 25px 25px 25px;
  position: absolute;
  right: 0px;
  box-sizing: border-box;
  top: 88px;
  padding: 16px 27px 37px;
  z-index: 10;
  p {
    font-weight: 300;
    font-size: 14px;
    color: #909094;
    margin-bottom: 14px;
  }
  .input_c1 {
    display: flex;
    flex-wrap: wrap;
    span {
      // width: 114px;
      flex-shrink: 0;

      height: 37px;
      background: #f5f5f5;
      border-radius: 19px 19px 19px 19px;
      font-size: 12px;
      line-height: 37px;
      color: rgba(145, 145, 145, 1);
      padding: 0 20px;
      margin-right: 17px;
      margin-bottom: 17px;
    }
  }
  > img {
    width: 44px;
    height: 18px;
  }
  .input_c2 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > div {
      font-weight: 300;
      font-size: 16px;
      height: 44px;
      color: #919191;
      width: 296px;
      // background: #caa;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      // flex-wrap: wrap !important;

      span {
        width: 4px;
        height: 4px;
        background: #f65d08;
        border-radius: 100%;
        margin-right: 12px;
      }
      img {
        width: 22px;
        height: 22px;
        margin-left: 20px;
      }
    }
  }
}
.input_cK1 {
  width: 351px;
  // height: 557px;
  background: #ffffff;
  box-shadow: 0px 3px 22px 1px #f1f1f1;
  border-radius: 25px 25px 25px 25px;
  position: absolute;
  right: 0px;
  box-sizing: border-box;
  top: 88px;
  padding: 34px 16px 37px;
  z-index: 10;
  .p_h {
    // background-color: pink;
    padding-left: 14px;
    display: flex;
    align-items: center;
    position: relative;
    .h_pic {
      width: 62px;
      height: 62px;
    }
    > div {
      // height: auto;
      // background-color: #acc;
      margin-left: 13px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // padding-top: 9px;
      .p1 {
        font-weight: 500;
        font-size: 15px;
        color: #191918;
        margin-bottom: 7px;
      }
      .p2 {
        font-weight: 500;
        font-size: 10px;
        color: #000000;
        margin-bottom: 7px;
      }
    }
    .sign {
      width: 64px;
      height: 36px;
      position: absolute;
      top: 12px;
      right: 7px;
    }
  }
}
.mine_bg1 {
  width: 698px * 0.46;
  height: 378px * 0.46;
  background: linear-gradient(90deg, #ffe1bb 0%, #ffe295 100%);
  border-radius: 20px * 0.46;
  // margin-left: 26px;
  margin-top: 26px;
  position: relative;

  .mine_bg1_tap {
    width: 250px * 0.46;
    height: 118px * 0.46;
    // box-shadow: 0px 6px 12px 2px rgba(248,105,10,0.44);
    border-radius: 0px;
    font-weight: 700;
    font-size: 24px * 0.46;
    color: #ffffff;
    text-align: center;
    position: absolute;
    top: -11px * 0.46;
    left: 0px;

    img {
      width: 250px * 0.46;
      height: 118px * 0.46;
    }

    .mine_bg1_tap_1 {
      width: 250px * 0.46;
      height: 118px * 0.46;
      position: absolute;
      padding-top: 24px * 0.46;
      top: 0;
      left: 0;
    }

    .mine_bg1_tap1 {
      font-size: 20px * 0.46;
      margin-top: 4px * 0.46;
    }
  }

  .mine_bg1_h {
    font-weight: 700;
    font-size: 24px * 0.46;
    color: #ca6910;
    padding-left: 78px * 0.46;
    padding-top: 16px * 0.46;
    // margin-top: 78px;
    position: relative;

    img {
      width: 16px * 0.46;
      height: 16px * 0.46;
      margin-top: 104px * 0.46;
      margin-left: 6px * 0.46;
    }

    .mine_bg1_h_1 {
      position: absolute;
      width: 174px * 0.46;
      height: 62px * 0.46;
      background: linear-gradient(90deg, #ffa069 0%, #ffbe3b 100%);
      // box-shadow: 0px 6px 24px 2px rgba(255,171,63,0.74);
      border-radius: 32px * 0.46;
      font-weight: 700;
      font-size: 24px * 0.46;
      color: #ffffff;
      text-align: center;
      line-height: 62px * 0.46;
      top: 60px * 0.46;
      right: 94px * 0.46;

      div {
        width: 82px * 0.46;
        height: 40px * 0.46;
        background: #fc6111;
        border-radius: 26px * 0.46 26px * 0.46 26px * 0.46 0px;
        // border: 2px solid #FFFFFF;
        font-weight: 500;
        font-size: 20px * 0.46;
        color: #ffffff;
        text-align: center;
        line-height: 40px * 0.46;
        position: absolute;
        top: -24px * 0.46;
        right: -48px * 0.46;
      }
    }
    .mine_bg1_h_1_ {
      background: #a5a5a5 !important;
      // color: #1C1C1E;
    }
  }

  .mine_bg1_main {
    width: 662px * 0.46;
    height: 194px * 0.46;
    background: #ffffff;
    box-shadow: 0px 6px * 0.46 12px * 0.46 2px * 0.46 rgba(248, 146, 21, 0.23);
    border-radius: 24px * 0.46;
    display: flex;
    margin-top: 26px * 0.46;
    margin-left: 18px * 0.46;
    font-weight: 500;
    padding-left: 26px * 0.46;
    padding-right: 26px * 0.46;
    font-size: 24px * 0.46;
    align-items: center;
    justify-content: space-between;
    color: #ca6910;

    div > div {
      width: 84px * 0.46;
      height: 84px * 0.46;
      border-radius: 100%;
      background-color: rgba(255, 226, 157, 0.31);
      margin-bottom: 14px * 0.46;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .lact1 {
      color: #ca6910 !important;
    }
    .lact {
      background-color: rgba(255, 226, 157, 0.31) !important;
    }
    .mine_bg1_main_case {
      div {
        background-color: rgba(228, 228, 228, 0.31);
        position: relative;

        span {
          position: absolute;
          width: 68px * 0.46;
          height: 28px * 0.46;
          background: rgba(255, 150, 74, 1);
          border-radius: 18px * 0.46 18px * 0.46 18px * 0.46 0px;
          font-weight: 500;
          font-size: 20px * 0.46;
          color: #ffffff;
          text-align: center;
          line-height: 28px * 0.46;
          top: -18px * 0.46;
          right: -20px * 0.46;
        }
      }

      color: rgba(112, 112, 112, 1);
    }

    img {
      width: 82px * 0.46;
      height: 82px * 0.46;
    }
  }
}
.back_btn {
  width: 305px;
  height: 41px;
  border-radius: 22px;
  background-color: rgba(255, 128, 47, 1);
  margin-left: 6px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  line-height: 41px;
}
.setting {
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    color: #191918;
    margin-bottom: 26px;
    img {
      width: 35px;
      height: 35px;
      margin-bottom: 14px;
    }
  }
}
.welfare_h {
  width: 605px;
  height: 240px;
  border-radius: 32px;
  // box-shadow: 0px 6px 12px 2px rgba(247, 186, 8, 0.15);
  // margin-left: 32px;
  // margin-top: 150px;
  background-color: #fff;
  padding-top: 28px;
  padding-right: 50px;
  margin-bottom: 36px;
  margin-left: 44px;
  margin-top: 46px;
  // transform: scale(0.88);
  .welfare_h1 {
    display: flex;
    padding-left: 50px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    .welfare_h1_1 {
      font-size: 27px;
      font-weight: 500;
      color: #1c1c1e;

      span {
        font-size: 27px;
        font-weight: 500;
        color: #fca148;
        margin-left: 6px;
      }
    }

    .welfare_h1_2 {
      width: 136px;
      height: 49px;
      background: #ff8737;
      border-radius: 26px;
      opacity: 1;
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 49px;
      position: relative;

      div {
        width: 136px;
        height: 54px;
        position: absolute;
        top: 0;
        left: 0;
        // background-color: #35B9FE;
      }
    }

    .welfare_h1_21 {
      background-color: #aaa;
    }
  }

  .welfare_h2 {
    height: 92px;
    padding-right: 10px;
    padding-left: 58px;
    display: flex;
    justify-content: space-between;

    .welfare_h2_case {
      flex-shrink: 0;
      width: 55px;
      height: 100px;
      // border-radius: 14px;
      // background-color: #acc;
      margin-right: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .w_img {
        width: 55px;
        height: 55px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 37px;
          height: 37px;
        }
      }

      > img {
        width: 62px;
        flex-shrink: 0;
        height: 62px;
      }

      > .w_text {
        font-weight: 500;
        font-size: 20px;
        color: #7a7a7a;
      }

      .img_gift {
        width: 56px;
        height: 56px;
      }

      .img_dui {
        width: 40px;
        height: 40px;
      }
    }

    // .welfare_h2_case1 {
    // 	// background-color: rgba(255, 189, 1, 0.67);
    // }
  }
}

.welfare_main {
  // transform: scale(0.88);

  width: 605px;
  border-radius: 32px;
  margin-left: 44px;
  // box-shadow: 0px 0px 12px 2px rgba(247, 186, 8, 0.27);
  background-color: #fff;
  // height: 1042px;
  position: relative;
  padding-top: 34px;
  height: 933px;
  overflow: hidden;

  // overflow-y: hidden;
  .welfare_main_h {
    font-weight: 500;
    font-size: 27px;
    color: #1c1c1e;
    margin-left: 44px;
    margin-bottom: 58px;
  }

  .welfare_main_process {
    position: relative;
    height: 12px;
    // background-color: pink;

    .progress_bar_span {
      // background-color: #35B9FE;
      height: 44px;
      width: 100%;
      margin-top: 40px;
      display: flex;

      > div {
        height: 44px;
        width: 118px;
        display: flex;
        justify-content: center;

        // flex: 1;
        // margin-left: 60px;
        // background-color: hotpink;
        // border-left: 1px solid pink;
        .w_bar_1 {
          font-weight: 500;
          height: 37px;
          line-height: 37px;
          font-size: 20px;
          color: #333333;
        }

        .w_bar_2 {
          width: 90px;
          height: 37px;
          background: #ff8737;
          border-radius: 36px;
          font-weight: 500;
          font-size: 20px;
          color: #ffffff;
          text-align: center;
          line-height: 37px;
        }

        .w_bar_3 {
          font-weight: 500;
          font-size: 20px;
          color: #333333;
          height: 37px;
          line-height: 37px;
        }
      }

      .w_bar1 {
        margin-left: 76px;
      }

      .w_bar2 {
        margin-left: 5px;
      }

      .w_bar3 {
        margin-left: 8px;
      }

      .w_bar4 {
        margin-left: 8px;
      }
    }

    .progress_bar {
      margin-left: 35px;
      // margin-right: 48px;
      height: 12px;
      width: 512px;
      // width: 100%;
      background: #d4d4d4;
      border-radius: 22px 22px 22px 22px;
      // overflow: hidden;
      position: relative;
      .progress_bar1 {
        height: 12px;
        // width: 146px;
        width: 63px;
        position: absolute;
        // background-color: #000;
        left: 0;
        top: 0;
        border-radius: 22px 0px 0px 22px;
        overflow: hidden;
      }
      .progress_bar2 {
        height: 12px;
        // width: 146px;
        width: 63px;
        position: absolute;
        // background-color: #000;
        left: 25%;
        top: 0;
        overflow: hidden;
      }
      .progress_bar3 {
        height: 12px;
        // width: 146px;
        width: 63px;
        position: absolute;
        // background-color: #000;
        left: 50%;
        top: 0;
        overflow: hidden;
      }
      .progress_bar4 {
        height: 12px;
        // width: 146px;
        width: 63px;
        position: absolute;
        // background-color: #000;
        left: 75%;
        top: 0;
        overflow: hidden;
      }
      .progress_bar_c {
        width: 0%;
        height: 12px;
        // background: linear-gradient(90deg, #FFFFFF 0%, #35B9FE 100%);
        background: #35b9fe;
        // background-color: #FD9E24;
        // border-radius: 22px 22px 22px 22px;
      }

      .w_span {
        width: 65px;
        height: 65px;
        border-radius: 100%;
        // background-color: #fcfcfc;
        position: absolute;
        // opacity: 0.8;
        img {
          width: 65px;
          height: 65px;
        }

        top: -28px;
        margin-left: -74px;
      }

      .w_span1 {
        left: 26.9%;
      }

      .w_span2 {
        left: 51.9%;
      }

      .w_span3 {
        left: 76.9%;
      }

      .w_span4 {
        left: 101.9%;
      }
    }

    img {
      width: 60px;
      height: 60px;
      margin-bottom: 16px;
    }

    .process_img1 {
      top: -26px;
      left: 150px;
    }

    .process_img2 {
      top: -26px;
      left: 296px;
    }

    .process_img3 {
      top: -26px;
      left: 454px;
    }

    .process_img4 {
      top: -26px;
      left: 592px;
    }

    .process_text {
      // width: 74px;
      height: 40px;
      // background: #FFFFFF;
      box-shadow: 0px 0px 12px 2px #d0d0d0;
      border-radius: 12px 12px 12px 12px;
      opacity: 1;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      text-align: center;
      line-height: 40px;
      position: absolute;
      left: -7px;
      bottom: -26px;
    }

    .process_text1 {
      color: rgba(249, 148, 16, 0.93);
      box-shadow: 0px 0px 12px 2px rgba(253, 132, 0, 0.26);
    }

    .process_m {
      position: absolute;
    }
  }
}
.process_tap {
  width: 605px;
  height: 100%;
  overflow: hidden;
  margin-top: 128px;

  .process_tap_main {
    width: 605px;
    display: flex;
    // height: 100%;
    margin-bottom: 42px;

    // overflow: hidden;
    .process_tap1 {
      flex: 1;
      display: flex;
      // margin-bottom: 50px;
      flex-direction: column;
      align-items: center;
      // background-color: pink;
      font-size: 25px;
      font-weight: 500;
      color: #1c1c1e;

      div {
        width: 68px;
        height: 8px;
        margin-top: 10px;
        border-radius: 4px;
      }

      .process_tap_act {
        background-color: #ff8737;
      }
    }
  }

  .process_tap_cases {
    width: 605px;
    height: 100%;
    overflow-y: auto;
    padding-left: 50px;
    padding-right: 48px;
    // padding-bottom: 1000px;
    .box {
      height: 1000px;
      // background-color: pink;
    }
    .process_tap_case {
      display: flex;
      height: 86px;
      align-items: center;
      margin-bottom: 40px;
      justify-content: space-between;

      img {
        width: 74px;
        height: 86px;
        margin-right: 24px;
      }

      .process_tap_case1 {
        // margin-right: 78px;
        width: 500px;

        // background-color: pink;
        .process_tap_case1_1 {
          font-size: 24px;
          font-weight: 700;
          color: #1c1c1e;
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .text {
            // width: 84px;
            padding: 0 12px;
            height: 32px;
            background: #feedbc;
            border-radius: 6px;
            font-weight: 500;
            display: flex;
            font-size: 20px;
            color: #fc8800;
            justify-content: center;
            align-items: center;
            margin-left: 14px;
          }
        }

        .process_tap_case1_2 {
          font-size: 20px;
          font-weight: 500;
          color: #9a9a9a;
          display: flex;
          align-items: center;

          .t_sp {
            background-color: #feedbc;
            border-radius: 6px;
            font-weight: 500;
            font-size: 17px;
            color: #fc8800;
            margin-right: 12px;
            padding-left: 10px;
            padding-right: 10px;
          }

          .t_span {
            color: #ff8737;
            background-color: #fff;
          }
        }

        .process_k {
          display: flex;

          .process_k1 {
            margin-right: 0;
          }

          > div {
            display: flex;
            align-items: center;
            font-weight: 500;
            margin-right: 14px;
            > div {
              display: flex;
              align-items: center;
              img {
                margin-right: 8px;
              }
            }
            .h1 {
              width: 36px;
              height: 40px;
              margin-right: 0;
            }

            .h2 {
              width: 36px !important;
              height: 36px !important;
              margin-right: 0;
            }

            .h3 {
              width: 36px;
              height: 36px;
              margin-right: 0;
            }
          }
        }

        .process_tap_case1_21 {
          font-size: 20px;
          font-weight: 500;
          color: #f7b909;
        }
      }

      .process_tap_case2 {
        font-size: 24px;
        font-weight: 500;
        color: #fa8f11;
        // background-color: aqua;
        flex: 1;
        // margin-right: 22px;
      }

      .process_tap_case3 {
        font-size: 24px;
        font-weight: 500;
        // color: #FA8F11;
        width: 134px;
        height: 49px;
        border-radius: 18px;
        background-color: #c7c7c7;
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        // text-align: center;
        flex-shrink: 0;
        line-height: 49px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
          line-height: 24px;
          // position: absolute;
          // white-space: n;
          // vertical-align:middle;
          // border: 1px solid #000;
        }

        div {
          width: 138px;
          height: 60px;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .process_tap_case3_loging {
        // background:#FF8737;
        // box-shadow: 0px 6px 12px 2px rgba(255, 166, 62, 0.66);
        // opacity: 0.59;
        border: 2px solid #ff8737;
        background-color: #fff;
        color: #ff8737;
      }

      .process_tap_case3_act {
        background: #ff8737;
      }
    }
  }
}
.bg1 {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(112, 112, 112, 0.27);
  z-index: 9999;
  .bm1 {
    width: 464px !important;
    height: 386px !important;
    margin-top: -193px !important;
    margin-left: -232px !important;
    border-radius: 22px !important;
    .s {
      width: 71px;
      height: 71px;
      margin-left: 199px;
    }
    .s1 {
      font-weight: 500;
      font-size: 23px;
      color: #1c1c1e;
      text-align: center;
      margin-top: 22px;
      margin-bottom: 7px;
    }
    .s2 {
      font-weight: 500;
      font-size: 19px;
      color: #191918;
      text-align: center;
    }
  }
  .bm {
    width: 515px;
    height: 463px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -231px;
    margin-left: -257px;
    background: url("../assets/img/buy/loginBg.png") no-repeat top left;
    background-size: cover;
    padding-top: 81px;
    .bm_span {
      font-size: 16px;
      color: RGBA(191, 191, 191, 1);
      position: absolute;
      top: 28px;
      right: 28px;
    }
    .bm_nav {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 25px;
      > div {
        font-weight: 500;
        font-size: 20px;
        color: #191918;
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          width: 46px;
          height: 4px;
          background: #f9c728;
          border-radius: 6px 6px 6px 6px;
          margin-top: 4px;
        }
      }
      .flod {
        font-weight: 700;
      }
    }
  }
  .phone_ {
    margin-left: 77px !important;
  }
  .phone {
    width: 311px;
    height: 50px;
    background: #f9f9f9;
    border-radius: 27px 27px 27px 27px;
    margin-left: 97px;
    margin-bottom: 16px;
    position: relative;
    input {
      width: 311px;
      height: 50px;
      background: #f9f9f9;
      border-radius: 27px 27px 27px 27px;
      padding-left: 60px;
    }
    .ph1 {
      position: absolute;
      font-weight: 500;
      font-size: 16px;
      color: #191918;
      top: 14px;
      left: 20px;
    }
    .ph2 {
      position: absolute;
      top: 14px;
      right: 21px;
      font-weight: 500;
      font-size: 16px;
      color: #f9c728;
    }
  }
  .phone1 {
    padding-left: 20px !important;
  }
  .bm_btn {
    width: 311px;
    height: 50px;
    background: #f9c728;
    border-radius: 27px 27px 27px 27px;
    margin-left: 97px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    margin-bottom: 24px;
  }
  .bm_btn1 {
    margin-top: 30px;
    margin-left: 77px !important;
  }
  .bm_f {
    font-weight: 300;
    font-size: 12px;
    color: rgba(25, 25, 24, 1);
    text-align: center;
    span {
      color: #f9c728;
    }
  }
  .main_c1 {
    font-weight: 700;
    font-size: 18px;
    color: #191918;
    display: flex;
    align-items: center;
    margin-left: 32px;
    margin-top: 13px;
    span {
      height: 19px;
      font-weight: 300;
      font-size: 12px;
      color: #ffffff;
      line-height: 19px;
      padding: 0 6px;
      background: #f8810a;
      border-radius: 4px 4px 4px 4px;
      margin-right: 6px;
    }
    span:nth-child(1) {
      margin-left: 16px;
    }
  }

  .main_c2 {
    font-weight: 300;
    font-size: 14px;
    color: rgba(81, 81, 81, 0.86);
    margin-left: 32px;
    margin-top: 10px;
  }
  .main_case1 {
    display: flex;
    height: 95px;
    display: flex;
    align-items: center;
    margin-bottom: 57px;
    position: relative;
    img {
      width: 95px;
      height: 95px;
      margin-left: 15px;
      margin-right: 24px;
    }
    .main_c4 {
      width: 94px;
      height: 36px;
      border-radius: 22px;
      background: linear-gradient(
          to right,
          rgba(250, 138, 10, 1) 0%,
          rgba(245, 72, 7, 1)
        )
        100%;
      box-shadow: 0px 2px 10px 1px rgba(255, 83, 0, 0.55);
      font-weight: 400;
      text-align: center;
      line-height: 36px;
      font-size: 14px;
      color: #ffffff;
      position: absolute;
      top: 30px;
      right: 35px;
    }
    .main_c1 {
      font-weight: 700;
      font-size: 16px;
      color: #191918;
    }
    .main_c3 {
      font-weight: 300;
      font-size: 12px;
      color: rgba(170, 170, 170, 0.86);
      margin-top: 8px;
      margin-bottom: 8px;
      span {
        margin-right: 8px;
      }
    }
    .main_c2 {
      width: 60px;
      height: 17px;
      background: #f8bf0a;
      border-radius: 4px 4px 4px 4px;
      text-align: center;
      line-height: 17px;
      font-weight: 300;
      font-size: 10px;
      color: #ffffff;
    }
    .main_c2_ {
      font-weight: 300;
      font-size: 12px;
      color: rgba(170, 170, 170, 0.86);
    }
  }
}
.main_case1 {
  display: flex;
  height: 95px;
  display: flex;
  align-items: center;
  margin-bottom: 57px;
  position: relative;
  img {
    width: 95px;
    height: 95px;
    margin-left: 15px;
    margin-right: 24px;
  }
  .main_c4 {
    width: 94px;
    height: 36px;
    border-radius: 22px;
    background: linear-gradient(
        to right,
        rgba(250, 138, 10, 1) 0%,
        rgba(245, 72, 7, 1)
      )
      100%;
    box-shadow: 0px 2px 10px 1px rgba(255, 83, 0, 0.55);
    font-weight: 400;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    top: 30px;
    right: 35px;
  }
  .main_c1 {
    font-weight: 700;
    font-size: 16px;
    color: #191918;
  }
  .main_c3 {
    font-weight: 300;
    font-size: 12px;
    color: rgba(170, 170, 170, 0.86);
    margin-top: 8px;
    margin-bottom: 8px;
    span {
      margin-right: 8px;
    }
  }
  .main_c2 {
    width: 60px;
    height: 17px;
    background: #f8bf0a;
    border-radius: 4px 4px 4px 4px;
    text-align: center;
    line-height: 17px;
    font-weight: 300;
    font-size: 10px;
    color: #ffffff;
  }
  .main_c2_ {
    font-weight: 300;
    font-size: 12px;
    color: rgba(170, 170, 170, 0.86);
  }
  .main_cq {
    // width: 41px;
    display: flex;
    span {
      height: 17px;
      background: #f8bf0a;
      border-radius: 4px 4px 4px 4px;
      padding: 0 10px;
      font-weight: 300;
      font-size: 10px;
      line-height: 17px;
      color: #ffffff;
      margin-right: 5px;
    }
  }
}
.bg_m {
  width: 317px;
  height: 58px;
  position: relative;
  margin-top: 30px;
  .b1 {
    width: 317px;
    height: 58px;
  }
  .b2 {
    width: 66px;
    height: 23px;
    position: absolute;
    top: 18px;
    right: 14px;
  }
}
.main_case1 {
  display: flex;
  height: 95px;
  display: flex;
  align-items: center;
  margin-bottom: 57px;
  position: relative;
  img {
    width: 95px;
    height: 95px;
    margin-left: 15px;
    margin-right: 24px;
  }
  .main_c4 {
    width: 94px;
    height: 36px;
    border-radius: 22px;
    background: linear-gradient(
        to right,
        rgba(250, 138, 10, 1) 0%,
        rgba(245, 72, 7, 1)
      )
      100%;
    box-shadow: 0px 2px 10px 1px rgba(255, 83, 0, 0.55);
    font-weight: 400;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    color: #ffffff;
    position: absolute;
    top: 30px;
    right: 35px;
  }
  .main_c1 {
    font-weight: 700;
    font-size: 16px;
    color: #191918;
  }
  .main_c3 {
    font-weight: 300;
    font-size: 12px;
    color: rgba(170, 170, 170, 0.86);
    margin-top: 8px;
    margin-bottom: 8px;
    span {
      margin-right: 8px;
    }
  }
  .main_c2 {
    width: 60px;
    height: 17px;
    background: #f8bf0a;
    border-radius: 4px 4px 4px 4px;
    text-align: center;
    line-height: 17px;
    font-weight: 300;
    font-size: 10px;
    color: #ffffff;
  }
  .main_c2_ {
    font-weight: 300;
    font-size: 12px;
    color: rgba(170, 170, 170, 0.86);
  }
  .main_cq {
    // width: 41px;
    display: flex;
    span {
      height: 17px;
      background: #f8bf0a;
      border-radius: 4px 4px 4px 4px;
      padding: 0 10px;
      font-weight: 300;
      font-size: 10px;
      line-height: 17px;
      color: #ffffff;
      margin-right: 5px;
    }
  }
}
.details_buy {
  width: 625px;
  height: 819px;
  background: #ffffff;
  box-shadow: 0px 6px 22px 1px rgba(0, 0, 0, 0.09);
  border-radius: 19px 19px 19px 19px;
  position: absolute;
  top: 53px;
  left: 43px;
  z-index: 100;
  padding-top: 26px;
  .buy_del {
    margin-left: 27px;
    width: 30px;
    height: 30px;
    margin-bottom: 32px;
  }
  .details_buy3 {
    margin-top: 34px;
    padding-left: 42px;
    .buy_p1 {
      font-weight: 700;
      font-size: 19px;
      color: #1c1c1e;
      margin-bottom: 16px;
    }
    .buy_d1 {
      width: 300px;
      font-weight: 300;
      font-size: 13px;
      color: #8a8a8a;
      margin-bottom: 18px;
    }
    .buy_d2 {
      display: flex;
      flex-wrap: wrap;
      img {
        width: 119px;
        height: 102px;
        border-radius: 12px;
        background-color: rgba(245, 245, 245, 1);
        margin-right: 21px;
        margin-bottom: 20px;
      }
    }
    .buy_p2 {
      font-weight: 300;
      font-size: 13px;
      color: #8a8a8a;
      margin-top: 22px;
      margin-bottom: 18px;
    }
    .buy_p3 {
      font-weight: 700;
      font-size: 12px;
      color: #1c1c1e;
    }
  }
  .details_buy2 {
    font-weight: 300;
    font-size: 16px;
    color: #1c1c1e;
    position: relative;
    padding-left: 42px;
    p {
      margin-bottom: 16px;
      span {
        color: rgba(246, 105, 108, 1);
      }
    }
    div {
      width: 116px;
      height: 44px;
      border-radius: 22px;
      background: linear-gradient(
        to right,
        rgba(250, 138, 10, 1) 0%,
        rgba(245, 72, 7, 1) 100%
      );
      font-weight: 400;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      line-height: 44px;
      box-shadow: 0px 2px 10px 1px rgba(255, 83, 0, 0.55);
      position: absolute;
      top: 54px;
      right: 36px;
    }
  }
  .details_buy1 {
    width: 625px;
    height: 119px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    > span {
      color: rgba(250, 130, 10, 1);
      font-size: 17px;
      position: absolute;
      top: 0;
      right: 36px;
    }
    img {
      width: 119px;
      height: 119px;
      margin-left: 42px;
      margin-right: 20px;
    }
    > div {
      p {
        font-weight: 700;
        font-size: 22px;
        color: #1c1c1e;
        margin-bottom: 20px;
      }
      div {
        font-weight: 500;
        font-size: 20px;
        color: #f6696c;
        span {
          font-size: 16px;
        }
      }
    }
  }
}
.i_share {
  position: absolute;
  top: 0;
  right: 23px;
  img {
    width: 42px;
    height: 42px;
    margin-right: 18px;
  }
}
.i_btn {
  display: flex;
  justify-content: center;
  margin-bottom: 41px;
  .i_btn1 {
    width: 293px;
    height: 44px;
    border-radius: 22px;
    border: 1px solid rgba(249, 125, 9, 1);
    font-weight: 500;
    font-size: 16px;
    color: #f97d09;
    text-align: center;
    line-height: 44px;
    margin-right: 30px;
  }
  .i_btn2 {
    width: 293px;
    height: 44px;
    border-radius: 22px;
    background: rgba(249, 125, 9, 1);
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 44px;
    // margin-left: 30px;
  }
}
.hot_ {
  width: 351px;
  height: 238px;
  background: #f5f5f5;
  border-radius: 28px 28px 28px 28px;
  // display: flex;
  // align-items: center;
  padding: 20px 24px;
  margin-top: 20px;
  p {
    font-weight: 700;
    font-size: 12px;
    color: rgba(110, 110, 110, 0.86);
    margin-bottom: 3px;
    text-align: center;
    line-height: 18px;
  }
  span {
    cursor: pointer;
    a {
      color: rgba(110, 110, 110, 0.86);
    }
  }
}
.mine_pic1_tap11 {
  position: absolute;
  left: -93px;
  bottom: -158px;
  width: 312px;
  height: 118px;
  z-index: 9999;
  background: #747474;
  box-shadow: 0px 6px 36px 2px rgba(0, 0, 0, 0.39);
  border-radius: 18px 18px 18px 18px;
  opacity: 0.91;
  background-color: rgba(116, 116, 116, 1);
  padding-top: 32px;
  padding-left: 26px;
  transform: scale(0.5);

  .mine_pic1_tap11_1 {
    font-weight: 600;
    font-size: 24px;
    color: #ff9c2c;
    margin-bottom: 8px;
  }

  .mine_pic1_tap11_2 {
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
  }
}

.mine_pic1_tap12 {
  transform: scale(0.5);
  position: absolute;
  left: -24px;
  bottom: -231px;
  width: 270px;
  height: 216px;
  background: #747474;
  box-shadow: 0px 6px 36px 2px rgba(0, 0, 0, 0.39);
  border-radius: 18px 18px 18px 18px;
  opacity: 0.91;
  background-color: rgba(116, 116, 116, 1);
  padding-top: 32px;
  padding-left: 26px;

  overflow-y: auto;
  z-index: 9999;
  .mine_pic1_tap11_1 {
    font-weight: 600;
    font-size: 24px;
    color: #ff9c2c;
    margin-bottom: 8px;
  }

  .mine_pic1_tap11_2 {
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 20px;
  }
}

.mine_pic1_tap13 {
  transform: scale(0.5);
  position: absolute;
  z-index: 9999;
  left: 21px;
  bottom: -186px;
  width: 302px;
  height: 156px;
  background: #747474;
  box-shadow: 0px 6px 36px 2px rgba(0, 0, 0, 0.39);
  border-radius: 18px 18px 18px 18px;
  opacity: 0.91;
  background-color: rgba(116, 116, 116, 1);
  padding-top: 32px;
  padding-left: 26px;
  overflow-y: auto;

  .mine_pic1_tap11_1 {
    font-weight: 600;
    font-size: 24px;
    color: #ff9c2c;
    margin-bottom: 10px;
  }

  .mine_pic1_tap11_2 {
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 10px;
  }
}
</style>