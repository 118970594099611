<template>
      <transition name="el-fade-in">
  <div class="q">
    <div class="main">
      <div class="q_h">开服 <span style="cursor: pointer;" @click="close">X</span></div>
      <div class="history" v-if="!flagHistory" @click="goHistory">查看历史开服</div>
      <div class="server_main">
        <dl>
          <dd v-for="(item, i) in List" :key="i">
            <div class="block">
              <span class="time">{{ item.star_time }}</span>
              <p>{{ item.server_name }}</p>
            </div>
            <div class="right">
              <span v-if="!item.is_server" class="open">已开服</span>
              <span v-else-if="item.user_server" class="open">已预约</span>
              <span v-else class="order" style="cursor: pointer;" @click="orderServer(item.id,i)" >预约</span>
            </div>
          </dd>
        </dl>
      </div>
      <div class="q_btn">
        <img src="../assets/img/z1.png" alt="" v-if="page == 1 || page == 0" />
        <img src="../assets/img/z2.png" style="cursor: pointer;" alt="" v-if="page > 1" @click="toPre" />
        <div>{{ page }} / {{ tpage }}</div>
        <img src="../assets/img/y1.png" alt="" v-if="page == tpage" />
        <img
         style="cursor: pointer;"
          src="../assets/img/y2.png"
          alt=""
          @click="toNext"
          v-if="page != tpage"
        />
      </div>
    </div>
  </div>
</transition>
</template>
<script>
export default {
  props: ["gid","flagHistory"],
  data() {
    return {
      List: "",
      tpage: 1,
      page: 1,
      showHistory:false,
    };
  },
  methods: {
    goHistory(){
      this.showHistory = true;
      this.getServerLaunch();
    },
    close() {
      this.showHistory = false;
      this.$emit("closeQuan");
    },
    orderServer(id,index){
      this.$http
        .post("pc/game/reservation_server", {
          ids: id
        })
        .then((res) => {
          console.log(res);
          if (res.code == 20000) {
            this.$set(this.List[index], 'user_server',true)
            this.$msg.success(res.msg);
          }else{
            this.$msg.error('预约失败')
          }
        });
    },
    getServerLaunch(){
      if(this.flagHistory || this.showHistory){
        this.$http
        .post("pc/game/history_server", {
          game_id: this.gid,
          page: this.page,
        })
        .then((res) => {
          console.log("历史开服",res);
          if (res.code == 20000) {
            this.List = res.data.data;
            this.tpage = res.data.last_page;
            if (this.tpage == 0) {
              this.page = 0;
            }
          }
        });
      }else{
        this.$http
        .post("pc/game/server_list", {
          game_id: this.gid,
          page: this.page,
        })
        .then((res) => {
          console.log("区服",res);
          if (res.code == 20000) {
            this.List = res.data.data;
            this.tpage = res.data.last_page;
            if (this.tpage == 0) {
              this.page = 0;
            }
          }
        });
      }
      
    },
    toPre() {
      this.page = this.page - 1;
      this.getServerLaunch();
    },
    toNext() {
      this.page = this.page + 1;
      this.getServerLaunch();
    },
  },
  mounted() {
    this.page = 1;
    this.getServerLaunch();
  },
};
</script>
<style scoped lang="scss">
.q {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(112, 112, 112, 0.27);
  top: 0;
  left: 0;
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  .main {
    width: 796px;
    height: 893px;
    background: #ffffff;
    box-shadow: 0px 3px 22px 1px rgba(0, 0, 0, 0.16);
    border-radius: 22px 22px 22px 22px;
    padding-top: 28px;
    position: relative;
  }
  .q_h {
    text-align: center;
    position: relative;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
    color: #191918;
    span {
      position: absolute;
      font-size: 20px;
      color: RGBA(191, 191, 191, 1);
      right: 42px;
      top: 0;
    }
  }
  .q_m {
    padding: 0 33px;
  }
  .claimrecord_m {
    width: 343px;
    height: 88px;
    float: left;
    background: #fff8e9;
    border-radius: 7px;
    margin-right: 11px;
    margin-left: 11px;
    display: flex;
    margin-bottom: 19px;
    align-items: center;
    .claimrecord_l {
      width: 100px;
      height: 88px;
      border-right: 1px dashed #ff8431;
      position: relative;
      // padding-top: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .claimrecord_l1 {
        font-weight: 700;
        font-size: 27px;
        color: #ff1d1d;
        text-align: center;

        span {
          font-weight: 700;
          font-size: 17px;
          color: #ff1d1d;
        }
      }

      .claimrecord_l2 {
        font-weight: 500;
        font-size: 12px;
        color: #010302;
        text-align: center;
      }
    }

    .claimrecord_l::before {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      position: absolute;
      background-color: #fff;
      top: -8px;
      right: -8px;
      z-index: 99;
    }

    .claimrecord_l::after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background-color: #fff;
      position: absolute;
      bottom: -8px;
      right: -8px;
      z-index: 99;
    }

    .claimrecord_r {
      // height: 176px;
      flex: 1;
      // padding-top: 32px;
      padding-left: 13px;
      position: relative;

      .claimrecord_r1 {
        font-weight: 700;
        font-size: 15px;
        color: #010302;
      }

      .claimrecord_r2 {
        font-weight: 500;
        font-size: 13px;
        color: #010302;
        margin-top: 4px;
        margin-bottom: 5px;
      }

      .claimrecord_r3 {
        font-weight: 500;
        font-size: 13px;
        color: #707070;
      }
    }
  }
}
.server_main {
  padding: 0 33px;
  width: 100%;
  margin-top: 30px;
  dl {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    dd {
      width: 335px;
      height: 69px;
      background: #f9f9f9;
      padding: 13px 30px;
      margin-bottom: 19px;
      .block {
        float: left;
      }
      .right {
        float: right;
        line-height: 42px;
        .open {
          color: #ff6931;
          font-weight: 700;
        }
        .order {
          width: 58px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #ff6931;
          border-radius: 15px;
          display: inline-block;
          color: white;
        }
      }
      .time {
        color: #1c1c1e;
        font-weight: 700;
        margin-bottom: 3px;
      }
      p {
        color: #98969b;
      }
    }
  }
}
.claimrecord_case_text3 {
  margin-left: 8px;
  position: absolute;
  right: 12px;
  top: 22px;
  font-size: 12px;
  margin-right: 12x;
  flex-shrink: 0;
  background-color: rgba(253, 171, 64, 1);
  width: 60px;
  height: 25px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  // background: #FFFFFF;
  line-height: 25px;
  text-align: center;
  border-radius: 14px;
  opacity: 1;
  // position: relative;

  view {
    position: absolute;
    width: 60px;
    height: 25px;
    top: 0;
    left: 0;
  }
}
.claimrecord_case_text3_act {
  font-weight: 500;
  background-color: transparent;

  font-size: 14px;
  color: #fdab40;
}
.q_btn {
  //   background: #acc;
  position: absolute;
  left: 0;
  bottom: 42px;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 60px;
    height: 28px;
    font-weight: 300;
    line-height: 28px;
    font-size: 20px;
    color: #ffc844;
    text-align: center;
  }
  img {
    width: 18px;
    height: 18px;
  }
}
.history{
  text-align: center;
  color: gray;
  cursor: pointer;
  // &::after{
  //   content: '';
  //   width: 100px;
  //   height: 2px;
  //   background: gray;
  //   display: block;
  //   margin: 0 auto;
  //   border-radius: 5px;
  //   margin-top: 5px;
  // }
}
</style>
