<template>
  <div id="app" style="height: 100%">
    <!-- <nav>
      <router-link to="/index"></router-link>
    </nav> -->
    <NavTap v-show="$store.state.flagNav.flag"></NavTap>
    <router-view />
  </div>
</template>
<script>
import NavTap from "./components/NavTap.vue";
export default {
  data() {
    return {
      flag: true,
    };
  },
  components: {
    NavTap,
  },
  mounted() {
    document.title = this.$store.state.title;
    this.flag = this.$store.state.flagNav.flag;
  },
  updated() {
    console.log("kk11111111111", this.$store.state.flagNav.flag);
  },
};
</script>
<style>
/*每个页面公共css */
html,
body,
#app {
  background-color: #fff;
  /* 设置状态栏背景颜色为红色 */
  height: 100%;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
  -webkit-appearance: none;
  background: transparent;
}
.el-carousel__container {
  height: 100% !important;
}
/* 	清除默认样式，初始化样式*/
* {
  margin: 0;

  padding: 0;

  box-sizing: border-box;
  -webkit-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;

  color: #333;
}

input,
button {
  outline: none;

  border: 0;
}

.fl {
  float: left;
}

/* .fr {

		float: right;

	} */

.clearfix::before,
.clearfix::after {
  content: "";

  display: table;
}

.clearfix::after {
  clear: both;
}

/* 
	body {
	
	    font: 16px/1.5 微软雅黑;
	
	    color: #333; */
/* height: 100%; */

/* 	} */

em,
i {
  font-style: normal;
}

img {
  list-style: none;
}
</style>
