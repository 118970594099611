import Vue from 'vue'
import ElementUI from 'element-ui';
import { Message } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import store from './store';
import http from './utils/http';
Vue.config.productionTip = false
Vue.use(ElementUI);
// 将 Axios 实例挂载到 Vue 原型上
Vue.prototype.$http = http;
Vue.prototype.$msg=Message
new Vue({
  router,
  store,
  beforeCreate(){

    Vue.prototype.$bus = this    //安装全局事件总线，this就是当前应用的vm

 },
  render: function (h) { return h(App) }
}).$mount('#app')
