<template>
  <transition name="el-fade-in">
  <div class="q">
    <div class="main">
      <div class="q_h">优惠券 <span style="cursor: pointer;" @click="close">X</span></div>
      <div class="tabs">
        <span style="cursor: pointer;" :class="flagType == 0 ? 'active' : ''" @click="showList(0)"
          >满减劵</span
        >
        <span style="cursor: pointer;" :class="flagType == 1 ? 'active' : ''" @click="showList(1)"
          >代金券</span
        >
      </div>
      <div class="q_m">
        <div
          class="claimrecord_m"
          :class="item.status > 0 ? 'claimrecord_gray' : ''"
          v-for="(item, index) in List"
          :key="index"
        >
          <div
            class="claimrecord_l"
            :style="item.status > 0 ? 'border-right:2px dashed #5D5C5B;' : ''"
            :class="item.status > 0 ? 'claimrecord_gray' : ''"
          >
            <div
              class="claimrecord_l1"
              :class="item.status > 0 ? 'claimrecord_gray' : ''"
            >
              <span :class="item.status > 0 ? 'claimrecord_gray' : ''">¥</span
              >{{ item.deduction_price }}
            </div>
            <div
              class="claimrecord_l2"
              :class="item.status > 0 ? 'claimrecord_gray' : ''"
            >
              满{{ item.meet_price }}元可用
            </div>
          </div>
          <div
            class="claimrecord_r"
            :class="item.status > 0 ? 'claimrecord_gray' : ''"
          >
            <div
              class="claimrecord_r1"
              :class="item.status > 0 ? 'claimrecord_gray' : ''"
            >
              {{ item.game_name }}
            </div>
            <div
              class="claimrecord_r2"
              :class="item.status > 0 ? 'claimrecord_gray' : ''"
            >
              {{ item.ticket_name }}
            </div>
            <div
              class="claimrecord_r3"
              :class="item.status > 0 ? 'claimrecord_gray' : ''"
            >
              有效期:{{ item.end_time }}
            </div>
            <img src="../assets/img/sp_die.png" class="used" alt="" v-if=" item.status==1" />
            <img src="../assets/img/sp_used.png" class="used" alt="" v-if=" item.status==2" />
          </div>
        </div>
      </div>
      <div class="q_btn">
        <img src="../assets/img/z1.png" alt="" v-if="page == 1 || page == 0" />
        <img src="../assets/img/z2.png" style="cursor: pointer;" alt="" v-if="page > 1" @click="toPre" />
        <div>{{ page }} / {{ tpage }}</div>
        <img src="../assets/img/y1.png" alt="" v-if="page == tpage" />
        <img
         style="cursor: pointer;"
          src="../assets/img/y2.png"
          alt=""
          @click="toNext"
          v-if="page != tpage"
        />
        <!-- <img src="" alt="" /> -->
      </div>
    </div>
  </div>
</transition>
</template>
<script>
// import { createLogger } from "vuex";
export default {
  props: ["gid"],
  data() {
    return {
      List: "",
      tpage: 1,
      page: 1,
      flagType: 0,
    };
  },
  methods: {
    showList(e) {
      if (this.flagType != e) {
        this.flagType = e;
        this.page = 1;
        this.getTicket();
      }
    },
    getTicket() {
      if (this.flagType == 0) {
        this.$http
          .post("pc/User/cp_ticket_list", {
            game_id: this.gid,
            page: this.page,
          })
          .then((res) => {
            console.log(res);
            if (res.code == 20000) {
              this.List = res.data.data;
              this.tpage = res.data.last_page;
              if (this.tpage == 0) {
                this.page = 0;
              }
            }
          });
      }else if(this.flagType == 1){
        this.$http
          .post("pc/User/ticket_list", {
            game_id: this.gid,
            page: this.page,
          })
          .then((res) => {
            console.log(res);
            if (res.code == 20000) {
              this.List = res.data.data;
              this.tpage = res.data.last_page;
              if (this.tpage == 0) {
                this.page = 0;
              }
            }
          });
      }
    },
    close() {
      this.$emit("closeQuan");
    },
    toPre() {
      this.page = this.page - 1;
      this.getTicket();
    },
    receive_ticket(ticket_id) {
      this.page = 1;
      this.$http
        .post("pc/Game/receive_ticket", {
          id: ticket_id,
        })
        .then((res) => {
          //   console.log(res);
          if (res.code == 20000) {
            this.$msg.success("领取成功");
            this.$http
              .post("pc/Game/cp_ticket_list", {
                game_id: this.gid,
                page: this.page,
              })
              .then((res) => {
                console.log(res);
                if (res.code == 20000) {
                  this.List = res.data.data;
                  this.tpage = res.data.last_page;
                  if (this.tpage == 0) {
                    this.page = 0;
                  }
                  //   console.log(res.data, "ppppp");
                }
              });
          }
        });
    },
    toNext() {
      this.page = this.page + 1;
      this.getTicket();
    },
  },
  mounted() {
    this.page = 1;
    console.log("ooooooo");
    this.getTicket();
  },
};
</script>
<style scoped lang="scss">
.q {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(112, 112, 112, 0.27);
  top: 0;
  left: 0;
  z-index: 99999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  .main {
    width: 796px;
    height: 893px;
    background: #ffffff;
    box-shadow: 0px 3px 22px 1px rgba(0, 0, 0, 0.16);
    border-radius: 22px 22px 22px 22px;
    padding-top: 28px;
    position: relative;
  }
  .q_h {
    text-align: center;
    position: relative;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 30px;
    color: #191918;
    span {
      position: absolute;
      font-size: 20px;
      color: RGBA(191, 191, 191, 1);
      right: 42px;
      top: 0;
    }
  }
  .q_m {
    padding: 0 33px;
    .used{
      width: 70px;
      height: 70px;
      display: block;
      position: absolute;
      top: -5px;
      right: 10px;
    }
  }
  .claimrecord_m {
    width: 343px;
    height: 88px;
    float: left;
    background: #fff8e9;
    border-radius: 7px;
    margin-right: 11px;
    margin-left: 11px;
    display: flex;
    margin-bottom: 19px;
    align-items: center;
    .claimrecord_l {
      width: 100px;
      height: 88px;
      border-right: 1px dashed #ff8431;
      position: relative;
      // padding-top: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .claimrecord_l1 {
        font-weight: 700;
        font-size: 27px;
        color: #ff1d1d;
        text-align: center;

        span {
          font-weight: 700;
          font-size: 17px;
          color: #ff1d1d;
        }
      }

      .claimrecord_l2 {
        font-weight: 500;
        font-size: 12px;
        color: #010302;
        text-align: center;
      }
    }

    .claimrecord_l::before {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      position: absolute;
      background-color: #fff;
      top: -8px;
      right: -8px;
      z-index: 99;
    }

    .claimrecord_l::after {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background-color: #fff;
      position: absolute;
      bottom: -8px;
      right: -8px;
      z-index: 99;
    }

    .claimrecord_r {
      // height: 176px;
      flex: 1;
      // padding-top: 32px;
      padding-left: 13px;
      position: relative;

      .claimrecord_r1 {
        font-weight: 700;
        font-size: 15px;
        color: #010302;
      }

      .claimrecord_r2 {
        font-weight: 500;
        font-size: 13px;
        color: #010302;
        margin-top: 4px;
        margin-bottom: 5px;
      }

      .claimrecord_r3 {
        font-weight: 500;
        font-size: 13px;
        color: #707070;
      }
    }
  }
  .claimrecord_gray{
    background-color: RGBA(244, 244, 244, 1) !important;
		color: rgba(162, 162, 162, 1) !important;
    border-radius: 10px;
  }
}
.claimrecord_case_text3 {
  margin-left: 8px;
  position: absolute;
  right: 12px;
  top: 22px;
  font-size: 12px;
  margin-right: 12x;
  flex-shrink: 0;
  background-color: rgba(253, 171, 64, 1);
  width: 60px;
  height: 25px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  // background: #FFFFFF;
  line-height: 25px;
  text-align: center;
  border-radius: 14px;
  opacity: 1;
  // position: relative;

  view {
    position: absolute;
    width: 60px;
    height: 25px;
    top: 0;
    left: 0;
  }
}
.claimrecord_case_text3_act {
  font-weight: 500;
  background-color: transparent;

  font-size: 14px;
  color: #fdab40;
}
.q_btn {
  //   background: #acc;
  position: absolute;
  left: 0;
  bottom: 42px;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 60px;
    height: 28px;
    font-weight: 300;
    line-height: 28px;
    font-size: 20px;
    color: #ffc844;
    text-align: center;
  }
  img {
    width: 18px;
    height: 18px;
  }
}
.tabs {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-items: center;
  justify-content: space-around;
  margin-bottom: 30px;
  span {
    cursor: pointer;
    width: 150px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    &.active {
      &::after {
        content: "";
        background: #fdab40;
        // width: 30px;
        height: 5px;
        display: block;
        border-radius: 5px;
      }
    }
  }
}
</style>
