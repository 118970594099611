<template>
  <div class="home">
    <div class="nav">
      <!-- <img src="../assets/img/nav.png" alt="" srcset="" /> -->
      <div class="nav1">
        <img
          src="../assets/img/index/logo.png"
          style="cursor: pointer"
          @click="toHome"
          alt=""
          srcset=""
          class="logo"
        />
      </div>
    </div>
    <!-- <div class="main" @wheel="scrollBottom"> -->
    <div class="main" @wheel="handleScroll">
      <el-carousel
        ref="carousel"
        height="200px"
        direction="vertical"
        :initial-index="0"
        :autoplay="false"
        :loop="false"
        indicator-position="none"
        @change="onChange"
      >
        <el-carousel-item>
          <div class="swiper-slide main1">
            <!-- <el-image
              style="width: 100%; height: 100%"
              src="../assets/img/Web1.png"
              fit="fill"
            ></el-image> -->
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="swiper-slide main2" style="">
            <!-- <el-image
              style="width: 100%; height: 100%"
              src="../assets/img/Web1.png"
              :fit="fill"
            ></el-image> -->
            <img src="../assets/img/s1.png" alt="" />
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="swiper-slide main3">
            <img src="../assets/img/s2.png" alt="" />
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="swiper-slide main4">
            <img src="../assets/img/s3.png" alt="" />
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="swiper-slide main5">
            <div>
              <div>
                <div class="sheep_c" v-show="i1">
                  <p class="p1">小羊一级</p>
                  <p class="p2">【初入江湖】</p>
                  <div class="sheep_c1">
                    <p>生产羊托币比例: 20%</p>
                    出入羊圈的萌新小羊，想要成就一番事业，怀揣着羊王的梦想。
                    <img src="../assets/img/bottom.png" alt="" />
                  </div>
                </div>
                <img
                  src="../assets/img/sheep/m1.png"
                  alt=""
                  srcset=""
                  class="img1"
                  @mouseenter="en1(1)"
                  @mouseleave="en2(1)"
                />
              </div>
              <div>
                <div class="sheep_c" v-show="i2">
                  <p class="p1">小羊二级</p>
                  <p class="p2">【生财有道】</p>
                  <div class="sheep_c1">
                    <p>生产羊托币比例: 30%</p>
                    在羊圈中通过历练自己，已经取得一些生财门道。
                    <img src="../assets/img/bottom.png" alt="" />
                  </div>
                </div>
                <img
                  src="../assets/img/sheep/m2.png"
                  alt=""
                  srcset=""
                  class="img2"
                  @mouseenter="en1(2)"
                  @mouseleave="en2(2)"
                />
              </div>
              <div>
                <div class="sheep_c" v-show="i3">
                  <p class="p1">小羊三级</p>
                  <p class="p2">【堆金积玉】</p>
                  <div class="sheep_c1">
                    <p>生产羊托币比例: 40%</p>
                    找到致富途径，逐渐拉开距离，开始堆积财富。
                    <img src="../assets/img/bottom.png" alt="" />
                  </div>
                </div>
                <img
                  src="../assets/img/sheep/m3.png"
                  alt=""
                  srcset=""
                  class="img3"
                  @mouseenter="en1(3)"
                  @mouseleave="en2(3)"
                />
              </div>
              <div>
                <div class="sheep_c" v-show="i4">
                  <p class="p1">小羊四级</p>
                  <p class="p2">【财大气粗】</p>
                  <div class="sheep_c1">
                    <p>生产羊托币比例: 50%</p>
                    已经财富自由，草料随便造，请朋好友串门都多起来了。
                    <img src="../assets/img/bottom.png" alt="" />
                  </div>
                </div>
                <img
                  src="../assets/img/sheep/m4.png"
                  alt=""
                  srcset=""
                  class="img4"
                  @mouseenter="en1(4)"
                  @mouseleave="en2(4)"
                />
              </div>
              <div>
                <div class="sheep_c" v-show="i5">
                  <p class="p1">小羊五级</p>
                  <p class="p2">【家财万贯】</p>
                  <div class="sheep_c1">
                    <p>生产羊托币比例: 55%</p>
                    多了很多慕名的追随者，开始带领羊羊们一起致富。
                    <img src="../assets/img/bottom.png" alt="" />
                  </div>
                </div>
                <img
                  src="../assets/img/sheep/m5.png"
                  alt=""
                  srcset=""
                  class="img5"
                  @mouseenter="en1(5)"
                  @mouseleave="en2(5)"
                />
              </div>
              <div>
                <div class="sheep_c" v-show="i6">
                  <p class="p1">小羊六级</p>
                  <p class="p2">【富甲一方】</p>
                  <div class="sheep_c1">
                    <p>生产羊托币比例: 60%</p>
                    拥有了自己的羊托庄园，又一个羊托集团诞生了。
                    <img src="../assets/img/bottom.png" alt="" />
                  </div>
                </div>
                <img
                  src="../assets/img/sheep/m6.png"
                  alt=""
                  srcset=""
                  class="img6"
                  @mouseenter="en1(6)"
                  @mouseleave="en2(6)"
                />
              </div>
              <div>
                <div class="sheep_c" v-show="i7">
                  <p class="p1">小羊七级</p>
                  <p class="p2">【富可敌国】</p>
                  <div class="sheep_c1">
                    <p>生产羊托币比例: 66%</p>
                    所到之处散发出纯正的王者气息，万人敬仰 王者降临。
                    <img src="../assets/img/bottom.png" alt="" />
                  </div>
                </div>
                <img
                  src="../assets/img/sheep/m7.png"
                  alt=""
                  srcset=""
                  class="img7"
                  @mouseenter="en1(7)"
                  @mouseleave="en2(7)"
                />
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="bottom" @click="nextPic" v-show="!baian">
      <div>下滑了解更多</div>
      <img src="../assets/img/bottom.png" alt="" class="span1" />
    </div>
    <div
      style="
        height: 198px;
        background-color: white;
        font-weight: 300;
        font-size: 14px;
        color: #a1a1a1;
        text-align: center;
        padding-top: 47px;
      "
      v-show="baian"
    >
      <p>
        健康游戏忠告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当
        适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活
      </p>
      <p
        style="
          margin: 20px 0;
          font-weight: 300;
          font-size: 14px;
          color: #a1a1a1;
        "
      >
        <span style="cursor: pointer"
          ><a
            style="font-weight: 300; font-size: 14px; color: #a1a1a1"
            href="https://admin.yangtuoyou.com/jianhu.html"
            >家长监护工程</a
          ></span
        >
        |
        <span style="cursor: pointer"
          ><a
            style="font-weight: 300; font-size: 14px; color: #a1a1a1"
            href="https://admin.yangtuoyou.com/jianhu.html"
            >防沉迷系统</a
          ></span
        >
        |
        <span style="cursor: pointer"
          ><a
            style="font-weight: 300; font-size: 14px; color: #a1a1a1"
            href="https://admin.yangtuoyou.com/jianhu.html"
            >纠纷处理</a
          ></span
        >
        |
        <span style="cursor: pointer"
          ><a
            style="font-weight: 300; font-size: 14px; color: #a1a1a1"
            href="https://admin.yangtuoyou.com/yhxy.html"
            >用户协议</a
          ></span
        >
      </p>
      <p>
        安徽羔羊网络科技有限公司互联网ICP备案：皖ICP备2023010426号-2 |
        网站备案号：皖ICP备19002784号-7 | 皖公网安备：34010402701713号
      </p>
      <p>
        互联网ICP备案ICP备案：皖ICP备19002784号-3 |
        网络文化经营许可证：皖网文（2019）3164-137
      </p>
      <!-- <p>网络文化经营许可证ICP备案：皖网文（2019）3164-137</p> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { Swiper, SwiperSlide } from "swiper/vue";
import { createLogger } from "vuex";
// 自定义节流函数
function throttle(func, delay) {
  console.log(1);
  var prev = Date.now();
  return function () {
    var context = this;
    var args = arguments;
    var now = Date.now();
    if (now - prev >= delay) {
      func.apply(context, args);
      prev = Date.now();
    }
  };
}
export default {
  name: "HomeView",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      flagNav: 5,
      value: true,
      activeIndex: 0,
      // 用于累计滚动量
      scrollAccumulation: 0,
      scrollnum: 0,
      // 触发函数的阈值
      scrollThreshold: 3,
      i1: false,
      i2: false,
      i3: false,
      i4: true,
      i5: false,
      i6: false,
      i7: false,
      baian: false,
    };
  },
  methods: {
    toHome() {
      window.location.href = "/#/";
    },
    en1(e) {
      // console.log(e)
      if (e == 1) {
        this.i1 = true;
        this.i2 = false;
        this.i3 = false;
        this.i4 = false;
        this.i5 = false;
        this.i6 = false;
        this.i7 = false;
      } else if (e == 2) {
        this.i1 = false;
        this.i2 = true;
        this.i3 = false;
        this.i4 = false;
        this.i5 = false;
        this.i6 = false;
        this.i7 = false;
      } else if (e == 3) {
        this.i1 = false;
        this.i2 = false;
        this.i3 = true;
        this.i4 = false;
        this.i5 = false;
        this.i6 = false;
        this.i7 = false;
      } else if (e == 4) {
        this.i1 = false;
        this.i2 = false;
        this.i3 = false;
        this.i4 = true;
        this.i5 = false;
        this.i6 = false;
        this.i7 = false;
      } else if (e == 5) {
        this.i1 = false;
        this.i2 = false;
        this.i3 = false;
        this.i4 = false;
        this.i5 = true;
        this.i6 = false;
        this.i7 = false;
      } else if (e == 6) {
        this.i1 = false;
        this.i2 = false;
        this.i3 = false;
        this.i4 = false;
        this.i5 = false;
        this.i6 = true;
        this.i7 = false;
      } else {
        this.i1 = false;
        this.i2 = false;
        this.i3 = false;
        this.i4 = false;
        this.i5 = false;
        this.i6 = false;
        this.i7 = true;
      }
    },
    en2(e) {
      this.i1 = false;
      this.i2 = false;
      this.i3 = false;
      this.i4 = false;
      this.i5 = false;
      this.i6 = false;
      this.i7 = false;
    },
    handleScroll(event) {
      // 根据滚轮滚动方向累加或减少滚动量
      this.scrollAccumulation += event.deltaY > 0 ? 1 : -1;
      // this.scrollnum += event.deltaY > 0 ? 1 : -1;
      // 判断是否达到半圈的滚动量，然后重置累计值并执行函数
      if (Math.abs(this.scrollAccumulation) >= this.scrollThreshold) {
        this.scrollAccumulation = 0; // 重置累计值
        if (event.deltaY > 0) {
          this.$refs.carousel.next();
          // if (this.scrollnum >= 12) {
          //   this.scrollnum = 12;
          //   this.baian = true;
          // }
        } else if (event.deltaY < 0) {
          this.$refs.carousel.prev();
          // if (this.scrollnum <= 0) {
          //   this.scrollnum = 0;
          //   this.baian = false;
          // }
        }
        // console.log(this.scrollAccumulation, this.scrollnum);
      }
    },
    yourFunction() {
      // console.log("鼠标滚轮滚动半圈，执行函数");
      // 这里替换为你需要执行的实际逻辑
    },
    onTap(e) {
      // console.log(111);
      this.flagNav = e;
    },
    onSwiper(swiper) {
      // console.log(swiper);
    },
    onChange(e, f) {
      console.log(e, f, ".............");
      if (e == 4) {
        this.baian = true;
      } else {
        this.baian = false;
      }
      if (e != 0) {
        this.$store.dispatch("changeNav1");
        // console.log("kk", this.$store.state.flagNav.flag);
      } else {
        this.$store.dispatch("changeNav2");
        // console.log("kk1", this.$store.state.flagNav.flag);
      }
    },
    onSlideChange() {
      // console.log("slide change");
    },
    scrollBottom(e) {
      if (e.deltaY > 0) {
        this.$refs.carousel.next();
      } else if (e.deltaY < 0) {
        this.$refs.carousel.prev();
      }
    },
    nextPic() {
      this.$refs.carousel.next();
    },
  },
  mounted() {
    // this.addMouseWheelControl();
  },
  beforeDestroy() {
    // this.removeMouseWheelControl();
  },
};
</script>
<style lang="scss" scoped>
.home,
body {
  width: 100%;
  height: 100%;
}

.swiper-container {
  height: 100%;
}

.swiper-wrapper {
  height: 100%;
}

.nav {
  height: 80px;

  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  .nav1 {
    height: 80px;
    width: 1474px;
    // background: pink;
    margin: 0 auto;
    .logo {
      width: 142px;
      height: 44px;
      margin-top: 20px;
      margin-right: 215px;
    }
  }
  img {
    height: 80px;
  }
}

.el-carousel-item {
  width: 100%;
  height: 100vh;
}

.main {
  width: 100%;
  height: 100%;
  // background: url('../assets/img/Web1.png') no-repeat;
  // background-size: cover;
  // position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  overflow-y: auto;

  .el-carousel {
    width: 100%;
    height: 100%;
  }

  .main1 {
    width: 100%;
    height: 100%;
    background: url("../assets/img/Web1.png") no-repeat left top;
    // background-size: 100% 100%;
    background-size: cover;
  }

  .main2 {
    width: 100%;

    height: 100%;
    background: url("../assets/img/b1.png") no-repeat left top;
    // background-size: 100% 100%;
    background-size: cover;
    position: relative;
    padding-top: 100px;
    img {
      display: block;
      width: 3452px * 0.43;
      height: 2168px * 0.43;
      margin: 0 auto;
    }
  }

  .main3 {
    width: 100%;
    height: 100%;
    background: url("../assets/img/b2.png") no-repeat left top;
    // background-size: 100% 100%;
    background-size: cover;
    position: relative;
    padding-top: 100px;
    img {
      display: block;
      width: 1403px * 0.84;
      height: 991px * 0.84;
      margin: 0 auto;
    }
  }

  .main4 {
    width: 100%;
    height: 100%;
    background: url("../assets/img/b1.png") no-repeat left top;
    // background-size: 100% 100%;
    background-size: cover;
    position: relative;
    padding-top: 200px;
    img {
      display: block;
      width: 1626px * 0.8;
      height: 853px * 0.8;
      margin: 0 auto;
    }
  }

  .main5 {
    width: 100%;
    height: 100%;
    background: url("../assets/img/Web5.png") no-repeat left top;
    // background-size: 100% 100%;
    background-size: cover;

    position: relative;

    > div {
      // width: 1474px;
      // margin: 0 auto;
      height: 329px;
      display: flex;
      position: absolute;
      left: 0;
      width: 100%;
      // background: pink;
      bottom: 47px;
      // padding-left: 147px;
      align-items: flex-end;
      justify-content: center;
      // > div:hover .sheep_c {
      //   display: block;
      // }
      > div {
        width: 221px;
        // height: 329px;
        position: relative;

        .sheep_c {
          width: 280px;
          position: absolute;
          left: 50%;
          margin-left: -180px;
          top: -230px;
          // display: none;

          .p1 {
            font-size: 24px;
            color: #fff;
            text-align: center;
            font-weight: 700;
          }

          .p2 {
            font-size: 16px;
            color: #fff;
            text-align: center;
            font-weight: 700;
            margin: 4px 0 10px;
          }

          .sheep_c1 {
            width: 280px;
            height: 109px;
            background: rgba(255, 255, 255, 0.27);
            border-radius: 36px 36px 36px 36px;
            text-align: center;
            box-sizing: border-box;
            padding-left: 21px;
            padding-top: 21px;
            padding-right: 21px;
            position: relative;
            img {
              width: 96px;
              height: 96px;
              position: absolute;
              left: 50%;
              margin-left: -48px;
              bottom: -79px;
            }
            p {
              font-weight: 500;
              font-size: 16px;
              color: #ffffff;
              margin-bottom: 6px;
            }

            //  text-align: center;
            font-weight: 500;
            font-size: 14px;
            color: #ffffff;
          }
        }

        // > img {
        //   width: 221px;
        //   height: auto;
        //   // height: 329px;
        // }
        .img1 {
          width: 325px * 0.39;
          height: 403px * 0.39;
        }
        .img2 {
          width: 325px * 0.46;
          height: 385px * 0.46;
        }
        .img3 {
          width: 242px * 0.65;
          height: 281px * 0.65;
        }
        .img4 {
          width: 265px * 0.61;
          height: 330px * 0.61;
        }
        .img5 {
          width: 285px * 0.62;
          height: 379px * 0.62;
        }
        .img6 {
          width: 302px * 0.65;
          height: 412px * 0.65;
        }
        .img7 {
          width: 315px * 0.67;
          height: 445px * 0.67;
        }
      }
    }
  }

  // padding-top: 10px;
  img {
    width: 100%;
    height: 100%;
  }
}

.bottom {
  width: 90px;
  height: 90px;
  position: fixed;
  bottom: 0;
  text-align: center;
  left: 50%;
  z-index: 10;
  margin-left: -45px;

  div {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
  }

  img {
    width: 90px;
    height: 90px;
  }
}

.nav_bar {
  width: 233px;
  height: 311px;
  background: rgba(255, 255, 255, 0.23);
  background-color: #000;
  border-radius: 22px 22px 22px 22px;
  // opacity: 0.23;
  position: fixed;
  top: 110px;
  left: 230px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    height: 47px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;

    .span1 {
      margin-left: 29px;
      margin-right: 20px;
    }

    .span2 {
      margin-left: 14px;
      margin-right: 13px;
    }
  }

  .text_c {
    color: #000;
  }

  .nav_act {
    width: 233px;
    height: 47px;
    background: #f5f5f5;
    border-radius: 24px 24px 24px 24px;
  }
}

.el-carousel__container {
  height: 100% !important;
}

.swiper,
.swiper-slide {
  height: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
